import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ObtenerEstadoArchivos } from 'modelos/Advanced'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Alert, Button, Modal, OverlayTrigger, Spinner, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatearFechaHora } from 'Utilidades'
import { renderTooltip } from 'utils/Utils'

interface ModalProgresoTXTProps {
    showModalProgresoTXT: boolean
    handleClose: any
}

const ModalProgresoTXT = ({showModalProgresoTXT,handleClose}: ModalProgresoTXTProps) => {

    let apiSintia = useAdvAPI()

    const [dataArchivos, setDataArchivos] = useState<ObtenerEstadoArchivos[]>([])
    const [cargando, updateCargando] = useState(false)
    const [searchResults, setSearchResults] = useState(dataArchivos)
    const [errorCarga, setErrorCarga] = useState('')
    const [errores, setErrores] = useState('')
    const [habilitarErrorArchivo] = useState(false)
    const [mostrarForm] = useState<boolean>(false)
    const [showModalErrores, setShowModalErrores] = useState(false)

    const borrarArchivos = (ids: any) => {
        setErrorCarga('')
        updateCargando(true)
        apiSintia.eliminarArchivos([ids]).then((resp) => {
            obtenerEstadoArchivosTXT()
        })
    }

    let intervaloActivo = false 

    const obtenerEstadoArchivosTXT = () => {

        if (intervaloActivo) return 
        intervaloActivo = true
    
        const ejecutarConIntervalo = async () => {
            try {
                const resp = await apiSintia.obtenerEstadoArchivos()
    
                const enProgreso = resp.filter((item: ObtenerEstadoArchivos) => item.DespachoID === null && item?.Error === null)
    
                if (JSON.stringify(resp) !== JSON.stringify(dataArchivos)) {
                    setDataArchivos(resp)
                    setSearchResults(resp)
                }
    
                const hayError = resp.filter((item) => item.Error !== null)
                if (hayError.length > 0) {
                    setErrorCarga(hayError[0].Error!)
                }
    
                if (enProgreso.length > 0 && intervaloActivo) {
                    setTimeout(ejecutarConIntervalo, 2000)
                } else {
                    intervaloActivo = false 
                }
                
                updateCargando(false)
            } catch (error) {
                updateCargando(false)
                intervaloActivo = false
                setErrorCarga('Error del servidor')
                console.error('Error:', error)
            }
        }
    
        ejecutarConIntervalo()
    }

    // const detenerPeticionObtenerEstadosTXT= () => {
    //     intervaloActivo = false
    // }

    useEffect(() => {
        if(showModalProgresoTXT){
            obtenerEstadoArchivosTXT()

        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalProgresoTXT])
    

  return (
  <>
    <Modal show={showModalProgresoTXT} onHide={handleClose} dialogClassName="modal-upload"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton={false}>
                <Modal.Title>Progresos .TXT</Modal.Title>
            </Modal.Header>
            <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            {errorCarga?.length > 0 && errores?.length > 0 && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                {errorCarga?.length < 30 ? errorCarga : 'Revise los archivos con errores o eliminelos antes de continuar.'}
                            </Alert>}
                           
                            {habilitarErrorArchivo && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                El archivo contiene errores o saltos de lineas. Por favor revise el .TXT
                            </Alert>}
                            {!mostrarForm && <div className='container-cards' style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll' }}>
                             
                                {searchResults?.map((el) => (

                                    (el?.DespachoID === null && el?.Error === null) ?
                                        <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid', }}>

                                            <Toast.Body>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                                                    <Spinner animation='border' variant='success' />
                                                    <p style={{ fontWeight: 'bold' }}>Procesando..</p>
                                                    <p>Identificador: {el?.IEXT}</p>

                                                </div>
                                                <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 0, hide: 200 }}
                                                        overlay={renderTooltip('Borrar')}
                                                    >
                                                        <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                    </OverlayTrigger>
                                                </div>

                                            </Toast.Body>
                                        </Toast> : el?.Error?.length! > 0 ? <>
                                            <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                <Toast.Header closeButton={false}>
                                                    <strong className="mr-auto" style={{ color: 'black' }}>
                                                        Identificador: {el?.DespachoID}-{el?.IEXT}</strong>
                                                    <small>ID Sistema: {el?.ID} </small>
                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <strong  style={{ color: 'black', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                                                            Archivo con errores</strong>
                                                        <Button onClick={() => {
                                                            setErrores(el?.Error!)
                                                            setShowModalErrores(true)
                                                        }}>Ver Errores</Button>
                                                        <p style={{ marginTop: 1 }}>Fecha Subido: {formatearFechaHora(el?.FechaCreado)} </p>
                                                    </div>
                                                    <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>


                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Borrar')}
                                                        >
                                                            <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                        </OverlayTrigger>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                        </> :
                                            <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                <Toast.Header closeButton={false}>
                                                    <strong className="mr-auto" style={{ color: 'black' }}>
                                                        Identificador:  <Link to={`/provisorio/${el?.DespachoID}`}>{el?.IEXT}</Link> </strong>
                                                    <small>ID Sistema: {el?.ID} </small>
                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div>
                                                        <p>Subregimen: {el?.Subregimen}</p>
                                                        <p>Fecha Subido: {formatearFechaHora(el?.FechaCreado)} </p>
                                                    </div>
                                                    <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>

                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Procesado')}
                                                        >
                                                            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 25, marginBottom: -5, color: '#65ba77', cursor: 'pointer' }} />

                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Borrar')}
                                                        >
                                                            <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                        </OverlayTrigger>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                ))}

                            </div>}
                        </Modal.Body>}
                    {dataArchivos?.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <p style={{ fontSize: 15 }}>Cantidad de archivos: {dataArchivos?.length}</p>
                    </div>}
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </BlockUi>
        </Modal>


        
        <Modal style={{
                backgroundColor: 'rgba(1,1,1,0.5)'
            }} show={showModalErrores} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Errores</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <div style={{ overflowY: errores !== '' ? 'scroll' : undefined, height: 270, border: '1px solid #000', backgroundColor: 'white', color: 'black' }}>
                                <p>{errores}</p>
                            </div>
                        </Modal.Body>}

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalErrores(false)}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
  )
}

export default ModalProgresoTXT