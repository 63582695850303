import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Form, Modal, Dropdown, Alert , Col} from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "App";
import Grilla, { GrillaRef, TipoCampo } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import { useDecodedParams, toFixedDecimal, isNullOrWhiteSpace, optionLabelConCodigo, convertirDatosGenericosAOption, abrirPdf } from "../../Utilidades";
import { Decimal } from "decimal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { BaseSelect as Select, BaseAsyncSelect as AsyncSelect } from "BaseSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import { MyAsyncSelect, MyForm, MyFormControl, MySelect } from "FormikHooks";
import { Link } from "react-router-dom";
import Loader from "react-loaders";
import { useApi, TipoNcm, TipoLock } from "ApiHooks";
import { CancelToken } from "SintiaHooks";
import { storageFactory } from "storage-factory";
import styled from "styled-components";

const SelectCustom = styled(Select)`
    width:250px;
`;
const AsyncSelectCustom = styled(AsyncSelect)`
    width:250px;
`;

enum TipoBusquedaSubitems {
    Todos = 0,
    ConKgNeto,
    SinKgNeto,
    ConPaisOrigen,
    SinPaisOrigen,
    ConEstadoMercaderia,
    SinEstadoMercaderia,
    FormatoNcmIncorrecto,
    SinSufijosCargados,
    CantidadEstadisticaEnCero,
    ConSufijoSinValor,
    ConNCMInexistente,
    AfectadosPorLNA,
    ConFiller,
    PorNCM,
    PorPaisOrigen,
    PorDescripcion,
    ValorEnSufijos
}
const optionsOrdenSubitems = [{ value: 0, label: 'Nro. Orden' }, { value: 1, label: 'Codigo Articulo' },
{ value: 2, label: 'Ncm' }, { value: 3, label: 'Unidad' }, { value: 4, label: 'Cantidad' }, { value: 5, label: 'Unitario' },
{ value: 6, label: 'FOB' }, { value: 7, label: 'Sufijos' }, { value: 8, label: 'Pais Origen' }, { value: 9, label: 'Filler' },
{ value: 10, label: 'Kg Neto' }, { value: 11, label: 'Nro. Item' }, { value: 12, label: 'Descripción Maria' }];
const optionsSentidoOrden = [{ value: 0, label: 'Ascendente' }, { value: 1, label: 'Descendente' }];

const storage = storageFactory(() => localStorage);
const claveOrden = 'OrdenBusquedaSubitems';
const claveSentido = 'SentidoBusquedaSubitems';
export function Subitems() {
    let refGrilla = useRef<GrillaRef>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let refFormikFiller = useRef<any>(null);
    let refFormikSufijo = useRef<any>(null);
    let refFormikPaisOrigen = useRef<any>(null);
    let refFormikPaisProcedencia = useRef<any>(null);
    let refFormikCambiarNcm = useRef<any>(null);
    let history = useHistory();
    let { interno } = useDecodedParams() as { interno: string };
    let { mostrarError } = useContext(AppContext);
    let api = useApi();
    let [cargando, updateCargando] = useState(true);
    let [notFound, updateNotFound] = useState(false);
    let [mensajeSpinner, updateMensajeSpinner] = useState('');
    let [ultimoBlobUrl, updateUltimoBlobUrl] = useState('');
    let [estadoCaratula, updateEstadoCaratula] = useState({
        bloqueada: false,
        totalFobCaratula: 0,
        totalFobSubitems: 0,
        totalKgNetoSubitems: 0,
        cantidadSubitems: 0,
        subregimen: ''
    });
    let ordenInicial = 0;
    let ordenStorage = storage.getItem(claveOrden);
    if (!isNullOrWhiteSpace(ordenStorage)) {
        ordenInicial = parseInt(ordenStorage as string);
    }
    let sentidoInicial = 0;
    let sentidoStorage = storage.getItem(claveSentido);
    if (!isNullOrWhiteSpace(sentidoStorage)) {
        sentidoInicial = parseInt(sentidoStorage as string);
    }
    let [busquedaActual, updateBusquedaActual] = useState({
        tipo: TipoBusquedaSubitems.Todos, valor: '',
        orden: ordenInicial, sentido: sentidoInicial
    });
    let [busqueda, updateBusqueda] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'setTipoBusqueda') {
            return { ...estado, tipo: accion.tipoBusqueda, valor: accion.valor };
        } else if (accion.tipo === 'setOrden') {
            return { ...estado, orden: accion.valor };
        } else if (accion.tipo === 'setSentidoOrden') {
            return { ...estado, sentido: accion.valor };
        }
        return estado;
    }, { tipo: TipoBusquedaSubitems.Todos, valor: '', orden: ordenInicial, sentido: sentidoInicial });
    let [optionsPaises, updateOptionsPaises] = useState<any[]>([]);
    let [mensajeAlert, updateMensajeAlert] = useState('');
    let [mostrarModalEstablecerFiller, updateMostrarModalEstablecerFiller] = useState(false);
    let [mostrarModalEstablecerSufijo, updateMostrarModalEstablecerSufijo] = useState(false);

    let [mostrarModalEstablecerPaisOrigen, updateMostrarModalEstablecerPaisOrigen] = useState(false);
    let [mostrarModalEstablecerPaisProcedencia, updateMostrarModalEstablecerPaisProcedencia] = useState(false);
    let [mostrarModalCambiarNcm, updateMostrarModalCambiarNcm] = useState(false);
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    const plantillaFormatoNroItem = (valor: any) => {
        if (valor === null || valor === undefined) {
            return '';
        }
        return `${valor}`.padStart(4, '0');
    }
    let campos = [{ propiedad: 'Id', clave: true, visible: false }, { propiedad: 'Orden', titulo: 'Orden' },
    { titulo: 'Cod. Articulo', propiedad: 'IdArticulo' }, { titulo: 'Ncm', propiedad: 'Ncm' },
    { titulo: 'Unidad', propiedad: 'CodigoUnidadDeclarada' },
    { titulo: 'Cantidad', propiedad: 'CantidadDeclarada', tipo: TipoCampo.Number },
    { titulo: 'Unidad Est', propiedad: 'CodigoUnidadEstadistica' },
    { titulo: 'Cantidad Est', propiedad: 'CantidadEstadistica', tipo: TipoCampo.Number },
    { titulo: 'Unitario', propiedad: 'PrecioUnitario', tipo: TipoCampo.Number, decimales: 5 },
    { titulo: "FOB", propiedad: 'TotalFob', tipo: TipoCampo.Number },
    { titulo: 'Kg Neto', propiedad: 'KgNeto', tipo: TipoCampo.Number }, { titulo: 'Sufijos', propiedad: 'Sufijos' },
    { titulo: 'Pais Origen', propiedad: 'CodigoPaisOrigen' }, { titulo: 'DJCP', propiedad: 'DJCP' },
    { titulo: 'Filler', propiedad: 'Filler' },
    { titulo: 'Item', propiedad: 'NroItemMaria', plantillaFormato: plantillaFormatoNroItem },
    { titulo: 'Subitem', propiedad: 'NroSubitemMaria', plantillaFormato: plantillaFormatoNroItem },
    { titulo: 'Descripción María', propiedad: 'DescripcionMaria' }];

    async function cargarEstadoCaratula() {
        let respuesta = await api.getEstadoCaratula(interno);
        if (!respuesta) {
            updateNotFound(true);
            return;
        }
        updateEstadoCaratula({
            bloqueada: respuesta.CaratulaBloqueada,
            totalFobCaratula: respuesta.TotalFobCaratula,
            totalFobSubitems: respuesta.TotalFobSubitems,
            totalKgNetoSubitems: respuesta.TotalKgNetoSubitems,
            cantidadSubitems: respuesta.CantidadSubitems,
            subregimen: respuesta.Subregimen
        });
        updateCargando(false);
    }


    useEffect(() => {
        async function cargarPaises() {
            let paises = await api.getPaises();
            updateOptionsPaises(convertirDatosGenericosAOption(paises));
        }
        async function cargar() {
            try {
                await cargarPaises();
                await cargarEstadoCaratula();
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar estado de caratula', error);
                    mostrarError('Error al cargar estado de caratula');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);



    useEffect(() => {
        refGrilla.current?.recargar();
    }, [busquedaActual]);
    useEffect(() => {
        if (!isNullOrWhiteSpace(ultimoBlobUrl)) {
            return () => {
                URL.revokeObjectURL(ultimoBlobUrl);
            }
        }
    }, [ultimoBlobUrl]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaSubitemsCaratulaPaginado(interno, busquedaActual.tipo,
            busquedaActual.valor, busquedaActual.orden, busquedaActual.sentido, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    async function eliminarSubitems(ids: string[]) {
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeEliminar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeEliminar) {
                let { exito, error } = await api.deleteVariosSubitems(interno, ids);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se pueden eliminar los subitems porque otra persona la está utilizando la caratula ${interno}`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al eliminar subitems', error);
                mostrarError('Hubo un error al eliminar los subitems');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function establecerKilosEnCero(establecerCantidadEstadistica: boolean) {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
        if (clavesSeleccionadas.length > 0) {
            if (establecerCantidadEstadistica) {
                updateMensajeDialogoConfirmar(`¿Está seguro que desea establecer los kilos y cantidad estadistica en cero en los ${clavesSeleccionadas.length} subitems seleccionados?`)
            } else {
                updateMensajeDialogoConfirmar(`¿Está seguro que desea establecer los kilos en cero en los ${clavesSeleccionadas.length} subitems seleccionados?`)
            }
        } else {
            if (establecerCantidadEstadistica) {
                updateMensajeDialogoConfirmar('¿Está seguro que desea establecer los kilos y cantidad estadistica en cero en todos los subitems?');
            } else {
                updateMensajeDialogoConfirmar('¿Está seguro que desea establecer los kilos en cero en todos los subitems?');
            }
        }
        let continuar = await refDialogo.current!.mostrar();
        if (!continuar) {
            return;
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerKilosEnCeroSubitems(interno, clavesSeleccionadas,
                    establecerCantidadEstadistica);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer kilos', error);
                mostrarError('Hubo un error al establecer kilos');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function establecerPaisOrigen(codigoPaisOrigen: string | null) {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
        if (!codigoPaisOrigen) {
            if (clavesSeleccionadas.length > 0) {
                updateMensajeDialogoConfirmar(`¿Está seguro que desea vaciar el pais de origen en los ${clavesSeleccionadas.length} subitems seleccionados?`)
            } else {
                updateMensajeDialogoConfirmar('¿Está seguro que desea vaciar el pais de origen en cero en todos los subitems?');
            }
            let continuar = await refDialogo.current!.mostrar();
            if (!continuar) {
                return;
            }
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerPaisOrigenSubitems(interno, clavesSeleccionadas,
                    codigoPaisOrigen);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer pais origen', error);
                mostrarError('Hubo un error al establecer pais origen');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function establecerPaisProcedencia(codigoPaisProcedencia: string | null) {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
        if (!codigoPaisProcedencia) {
            if (clavesSeleccionadas.length > 0) {
                updateMensajeDialogoConfirmar(`¿Está seguro que desea vaciar el pais de procedencia en los ${clavesSeleccionadas.length} subitems seleccionados?`)
            } else {
                updateMensajeDialogoConfirmar('¿Está seguro que desea vaciar el pais de procedencia en cero en todos los subitems?');
            }
            let continuar = await refDialogo.current!.mostrar();
            if (!continuar) {
                return;
            }
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerPaisProcedenciaSubitems(interno, clavesSeleccionadas,
                    codigoPaisProcedencia);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer pais procedencia', error);
                mostrarError('Hubo un error al establecer pais procedencia');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function vaciarCodigoProrrateo() {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
        if (clavesSeleccionadas.length > 0) {
            updateMensajeDialogoConfirmar(`¿Está seguro que desea vaciar el codigo de prorrateo en los ${clavesSeleccionadas.length} subitems seleccionados?`)
        } else {
            updateMensajeDialogoConfirmar('¿Está seguro que desea vaciar el codigo de prorrateo en cero en todos los subitems?');
        }
        let continuar = await refDialogo.current!.mostrar();
        if (!continuar) {
            return;
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.vaciarCodigoProrrateoSubitems(interno, clavesSeleccionadas);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al vaciar codigo de prorrateo', error);
                mostrarError('Hubo un error al vaciar codigo de prorrateo');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }



    async function establecerFiller(filler: string | null) {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
        if (!filler) {
            if (clavesSeleccionadas.length > 0) {
                updateMensajeDialogoConfirmar(`¿Está seguro que desea vaciar el filler en los ${clavesSeleccionadas.length} subitems seleccionados?`)
            } else {
                updateMensajeDialogoConfirmar('¿Está seguro que desea vaciar el filler en cero en todos los subitems?');
            }
            let continuar = await refDialogo.current!.mostrar();
            if (!continuar) {
                return;
            }
        }
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerFillerSubitems(interno, clavesSeleccionadas, filler);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer filler', error);
                mostrarError('Hubo un error al establecer filler');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }













    async function establecerSufijo(sufijo: string, valor: string) {
        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();

        updateMensajeDialogoConfirmar(`¿Confirma establecer el valor ${valor} en el sufijo ${sufijo}?`);

        let continuar = await refDialogo.current!.mostrar();
        if (!continuar) {
            return;
        }

        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerSufijoSubitems(interno, sufijo,valor, clavesSeleccionadas);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer sufijo', error);
                mostrarError('Hubo un error al establecer sufijo');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }












    async function establecerFillerDistintos() {
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
                let { exito, error } = await api.establecerFillerDistintosSubitems(interno,
                    clavesSeleccionadas);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer filler distintos', error);
                mostrarError('Hubo un error al establecer filler distintos');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function establecerFillerPorCodigoArticulo() {
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
                let { exito, error } = await api.establecerFillerPorCodigoArticuloSubitems(interno,
                    clavesSeleccionadas);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer filler por codigo articulo', error);
                mostrarError('Hubo un error al establecer filler por codigo articulo');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function establecerFillerPorNroFactura() {
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
                let { exito, error } = await api.establecerFillerPorNroFacturaSubitems(interno,
                    clavesSeleccionadas);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al establecer filler por nro factura', error);
                mostrarError('Hubo un error al establecer filler por nro factura');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function cambiarNcm(ncmViejo: string, ncmNuevo: string) {
        refGrilla.current?.setBloqueado(true);
        try {
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let { exito, error } = await api.establecerNcmSubitems(interno,
                    ncmViejo, ncmNuevo);
                if (exito) {
                    await api.eliminarLock(TipoLock.Caratula, interno);
                    await cargarEstadoCaratula();
                    refGrilla.current?.recargar();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede modificar la caratula ${interno} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cambiar ncm', error);
                mostrarError('Hubo un error al cambiar NCM');
                refGrilla.current?.setBloqueado(false);
            }
        }
    }
    async function reporteResumido() {
        try {
            updateMensajeSpinner('Generando reporte resumido...');
            let respuesta = await api.getPdfReporteSubitemsCaratula(interno);
            let blobUrl = URL.createObjectURL(respuesta);
            updateUltimoBlobUrl(blobUrl);
            abrirPdf(blobUrl).catch(() => {
                mostrarError('Tiene habilitado el bloqueador de pop-ups. Por favor deshabilitelo y vuelva a intentarlo');
            });
            updateMensajeSpinner('');
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al generar reporte resumido', error);
                mostrarError('Error al generar reporte resumido');
            }
            if (!api.isUnmounted()) {
                updateMensajeSpinner('');
            }
        }
    }
    let optionsFiltro = [{ value: TipoBusquedaSubitems.ConKgNeto, label: 'Con Kilos' },
    { value: TipoBusquedaSubitems.SinKgNeto, label: 'Sin Kilos' },
    { value: TipoBusquedaSubitems.ConPaisOrigen, label: 'Con País Origen' },
    { value: TipoBusquedaSubitems.SinPaisOrigen, label: 'Sin País Origen' },
    { value: TipoBusquedaSubitems.ConEstadoMercaderia, label: 'Con Estado Mercadería' },
    { value: TipoBusquedaSubitems.SinEstadoMercaderia, label: 'Sin Estado Mercadería' },
    { value: TipoBusquedaSubitems.PorNCM, label: 'Por Posición' },
    { value: TipoBusquedaSubitems.FormatoNcmIncorrecto, label: 'Formato NCM Incorrecto' },
    { value: TipoBusquedaSubitems.SinSufijosCargados, label: 'Sin Sufijos Cargados' },
    { value: TipoBusquedaSubitems.CantidadEstadisticaEnCero, label: 'Cantidad Estadistica en Cero' },
    { value: TipoBusquedaSubitems.ConSufijoSinValor, label: 'Con Algun Sufijo Sin Valor' },
    { value: TipoBusquedaSubitems.PorPaisOrigen, label: 'Por Pais Origen' },
    { value: TipoBusquedaSubitems.PorDescripcion, label: 'Por Descripción Articulo' },
    { value: TipoBusquedaSubitems.ConNCMInexistente, label: 'Con Posición Inexistente' },
    { value: TipoBusquedaSubitems.ValorEnSufijos, label: 'Sufijos Conteniendo Texto' },
    { value: TipoBusquedaSubitems.AfectadosPorLNA, label: 'Afectados por LNA' },
    { value: TipoBusquedaSubitems.ConFiller, label: 'Con Filler' }];
    let tiposNcm = [TipoNcm.Ncm];
    if (estadoCaratula.subregimen?.startsWith('TRM')) {
        tiposNcm = [TipoNcm.TRAM];
    } else if (estadoCaratula.subregimen?.startsWith('ER')) {
        tiposNcm = [TipoNcm.Rancho];
    } else if (estadoCaratula.subregimen?.startsWith('PI')) {
        tiposNcm = [TipoNcm.Particular];

    }
    return notFound ? (<NotFoundComponent></NotFoundComponent>) : (<>
        <h2>Subitems de <Link to={'/caratulas/' + encodeURIComponent(interno)}>{interno}</Link></h2>
        <BlockUi blocking={cargando}>
            <dl className="totalesSubitems">
                <div>
                    <dt>Total Caratula</dt>
                    <dd>{toFixedDecimal(estadoCaratula.totalFobCaratula, 2)}</dd>
                </div>
                <div>
                    <dt>Neto Total</dt>
                    <dd>{toFixedDecimal(estadoCaratula.totalKgNetoSubitems, 2)}</dd>
                </div>
                <div>
                    <dt>Cantidad</dt>
                    <dd>{estadoCaratula.cantidadSubitems}</dd>
                </div>
                <div>
                    <dt>Total Subitems</dt>
                    <dd>{toFixedDecimal(estadoCaratula.totalFobSubitems, 2)}</dd>
                </div>
                <div>
                    <dt>Diferencia</dt>
                    <dd>{toFixedDecimal(new Decimal(estadoCaratula.totalFobCaratula).minus(estadoCaratula.totalFobSubitems), 2)}</dd>
                </div>
            </dl>
            {!isNullOrWhiteSpace(mensajeAlert) && <Alert variant="danger">{mensajeAlert}</Alert>}
            <Button className="mb-2 mr-2" disabled={estadoCaratula.bloqueada} onClick={() => history.push('/caratulas/' + encodeURIComponent(interno) + '/subitems/add')}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Agregar</span>
            </Button>
            <Dropdown className="d-inline">
                <Dropdown.Toggle disabled={estadoCaratula.bloqueada} id="menuUtilidades" className="mr-2 mb-2">Utilidades</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => establecerKilosEnCero(false)}>Establecer Kilos en Cero</Dropdown.Item>
                    <Dropdown.Item onClick={() => establecerPaisOrigen(null)}>Vaciar Pais Origen</Dropdown.Item>
                    <Dropdown.Item onClick={() => establecerFiller(null)}>Vaciar Filler</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateMostrarModalEstablecerFiller(true)}>Establecer Filler</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateMostrarModalEstablecerSufijo(true)}>Establecer Sufijo</Dropdown.Item>
                    <Dropdown.Item onClick={vaciarCodigoProrrateo}> Vaciar Codigo Prorrateo</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateMostrarModalEstablecerPaisOrigen(true)}>Establecer Pais Origen</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateMostrarModalEstablecerPaisProcedencia(true)}>Establecer Pais Procedencia</Dropdown.Item>
                    <Dropdown.Item onClick={establecerFillerDistintos}>Establecer Filler Distintos (hacer de cada subitem un item)</Dropdown.Item>
                    <Dropdown.Item onClick={establecerFillerPorCodigoArticulo}>Establecer Filler por Codigo de Articulo</Dropdown.Item>
                    <Dropdown.Item onClick={() => establecerKilosEnCero(true)}>Establecer Kilos y Cantidad Estadistica en Cero (solo TRAM)</Dropdown.Item>
                    <Dropdown.Item onClick={establecerFillerPorNroFactura}>Establecer Filler por Nro Factura</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateMostrarModalCambiarNcm(true)}>Cambiar NCM</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        let clavesSeleccionadas = refGrilla.current!.getClavesSeleccionadas();
                        if (clavesSeleccionadas.length > 0) {
                            updateMensajeDialogoConfirmar(`¿Está seguro que desea eliminar ${clavesSeleccionadas.length} subitems?`);
                            refDialogo.current?.mostrar().then(() => eliminarSubitems(clavesSeleccionadas)).catch(() => { });
                        } else {
                            mostrarError('Debe seleccionar al menos un subitem para eliminar');
                        }
                    }}>Eliminar Subitems Seleccionados</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="d-inline mr-2">
                <Dropdown.Toggle id="menuReportes" className="mb-2">Reportes</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={`/caratulas/${encodeURIComponent(interno)}/subitems/reporteDetallado`}>Articulo (Detallado)</Dropdown.Item>
                    <Dropdown.Item onClick={reporteResumido}>Impresora (Resumido)</Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/caratulas/${encodeURIComponent(interno)}/subitems/reporteGravamenes`}>Articulos y Gravamenes</Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/caratulas/${encodeURIComponent(interno)}/subitems/reporteValorCriterio`}>Valor Criterio</Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/caratulas/${encodeURIComponent(interno)}/subitems/reporteMineria`}>Mineria</Dropdown.Item>
                    <Dropdown.Item as={Link} to={`/caratulas/${encodeURIComponent(interno)}/subitems/reporteIntervenciones`}>Intervenciones</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Form className="mb-2 d-inline-flex" inline onSubmit={e => {
                e.preventDefault();
                if ((busqueda.tipo === TipoBusquedaSubitems.PorNCM || busqueda.tipo === TipoBusquedaSubitems.PorPaisOrigen ||
                    busqueda.tipo === TipoBusquedaSubitems.PorDescripcion || busqueda.tipo === TipoBusquedaSubitems.ValorEnSufijos) &&
                    isNullOrWhiteSpace(busqueda.valor)) {
                    updateMensajeAlert('Debe ingresar un valor');
                } else {
                    updateMensajeAlert('');
                    updateBusquedaActual(busqueda);
                    storage.setItem(claveOrden, busqueda.orden);
                    storage.setItem(claveSentido, busqueda.sentido);
                }
            }}>
                <Form.Group className="mr-2">
                    <Form.Label htmlFor="cboFiltro" className="mr-2">Filtro</Form.Label>
                    <SelectCustom id="cboFiltro" options={optionsFiltro} placeholder="Seleccionar..." isClearable onChange={(seleccion: any) => {
                        let option = seleccion as unknown as { value: string };
                        updateBusqueda({ tipo: 'setTipoBusqueda', tipoBusqueda: option?.value ?? TipoBusquedaSubitems.Todos, valor: '' });
                    }}></SelectCustom>
                </Form.Group>
                {busqueda.tipo === TipoBusquedaSubitems.PorNCM && <Form.Group className="mr-2">
                    <Form.Label htmlFor="txtNcm" className="mr-2">NCM</Form.Label>
                    <AsyncSelectCustom id="txtNcm" isClearable placeholder="Seleccionar..." value={isNullOrWhiteSpace(busqueda.valor) ? null : { label: busqueda.valor, value: busqueda.valor }}
                        onChange={(e: any) => {
                            updateBusqueda({ tipo: 'setTipoBusqueda', tipoBusqueda: TipoBusquedaSubitems.PorNCM, valor: e?.value });
                        }} loadOptions={api.cargarNcm()} createOptionPosition="first"
                        formatCreateLabel={(valor: any) => valor} ></AsyncSelectCustom>
                </Form.Group>}
                {busqueda.tipo === TipoBusquedaSubitems.PorPaisOrigen && <Form.Group className="mr-2">
                    <Form.Label htmlFor="cboPais" className="mr-2">País Origen</Form.Label>
                    <SelectCustom id="cboPais" options={optionsPaises} placeholder="Seleccionar..."
                        value={isNullOrWhiteSpace(busqueda.valor) ? null : optionsPaises.find((item: any) => item.value === busqueda.valor)}
                        onChange={(seleccion: any) => {
                            let option = seleccion as unknown as { value: string };
                            updateBusqueda({ tipo: 'setTipoBusqueda', tipoBusqueda: TipoBusquedaSubitems.PorPaisOrigen, valor: option?.value });
                        }} getOptionLabel={optionLabelConCodigo}>
                    </SelectCustom>
                </Form.Group>}
                {busqueda.tipo === TipoBusquedaSubitems.PorDescripcion && <Form.Group className="mr-2">
                    <Form.Label htmlFor="txtDescripcion" className="mr-2">Descripción</Form.Label>
                    <Form.Control type="text" value={busqueda.valor}
                        onChange={e => updateBusqueda({ tipo: 'setTipoBusqueda', tipoBusqueda: TipoBusquedaSubitems.PorDescripcion, valor: e.target.value })}></Form.Control>
                </Form.Group>}
                {busqueda.tipo === TipoBusquedaSubitems.ValorEnSufijos && <Form.Group className="mr-2">
                    <Form.Label htmlFor="txtValorSufijos" className="mr-2">Contenido Sufijos</Form.Label>
                    <Form.Control type="text" value={busqueda.valor}
                        onChange={e => updateBusqueda({ tipo: 'setTipoBusqueda', tipoBusqueda: TipoBusquedaSubitems.ValorEnSufijos, valor: e.target.value })}></Form.Control>
                </Form.Group>}
                <Form.Group className="mr-2">
                    <Form.Label htmlFor="cboOrden" className="mr-2">Orden</Form.Label>
                    <SelectCustom id="cboOrden" options={optionsOrdenSubitems}
                        value={optionsOrdenSubitems.find(o => o.value === busqueda.orden)}
                        onChange={(seleccion: any) => {
                            let option = seleccion as unknown as { value: string };
                            updateBusqueda({ tipo: 'setOrden', valor: parseInt(option.value) });
                        }}></SelectCustom>
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="cboSentido" className="mr-2">Sentido</Form.Label>
                    <SelectCustom id="cboSentido" options={optionsSentidoOrden}
                        value={optionsSentidoOrden.find(o => o.value === busqueda.sentido)}
                        onChange={(seleccion: any) => {
                            let option = seleccion as unknown as { value: string };
                            updateBusqueda({ tipo: 'setSentidoOrden', valor: parseInt(option.value) });
                        }}></SelectCustom>
                </Form.Group>
                <Button type="submit" className="ml-2">Aplicar filtro</Button>
            </Form>
            <Grilla seleccionMultiple responsiva campos={campos} cargarDatos={cargarDatos}
                eventoDetalle={item => history.push('/caratulas/' + encodeURIComponent(interno) + '/subitems/edit/' + encodeURIComponent(item.Id))}
                eventoEliminar={item => {
                    updateMensajeDialogoConfirmar('¿Está seguro que desea eliminar este subitem?');
                    refDialogo.current?.mostrar().then(() => eliminarSubitems([item.Id])).catch(() => { });
                }} deshabilitarBotonEliminar={estadoCaratula.bloqueada}
                ref={refGrilla} />
        </BlockUi>



        <MyModal show={mostrarModalEstablecerFiller}
            onHide={() => updateMostrarModalEstablecerFiller(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Establecer Filler</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={refFormikFiller} validationSchema={Yup.object({
                        'Valor': Yup.string().nullable().required('Debe ingresar el filler')
                    })} initialValues={{
                        'Valor': '',
                    }} onSubmit={(nuevoFiller: { Valor: string }, actions) => {
                        establecerFiller(nuevoFiller.Valor);
                        actions.setSubmitting(false);
                        updateMostrarModalEstablecerFiller(false);
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyFormControl autoFocus type="text" hideLabel label="Filler" name="Valor"></MyFormControl>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalEstablecerFiller(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => refFormikFiller.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>


{//establecer sufijo
}


        <MyModal show={mostrarModalEstablecerSufijo}
            onHide={() => updateMostrarModalEstablecerSufijo(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Establecer valor de Sufijo</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={refFormikSufijo} 
                            validationSchema={Yup.object({
                                                            'Sufijo': Yup.string().nullable()
                                                                         .length(2, 'El Sufijo debe tener exactamente 2 caracteres')
                                                                         .required('Debe ingresar codigo de sufijo'),
                                                            'Valor': Yup.string().matches(/^[^()=@-]*$/, 'El valor no puede contener los caracteres: -, ), (, @, - , =')
                                                                        .required('Debe ingresar el valor del sufijo')
                                                                        .when('Sufijo', {
                                                                            is: (Sufijo) => Sufijo && Sufijo.startsWith('Z'),
                                                                            then: Yup.string().matches(/^\d+$/, 'Si Sufijo comienza con Z, sufijo debe ser un número entero'),
                                                                          })
                                                        })
                                             } 
                            initialValues={{
                                            'Sufijo': '',
                                            'Valor': ''
                                          }} 
                            onSubmit={(nuevoSufijo: {Sufijo:string, Valor: string }, actions) => {
                                        establecerSufijo(nuevoSufijo.Sufijo, nuevoSufijo.Valor);
                                        actions.setSubmitting(false);
                                        updateMostrarModalEstablecerSufijo(false);
                                     }}>
                        <MyForm blockWhenSubmitting={false}>
                                <Form.Row>
                                    <Form.Group as={Col} className="col-md-2 align-middle">
                                        <MyFormControl autoFocus type="text" label="Sufijo" name="Sufijo" maxLength={2}></MyFormControl>
                                    </Form.Group>
                                    <Form.Group as={Col} className="col-md-10 align-middle">
                                        <MyFormControl type="text" label="Valor" name="Valor"  maxLength={20}></MyFormControl>
                                    </Form.Group>
                                </Form.Row>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalEstablecerSufijo(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => refFormikSufijo.current?.submitForm()}>
                        Reemplazar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
















        <MyModal show={mostrarModalEstablecerPaisOrigen}
            onHide={() => updateMostrarModalEstablecerPaisOrigen(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Establecer Pais Origen</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={refFormikPaisOrigen} validationSchema={Yup.object({
                        'Valor': Yup.string().nullable().required('Debe seleccionar el pais de origen')
                    })} initialValues={{
                        'Valor': '',
                    }} onSubmit={(nuevoPais: { Valor: string }, actions) => {
                        establecerPaisOrigen(nuevoPais.Valor);
                        actions.setSubmitting(false);
                        updateMostrarModalEstablecerPaisOrigen(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Valor" label="Pais Origen" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} />
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalEstablecerPaisOrigen(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => refFormikPaisOrigen.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalEstablecerPaisProcedencia}
            onHide={() => updateMostrarModalEstablecerPaisProcedencia(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Establecer Pais Procedencia</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={refFormikPaisProcedencia} validationSchema={Yup.object({
                        'Valor': Yup.string().nullable().required('Debe seleccionar el pais de procedencia')
                    })} initialValues={{
                        'Valor': '',
                    }} onSubmit={(nuevoPais: { Valor: string }, actions) => {
                        establecerPaisProcedencia(nuevoPais.Valor);
                        actions.setSubmitting(false);
                        updateMostrarModalEstablecerPaisProcedencia(false);
                    }}>
                        <MyForm>
                            <Form.Group>
                                <MySelect name="Valor" label="Pais Origen" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                    getOptionLabel={optionLabelConCodigo} />
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalEstablecerPaisProcedencia(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => refFormikPaisProcedencia.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={mostrarModalCambiarNcm}
            onHide={() => updateMostrarModalCambiarNcm(false)}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Cambiar NCM</h2>
                </Modal.Header>
                <Modal.Body>
                    <Formik innerRef={refFormikCambiarNcm} validationSchema={Yup.object({
                        'NcmViejo': Yup.string().nullable().required('Debe ingresar el ncm a reemplazar'),
                        'NcmNuevo': Yup.string().nullable().required('Debe ingresar el ncm nuevo')
                    })} initialValues={{
                        'NcmViejo': '', 'NcmNuevo': ''
                    }} onSubmit={(values, actions) => {
                        cambiarNcm(values.NcmViejo, values.NcmNuevo);
                        actions.setSubmitting(false);
                        updateMostrarModalCambiarNcm(false);
                    }}>
                        <MyForm blockWhenSubmitting={false}>
                            <Form.Group>
                                <MyFormControl autoFocus name="NcmViejo" label="NCM A Reemplazar" type="text"></MyFormControl>
                            </Form.Group>
                            <Form.Group>
                                <MyAsyncSelect name="NcmNuevo" label="NCM Nuevo"
                                    loadOptions={api.cargarNcm(tiposNcm)}></MyAsyncSelect>
                            </Form.Group>
                        </MyForm>
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateMostrarModalCambiarNcm(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={() => refFormikCambiarNcm.current?.submitForm()}>
                        Ingresar
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeSpinner}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">{mensajeSpinner}</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeDialogoConfirmar} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
    </>);
}