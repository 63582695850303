import { useApi } from 'ApiHooks'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import ModalFormCaratula from 'Paginas/advanced/ProvisorioTable/ModalFormCaratula'
import { ObtenerArticulosVistaResponse, ObtenerArticuosLiqVistaResponse, ObtenerDespachoCaratulaResponse, ObtenerItemVistaResponse, Perfiles } from 'modelos/Advanced'
import React, { useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, ButtonGroup, Container, Dropdown, Form } from 'react-bootstrap'
import { useParams } from 'react-router'
import './styleTable.css'
import ModalNuevoPerfil from 'Paginas/advanced/ProvisorioTable/ModalNuevoPerfil'
import ModalSeleccionarPerfil from 'Paginas/advanced/ProvisorioTable/ModalSeleccionarPerfil'
import ModalConfirmarCancelarValidacion from 'Paginas/advanced/ProvisorioTable/ModalConfirmarCancelarValidacion'
import RenderSwitchTabla from 'Paginas/advanced/ProvisorioTable/RenderSwitchTablas'
import ModalPerfiles from 'Paginas/advanced/ProvisorioTable/ModalPerfiles'
import ModalValidarDespacho from 'Paginas/advanced/ProvisorioTable/ModalValidarDespacho'
import ModalPDFMaria from 'Paginas/advanced/ProvisorioTable/ModalPDFMaria'
import ModalTablaErroresValidacion from 'Paginas/advanced/ProvisorioTable/ModalTablaErroresValidacion'
import ModalOficializar from 'Paginas/advanced/ProvisorioTable/ModalOficializar'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ModalAlertasUsuario from 'Paginas/advanced/ProvisorioTable/ModalAlertasUsuario'
import ModalSesiones from 'Paginas/advanced/ProvisorioTable/ModalSesiones'
import ModalEditarSufijos from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalEditarSufijos'
import ModalSelectMultipleItems from 'Paginas/advanced/ProvisorioTable/ModalesEdicionTabla/ModalSelectMultipleItems'
import { OnValueChangeArgument } from 'FormikHooks'

const ProvisorioTable = () => {

  interface paramsInterface {
    id: string,
  }

  let params: paramsInterface = useParams()
  let api = useAdvAPI()
  let apiSintia = useApi()

  const [tableDataItems, setTableDataItems] = useState<ObtenerItemVistaResponse[]>([])
  const [tableDataItemsDetalle, setTableDataItemsDetalle] = useState<any[]>([])
  const [tableDataArticulos, setTableDataArticulos] = useState<ObtenerArticulosVistaResponse[]>([])
  const [tableDataArticulosLiq, setTableDataArticulosLiq] = useState<ObtenerArticuosLiqVistaResponse[]>([])
  const [cargando, updateCargando] = useState(false)
  const [buttonSelected, setButtonSelected] = useState('Items')
  const [showModalValidar, setShowModalValidar] = useState(false)
  const [cargandoDespachoValidacion, setCargandoDespachoValidacion] = useState(false)
  const [mensajeDespachoProgreso, setMensajeDespachoProgreso] = useState(null)
  const [opcionesVentanaMaria, setOpcionesVentanaMaria] = useState<any>(null)
  const [opcionSeleccionadaVentanaMaria, setOpcionSeleccionadaVentanaMaria] = useState<string | null>(null)
  const [tipoVetanaMaria, setTipoVentanaMaria] = useState<string | null>(null)
  const [ventanaMariaID, setVentanaMariaID] = useState<string | null>(null)
  const [tipoEventoMariaID, setTipoEventoMariaID] = useState<string | null>(null)
  const [tituloVentanaMaria, setTituloVentanaMaria] = useState<string | null>(null)
  const [mensajeVentanaMaria, setMensajeVentanaMaria] = useState<string | null>(null)
  const [textoVentanaMaria, setTextoVentanaMaria] = useState<string | null>(null)
  const [mensajeValidacionError, setMensajeValidacionError] = useState<string | any>('')
  const [showModalConfirmarCancelar, setShowModalConfirmarCancelar] = useState<boolean>(false)
  const [showModalSeleccionarPerfil, setShowModalSeleccionarPerfil] = useState<boolean>(false)
  const [showModalNuevoPerfil, setShowModalNuevoPerfil] = useState<boolean>(false)
  const [showModalPerfiles, setShowModalPerfiles] = useState<boolean>(false)
  const [showModalPDFMaria, setShowModalPDFMaria] = useState<boolean>(false)
  const [showModalOficializar, setShowModalOficializar] = useState<boolean>(false)
  const [showModalSesiones, setShowModalSesiones] = useState<boolean>(false)
  const [showModalTablaErroresValidacion, setShowModalTablaErroresValidacion] = useState<boolean>(false)
  const [tipoBotonVentanaMaria, setTipoBotonVentanaMaria] = useState<string | null>(null)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | any>(null)
  const [opcionesPerfiles, setOpcionesPerfiles] = useState<Perfiles[]>([])
  const [perfilSeleccionadoID, setPerfilSeleccionadoID] = useState<Number | null>(null)
  const [nombrePerfilSeleccionado, setNombrePerfilSeleccionado] = useState<string | null>(null)
  const [nuevoPerfil_InputBoxValue, setNuevoPerfil_InputBoxValue] = useState<string | any>(null)
  const [estadoDespacho, setEstadoDespacho] = useState('')
  const [nroIdentificadorPropio, setNroIdentificadorPropio] = useState('')
  const [nroDespacho, setNroDespacho] = useState('')
  const [nroCarpeta, setNroCarpeta] = useState('')
  const [showPerfiles, setShowPerfiles] = useState(0)
  const [despachoData, setDespachoData] = useState<ObtenerDespachoCaratulaResponse>()
  const [tituloValidarDespacho, setTituloValidarDespacho] = useState<string>('Iniciando Validación')
  const [CUITSeleccionado, setCUITSeleccionado] = useState('')
  const [reloadForm, setReloadForm] = useState(false)
  const [showModalModificarItems,setShowModalModificarItems] = useState<boolean>(false)
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({})
  const [sufijosGlobalesValueUno, setSufijosGlobalesValueUno] = useState<OnValueChangeArgument>()
  const [sufijosGlobalesValueDos, setSufijosGlobalesValueDos] = useState<OnValueChangeArgument>()
  const [showModalSufijosGlobales, setCloseModalSufijosGlobales] = useState(false)
  const nroProvisorio = useRef<null | string>(null)

  const estadoDespachoID = useRef<null | number>(null)

  const handleCloseModalErroresValidacion = () => {
    setShowModalTablaErroresValidacion(false)
  }

  const handleCloseModalPerfiles = () => {
    setShowModalPerfiles(false)
  }

  const handleCloseModalOficializar = () => {
    obtenerDetalleDespacho()
    cargarTablaItems()
    setShowModalOficializar(false)
  }

  const handleCloseModalSesiones = () => {
    setShowModalSesiones(false)
  }

  const handleShowModalPerfiles = () => {
    setShowModalPerfiles(true)
    obtenerPerfiles()

  }
  const handleShowModalSeleccionarPerfil = () => {
    obtenerPerfiles()
    setShowModalSeleccionarPerfil(true)
  }

  const handleCloseModalSeleccionarPerfil = () => {
    setShowPerfiles(0)
    setShowModalSeleccionarPerfil(false)
  }

  const handleCloseModalNuevoPerfil = () => {
    setShowModalNuevoPerfil(false)
  }

  const handleShowModalValidar = (perfilSeleccionado: Number | null) => {
    setTituloValidarDespacho('Iniciando Validación')
    setShowModalSeleccionarPerfil(false)
    setShowModalValidar(true)
    obtenerEstadoValidacion()
    estadoDespachoID?.current === 1 && validarDespacho(perfilSeleccionado!)
  }

  const handleCloseModalValidar = () => {
    setTituloValidarDespacho('Validar')
    setShowModalValidar(false)
    setTipoBotonVentanaMaria(null)
    setTipoVentanaMaria(null)
    setOpcionesVentanaMaria(null)
    setTipoEventoMariaID(null)
    setTituloVentanaMaria(null)
    setMensajeVentanaMaria(null)
    setTextoVentanaMaria(null)
    setVentanaMariaID(null)
    setMensajeDespachoProgreso(null)
    setCargandoDespachoValidacion(false)
    obtenerDetalleDespacho()
  }

  const handleShowModalConfirmarCancelar = () => setShowModalConfirmarCancelar(true)

  const handleCloseModalConfirmarCancelar = () => {
    setShowModalConfirmarCancelar(false)
  }

  const cargarTablaItems = async () => {
    try {
      updateCargando(true)
      let items = await api.getObtenerItemVista(params?.id)
      setTableDataItems(items)
      updateCargando(false)

    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const obtenerItemsDetalles= async () => {
    try {
      await api.getObtenerItemDetalle(params?.id).then((resp) => {
        setTableDataItemsDetalle(resp)
      })

    } catch (error) {
    }
  }
  const obtenerDetalleDespacho = async () => {
    try {
      updateCargando(true)
      let despachoDetalle = await api.ObtenerEstadoDespacho(Number(params?.id))
      let dataDespachoCaratula = await api.getObtenerDespachoCaratula(params?.id)
      setEstadoDespacho(despachoDetalle?.EstadoDescripcion)
      estadoDespachoID.current = despachoDetalle?.EstadoID
      setNroDespacho(despachoDetalle?.NroDespacho || despachoDetalle?.NumeroProvisorio)
      nroProvisorio.current = despachoDetalle?.NumeroProvisorio
      setNroIdentificadorPropio(despachoDetalle?.IdentificadorPropio)
      setNroCarpeta(dataDespachoCaratula?.Caratula?.IEXTS_Referencia)
      setDespachoData(dataDespachoCaratula)
      if (despachoDetalle?.EstadoID !== 1 && despachoDetalle?.EstadoID !== 5 && despachoDetalle?.EstadoID !== 6 && despachoDetalle?.EstadoID !== 7 && despachoDetalle?.EstadoID !== 8 && despachoDetalle?.EstadoID !== 10) {
        obtenerDetalleDespacho()
      }
      updateCargando(false)

    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const cargarTablaArticulos = async () => {
    try {
      updateCargando(true)
      let articulos = await api.getObtenerArticulosVista(params.id)
      setTableDataArticulos(articulos)
      updateCargando(false)
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const cargarTablaLiq = async () => {
    try {
      updateCargando(true)
      let articulosLiq = await api.getObtenerArticulosLiq(params.id)
      setTableDataArticulosLiq(articulosLiq)
      updateCargando(false)
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const tableChange = (botonSelect: string) => {
    const tablaFunctions: any = {
      'Items': cargarTablaItems,
      'ItemsDetalle': obtenerItemsDetalles,
      'Articulos': cargarTablaArticulos,
      'Liquidación': cargarTablaLiq
    }

    const selectedFunction = tablaFunctions[botonSelect]

    if (selectedFunction) {
      setButtonSelected(botonSelect)
      selectedFunction()
    } else {
      console.error('Función no encontrada para el botón seleccionado')
    }
  }

  const validarDespacho = async (IDPerfilSeleccionado?: Number) => {
    try {
      updateCargando(true)
      await api.ValidarDespacho(params?.id, IDPerfilSeleccionado)
      obtenerEstadoValidacion()
      setTimeout(() => {
        obtenerDetalleDespacho()
      }, 6000)
      updateCargando(false)
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const obtenerEstadoValidacion = async () => {
    try {
      updateCargando(true)
      setCargandoDespachoValidacion(true)

      const retryDelay = 5000 // 4 segundos de retardo entre peticiones

      const checkValidationStatus = async () => {
        try {
          const estadoValidacion = await api.ObtenerEstadoValidacion(params?.id)
          const historialValidacion = await api.ObtenerUltimoHistorialValidacion(params?.id)
          const estadoDespacho = await api.ObtenerEstadoDespacho(Number(params?.id))
          if (estadoValidacion?.VentanaMaria?.Despacho?.PerfilVentanasMariaID !== null) {
            setPerfilSeleccionadoID(estadoValidacion?.VentanaMaria?.Despacho?.PerfilVentanasMaria?.ID)
            setNombrePerfilSeleccionado(estadoValidacion?.VentanaMaria?.Despacho?.PerfilVentanasMaria?.Nombre)
          } else {
            setPerfilSeleccionadoID(null)
          }
          if (estadoValidacion.VentanaMaria === null) {
            setMensajeDespachoProgreso(estadoValidacion?.DescripcionProgreso)
            const newTimeoutId = setTimeout(checkValidationStatus, retryDelay)
            setTimeoutId(newTimeoutId)
          } else {
            if (estadoValidacion?.VentanaMaria?.TipoVentana === 'VentanaMaria_ListView') {
              setTituloValidarDespacho('Validando')
              setOpcionSeleccionadaVentanaMaria(estadoValidacion?.VentanaMaria?.Opciones?.[0])
            }
            setTituloValidarDespacho('Validando')
            setTipoBotonVentanaMaria(estadoValidacion?.VentanaMaria?.BotonesVentanaMaria?.[0]?.Nombre)
            setTipoVentanaMaria(estadoValidacion?.VentanaMaria?.TipoVentana)
            setOpcionesVentanaMaria(estadoValidacion?.VentanaMaria?.Opciones)
            setTipoEventoMariaID(estadoValidacion?.VentanaMaria?.ID)
            setTituloVentanaMaria(estadoValidacion?.VentanaMaria?.Titulo === estadoValidacion?.VentanaMaria?.Mensaje ? 'Pregunta general' : estadoValidacion?.VentanaMaria?.Titulo)
            setMensajeVentanaMaria(estadoValidacion?.VentanaMaria?.Mensaje)
            setTextoVentanaMaria(estadoValidacion?.VentanaMaria?.Texto)
            setVentanaMariaID(estadoValidacion?.VentanaMaria?.VentanaMariaID)
            setMensajeDespachoProgreso(null)
            setCargandoDespachoValidacion(false)
            updateCargando(false)
          }

          if (estadoDespacho?.EstadoID === 6) {
            setTituloValidarDespacho('Validación Finalizada')
            obtenerDetalleDespacho()
            setTipoVentanaMaria("Validacion de Item Finalizada.")
            clearTimeout(timeoutId)
            setTimeoutId(null)
            setMensajeDespachoProgreso(null)
            setCargandoDespachoValidacion(false)
            updateCargando(false)
          }

          if (historialValidacion?.Error !== null) {
            setTituloValidarDespacho('Error en la Validación')
            clearTimeout(timeoutId)
            setTimeoutId(null)
            setTipoVentanaMaria('ErrorValidacion')
            setMensajeValidacionError(historialValidacion?.Error)
            setMensajeDespachoProgreso(null)
            setCargandoDespachoValidacion(false)
            updateCargando(false)
          }

          if (estadoDespacho?.EstadoID === 5) {
            setTituloValidarDespacho('Error en la Validación')
            clearTimeout(timeoutId)
            setTimeoutId(null)
            setTipoVentanaMaria('Error')
            setMensajeValidacionError(historialValidacion?.Error)
            setMensajeDespachoProgreso(null)
            setCargandoDespachoValidacion(false)
            updateCargando(false)
          }
        } catch (error) {
          if (!apiSintia.isCancel(error)) {
            setTituloValidarDespacho('Error en la Validación')
            setTipoVentanaMaria('Error')
            clearTimeout(timeoutId)
            setTimeoutId(null)
            setMensajeDespachoProgreso(null)
            setCargandoDespachoValidacion(false)
            updateCargando(false)
            console.error('Error al cargar', error)
          }
        }

      }

      // Llamar a la función de verificación por primera vez
      checkValidationStatus()
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
      updateCargando(false)
      setCargandoDespachoValidacion(false)
      setTipoVentanaMaria('Error')
      setMensajeValidacionError('Error del servidor')
    }
  }

  const cancelarValidar = async (args?: number) => {
    if (timeoutId) {
      handleCloseModalValidar()
      clearTimeout(timeoutId)
      setTimeoutId(null)
      setCargandoDespachoValidacion(false)
      setMensajeDespachoProgreso(null)
    }
    setPerfilSeleccionadoID(null)
    setOpcionesPerfiles([])
    updateCargando(true)
    try {
      await api.CancelarValidar(params?.id)
      if (args === 1) {
        handleCloseModalConfirmarCancelar()
        handleCloseModalSeleccionarPerfil()
        obtenerDetalleDespacho()
        setPerfilSeleccionadoID(null)
        setMensajeValidacionError([])
        handleCloseModalErroresValidacion()
        setTituloValidarDespacho('Iniciando Validación')
        setTimeoutId(null)
        setTipoVentanaMaria(null)
        setMensajeDespachoProgreso(null)
        setCargandoDespachoValidacion(false)
        updateCargando(false)
        obtenerPerfiles()
        setTimeout(() => {
          setShowModalSeleccionarPerfil(true)
          updateCargando(false)
        }, 2000)
      } else {
        handleCloseModalConfirmarCancelar()
        handleCloseModalSeleccionarPerfil()
        obtenerDetalleDespacho()
        updateCargando(false)
      }
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }


  const obtenerPerfiles = async () => {
    updateCargando(true)
    try {
      await api.ObtenerListaPerfiles().then((resp) => {
        setOpcionesPerfiles(resp)
        updateCargando(false)
      })

    } catch (error) {
    }
  }

  const crearNuevoPerfil = async (nuevoPerfil_InputBoxValue: string) => {
    updateCargando(true)
    try {
      await api.CrearNuevoPerfil(nuevoPerfil_InputBoxValue)
      handleCloseModalNuevoPerfil()
      updateCargando(false)
      obtenerPerfiles()
    } catch (error) {
      if (!apiSintia.isCancel(error)) {
        console.error('Error al cargar', error)
      }
    }
  }

  const updateSufijosGlobales = async () => {
    updateCargando(true)

    try {
        await api.UpdateSufijosGlobales({
          "Sufijo":{
                "DespachoId" : params?.id,
                "Codigo" : sufijosGlobalesValueUno,
                "Valor" : sufijosGlobalesValueDos
            },
          "NArts": Object?.keys(selectedItems)?.filter(key => selectedItems[key])
        })
        updateCargando(false)
        setReloadForm(true)
        setShowModalModificarItems(false)
        setCloseModalSufijosGlobales(true)
        obtenerDetalleDespacho()
    } catch (error) {
        updateCargando(false)
        console.log('error', error)
    }
}


  useEffect(() => {
    if (showModalValidar) {
      const handleEscPress = (event: { key: string }) => {
        if (event.key === 'Escape') {
          handleShowModalConfirmarCancelar()
        }
      }
      window.addEventListener('keydown', handleEscPress)

      return () => {
        window.removeEventListener('keydown', handleEscPress)
      }
    }
  }, [showModalValidar])

  useEffect(() => {
    return () => clearTimeout(timeoutId!)
  }, [timeoutId])

  useEffect(() => {
    cargarTablaItems()
    obtenerDetalleDespacho()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (reloadForm) {
      obtenerDetalleDespacho()
      cargarTablaItems()
      cargarTablaArticulos()
      setReloadForm(false)
    }
    //eslint-disable-next-line
  }, [reloadForm])

  return (
    <Container>
      <div>
        <Link to={{
          pathname: `/advanced/VerDestincaciones`,
        }}><Button variant='warning'><FontAwesomeIcon icon={faArrowLeft} /></Button></Link>
        <Container style={{ display: 'flex', flexDirection: 'row', padding: 20, marginBottom: 10 }}>
          <br /><p style={{ marginTop: 0 }} className="h3">Provisorios</p><br />
          <div style={{ marginLeft: '60%' }}>
            <ModalAlertasUsuario cuitDespachante={despachoData?.Caratula?.CDDTAGR} />
          </div>
        </Container>        <hr style={{ marginTop: -20 }} />
        <Container style={{ marginTop: -10, marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Label>Identificador Propio</Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 150 }}
              name="IEXT"
              value={nroIdentificadorPropio ?? '-'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <Form.Label>Referencia Cliente </Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 190 }}
              name="IEXT"
              value={nroCarpeta ?? '-'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <Form.Label>Nro. Despacho</Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 155 }}
              name="IEXT"
              value={nroDespacho ?? '-'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <Form.Label>Destinación</Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 100 }}
              name="IEXT"
              value={despachoData?.Caratula?.ISTA ?? '-'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <Form.Label>Despachante</Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 150 }}
              name="IEXT"
              value={despachoData?.Caratula?.CDDTAGR ?? '-'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <Form.Label>Import/Export</Form.Label>
            <Form.Control
              type="text"
              disabled
              style={{ height: 38, width: 150 }}
              name="IEXT"
              value={despachoData?.Caratula?.NDDTIMMIOE ?? '-'}
            />
          </div>
        </Container>
        <hr />

      </div>
      <BlockUi blocking={cargando}>
        <div className="btn-group row mb-3" style={{ display: 'flex' }}>
          <Button disabled={!(estadoDespachoID?.current === 8 || estadoDespachoID?.current === 5
          )} onClick={() => setShowModalTablaErroresValidacion(true)} variant={estadoDespacho === 'Sin Validar' ? "secondary" : estadoDespacho === 'Oficializado' ? 'warning' : (estadoDespachoID?.current === 8 || estadoDespachoID?.current === 5
          ) ? 'danger' : 'success'} className=''>{estadoDespacho}</Button>
          <ModalFormCaratula id={params.id} estadoDespacho={estadoDespacho} reloadDespachoData={() => obtenerDetalleDespacho()
          } />
          <Button onClick={() => tableChange('Items')} variant="outline-primary">Items</Button>
          <Button onClick={() => tableChange('ItemsDetalle')} variant="outline-primary">Items Detalle</Button>
          <Dropdown as={ButtonGroup}>
            <Button onClick={() => tableChange('Articulos')} variant="primary">Articulos</Button>
            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
            <Dropdown.Menu>
              <Dropdown.Item><ModalEditarSufijos estadoDespacho={estadoDespacho} setCloseModalSufijosGlobales={setCloseModalSufijosGlobales} typeButton={1} setSufijosGlobalesValueUno={setSufijosGlobalesValueUno} setSufijosGlobalesValueDos={setSufijosGlobalesValueDos} handleShowModalModificarItems={() => setShowModalModificarItems(true)
              } showModalSufijosGlobales={showModalSufijosGlobales} setReloadForm={setReloadForm} /></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button onClick={() => tableChange('Liquidación')} variant="outline-primary">Liquidación</Button>
          <Button onClick={() => (estadoDespachoID?.current === 2 || estadoDespachoID?.current === 3) ? handleShowModalValidar(0) : handleShowModalSeleccionarPerfil()} variant={estadoDespacho === 'Validado' || estadoDespacho === 'Oficializado' ? 'secondary' : 'success'} disabled={estadoDespacho === "Oficializado"}>Validar</Button>
          <Button variant={(estadoDespacho !== 'Validado' && estadoDespacho !== 'Oficializado') && estadoDespacho !== 'Error Oficializando (Despacho Validado)' ? 'secondary' : 'success'} disabled={(estadoDespacho !== 'Validado' && estadoDespacho !== 'Oficializado' && estadoDespacho !== 'Error Oficializando (Despacho Validado)')} onClick={() => setShowModalPDFMaria(true)}>PDF Maria</Button>
          <Button onClick={() => handleShowModalPerfiles()} variant='outline-primary'>Perfiles</Button>
          {nroDespacho?.slice(2, 5).includes('777') ? <Button
            disabled={estadoDespacho !== "Validado" && estadoDespacho !== "Error Oficializando (Despacho Validado)"}
            variant={(estadoDespacho !== 'Validado' && estadoDespacho !== "Error Oficializando (Despacho Validado)") ? 'secondary' : 'success'} onClick={() => setShowModalOficializar(true)} >Oficializar</Button> :
            <Button
              disabled={estadoDespacho !== "Validado" && estadoDespacho !== "Error Oficializando (Despacho Validado)"}
              variant={(estadoDespacho !== 'Validado' && estadoDespacho !== "Error Oficializando (Despacho Validado)") ? 'secondary' : 'success'} onClick={() => setShowModalSesiones(true)} >Oficializar</Button>}
        </div>
      </BlockUi>
      <BlockUi blocking={cargando}>
        <RenderSwitchTabla
          setReloadForm={setReloadForm}
          buttonSelected={buttonSelected}
          tableDataItems={tableDataItems}
          tableDataItemsDetalle={tableDataItemsDetalle}
          idDespacho={params?.id}
          tableDataArticulos={tableDataArticulos}
          tableDataArticulosLiq={tableDataArticulosLiq}
          nroProvisorio={nroProvisorio}
          estadoDespacho={estadoDespacho}
        />
      </BlockUi>
      <ModalValidarDespacho
        tituloValidarDespacho={tituloValidarDespacho}
        nombrePerfilSeleccionado={nombrePerfilSeleccionado}
        cargandoDespachoValidacion={cargandoDespachoValidacion}
        handleCloseModalValidar={handleCloseModalValidar}
        handleShowModalConfirmarCancelar={handleShowModalConfirmarCancelar}
        idDespacho={params?.id}
        handleShowModalSeleccionarPerfil={handleShowModalSeleccionarPerfil}
        mensajeDespachoProgreso={mensajeDespachoProgreso}
        mensajeValidacionError={mensajeValidacionError}
        mensajeVentanaMaria={mensajeVentanaMaria}
        obtenerEstadoValidacion={obtenerEstadoValidacion}
        opcionSeleccionadaVentanaMaria={opcionSeleccionadaVentanaMaria}
        opcionesVentanaMaria={opcionesVentanaMaria}
        perfilSeleccionadoID={perfilSeleccionadoID}
        setOpcionSeleccionadaVentanaMaria={setOpcionSeleccionadaVentanaMaria}
        setTipoVentanaMaria={setTipoVentanaMaria}
        showModalValidar={showModalValidar}
        textoVentanaMaria={textoVentanaMaria}
        tipoBotonVentanaMaria={tipoBotonVentanaMaria}
        tipoEventoMariaID={tipoEventoMariaID}
        tipoVetanaMaria={tipoVetanaMaria}
        tituloVentanaMaria={tituloVentanaMaria}
        updateCargando={updateCargando}
        ventanaMariaID={ventanaMariaID}
        cancelarValidar={cancelarValidar}
      />
      <ModalConfirmarCancelarValidacion
        cancelarValidar={cancelarValidar}
        handleCloseModalConfirmarCancelar={handleCloseModalConfirmarCancelar}
        handleCloseModalValidar={handleCloseModalValidar}
        showModalConfirmarCancelar={showModalConfirmarCancelar}
      />

      <ModalSeleccionarPerfil
        showModalSeleccionarPerfil={showModalSeleccionarPerfil}
        handleCloseModalSeleccionarPerfil={handleCloseModalSeleccionarPerfil}
        cargando={cargando}
        estadoDespachoId={estadoDespachoID?.current}
        setPerfilSeleccionadoID={setPerfilSeleccionadoID}
        opcionesPerfiles={opcionesPerfiles}
        setShowModalNuevoPerfil={setShowModalNuevoPerfil}
        handleShowModalValidar={handleShowModalValidar}
        perfilSeleccionadoID={perfilSeleccionadoID}
        setNombrePerfilSeleccionado={setNombrePerfilSeleccionado}
        estadoDespacho={estadoDespacho}
        showPerfiles={showPerfiles}
        setShowPerfiles={setShowPerfiles}
        cancelarValidar={cancelarValidar}
      />

      <ModalNuevoPerfil
        showModalNuevoPerfil={showModalNuevoPerfil}
        handleCloseModalNuevoPerfil={handleCloseModalNuevoPerfil}
        nuevoPerfil_InputBoxValue={nuevoPerfil_InputBoxValue}
        setNuevoPerfil_InputBoxValue={setNuevoPerfil_InputBoxValue}
        crearNuevoPerfil={crearNuevoPerfil} />

      <ModalPerfiles
        showModalPerfiles={showModalPerfiles}
        handleCloseModalPerfiles={handleCloseModalPerfiles}
        handleShowModalPerfiles={handleShowModalPerfiles}
        opcionesPerfiles={opcionesPerfiles}
        cargando={cargando}
        setShowModalNuevoPerfil={setShowModalNuevoPerfil}
        obtenerPerfiles={obtenerPerfiles}
        updateCargando={updateCargando}
      />

      <ModalPDFMaria
        showModalPDFMaria={showModalPDFMaria}
        setShowModalPDFMaria={setShowModalPDFMaria}
        cargando={cargando}
        despachoID={Number(params?.id)}
        updateCargando={updateCargando}
        estadoDespacho={estadoDespacho}
        nroDespacho={nroDespacho}
        nroProvisorio={nroProvisorio?.current}
      />

      <ModalTablaErroresValidacion
        setTituloValidarDespacho={setTituloValidarDespacho}
        setTimeoutId={setTimeoutId}
        setTipoVentanaMaria={setTipoVentanaMaria}
        setMensajeDespachoProgreso={setMensajeDespachoProgreso}
        setCargandoDespachoValidacion={setCargandoDespachoValidacion}
        cargando={cargando}
        despachoID={Number(params?.id)}
        updateCargando={updateCargando}
        handleCloseModalErroresValidacion={handleCloseModalErroresValidacion}
        showModalTablaErroresValidacion={showModalTablaErroresValidacion}
      />

      <ModalOficializar
        showModalOficializar={showModalOficializar}
        handleCloseModalOficializar={handleCloseModalOficializar}
        cargando={cargando}
        updateCargando={updateCargando}
        despachoID={params?.id}
        setShowModalPDFMaria={setShowModalPDFMaria}
        CUITSeleccionadoSesion={CUITSeleccionado}
      />

      <ModalSesiones
        cargando={cargando}
        handleCloseModalSesiones={handleCloseModalSesiones}
        updateCargando={updateCargando}
        setShowModalOficializar={setShowModalOficializar}
        showModalSesiones={showModalSesiones}
        setShowModalSesiones={setShowModalSesiones}
        setCUITSeleccionado={setCUITSeleccionado}
      />
      <ModalSelectMultipleItems 
       cargando={cargando}
       handleCloseModalModificarItems={() => setShowModalModificarItems(false)} 
       showModalModificarItems={showModalModificarItems} 
       tableDataItems={tableDataItems!} 
       insertDatosGlobalesInfComp={() => updateSufijosGlobales()} 
       selectedItems={selectedItems} 
       setSelectedItems={setSelectedItems} />
    </Container>

  )
}

export default ProvisorioTable