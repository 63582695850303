import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Container, Modal } from 'react-bootstrap';
import '../ConvertirLicenciaModal/style-modal.css'

interface ModalEliminarDCEXPProps {
    triggerSubmit: (() => void);
    ID: number;
    nroItem?: string
}

const ModalEliminarDCEXP = ({ ID, triggerSubmit, nroItem, }: ModalEliminarDCEXPProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    let api = useAdvAPI();

    const handleClose = () => {
        setShow(false)
    }

    const confirmarEliminarCCM = async () => {
        updateCargando(true)
        try {
            await api.EliminarCCMById(ID)
            updateCargando(false);
            triggerSubmit()
        } catch (error) {
            updateCargando(false);
            console.log('error', error)
        }
    }
    const handleShow = () => setShow(true);

    return (
        <>
            <Button title='Borrar' variant="link" onClick={handleShow} style={{ fontSize: 25, color: '#db3939' }}><FontAwesomeIcon style={{ marginLeft: 30 }} icon={faTrash} /></Button>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    <Modal.Body>
                        <Container style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ fontSize: 50, color: '#e83846' }} icon={faTimesCircle} />
                            <p style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>¿Confirma eliminar el valor del ITEM {nroItem}?</p>
                        </Container>
                        <Container style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignContent: 'center', justifyItems: 'center', alignItems: 'center', marginTop: 15 }}>
                            <Button variant="danger" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="success" onClick={() => confirmarEliminarCCM()}>
                                Confirmar
                            </Button>
                        </Container>
                    </Modal.Body>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEliminarDCEXP