import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
//import { useApi } from "ApiHooks";
import { useAdvAPI } from './AdvancedApi';
import { useApi } from "ApiHooks";
//import { useHistory } from "react-router";
import { LoginAFIP, LoginAFIPRef } from './sesionafip';
import './listado.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import BlockUi from "react-block-ui";

export function GetSession() {

    const [cargando, setCargando] = useState(false);
    const [inicioSesion, setInicioSesion] = useState(false);

    let api = useAdvAPI();
    //let history = useHistory();
    let apiSintia = useApi();
    //let [resulado, updateResulado] = useState<any[]>([]);
    let [resulado, updateResulado] = useState<any[]>([]);

    let loginAFIPRef = useRef<LoginAFIPRef>(null);
    //let huboCambios = React.useRef<{ valor: boolean }>({ valor: false });

    /*class despachanteExt {
        cuit: String = '';
        nombre: String = '';
        inicioSesion: Boolean = false;
        }*/


    function iniciarSesion(t: any) {
        //muestro el modal con el inicio de sesion en afip
        var logueo = false;
        var cuit: string;

        if (t === null || undefined) {
            cuit = '';
        } else {
            cuit = t.cuit;
        }

        loginAFIPRef.current!.mostrar(cuit).then(resultado => { setInicioSesion(resultado.inicioSesion) }).catch(() => { });

        if (logueo) {
            cargar();
        }
    }

    const CerrarSesionAFIP = async (cuit: string) => {
        try {
            setCargando(true)
             await api.removeSessionByCuit({
                CuitLogin: cuit
            })
            cargar()
            setCargando(false)
        } catch (error) {
            cargar()
            setCargando(false)
        }
    }

    async function cargar() {
        try {
            setCargando(true);
            updateResulado(([]));
            //let despachantes = await apiSintia.getDespachantes();
            let sesiones = await apiSintia.getSesionesAFIPHistorico();

            await cargarDatos(sesiones);

        } catch (error) {
            if (!apiSintia.isCancel(error)) {
                console.error('Error al cargar caratulas', error);
            }
        } finally {
            setCargando(false);
        }
    }


    useEffect(() => {
        cargar();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (inicioSesion) {
            updateResulado([]);
            cargar();
        }
        //eslint-disable-next-line
    }, [inicioSesion]);




    async function cargarDatos(listSesiones: any) {

        if (listSesiones.length > 0) {
            listSesiones.forEach(async (sesion: { CUIT: string; Nombre: string; UltimaConexion: DateTime }) => {

                let despanuevo = { 'cuit': '', 'nombre': '', 'inicioSesion': false };
                despanuevo.cuit = sesion.CUIT;
                despanuevo.nombre = sesion.Nombre;

                //verifico si tiene sesion
                let result = await api.getSesionInfo(sesion.CUIT);
                if (result.ParsedResponse!.ApiMessage.includes('Found a session')) {
                    despanuevo.inicioSesion = true;
                }

                updateResulado(resulado => [...resulado, despanuevo]);

            });
            //updateDespachosNoResult(false);

        }
        else {
            updateResulado([]);
            //updateDespachosNoResult(true);

        }

    }

    const lenghtCss = function (nTotal: number) {
        return {
            '--length': nTotal

        }
    }

    const indiceCss = function (nIndice: number) {
        return {
            '--i': nIndice
        }
    }


    function GrillaDespachos(props: {
        despachantes: any[]
        //            eventoRatificar: (item: any) => void,
        //            eventoAVC: (item: any) => void

    })

    // eslint-disable-next-line
    {
        return <>


            <ol className="customol" style={lenghtCss(props.despachantes.length) as React.CSSProperties}>

                {props.despachantes && props.despachantes.map((t: any, indice: number) =>
                (
                    /*<tr className="cursor-pointer " key={t.Despacho} onClick={e => {
                        //let element = e.target as HTMLElement;
                        //ejecutar solamente cuando el target sea una celda de la tabla y no un botón o checkbox
                        /*if (element.nodeName === 'TD' || element.nodeName === 'TR') {
                            props.eventoAbrir(t);
                        }*/
                    //                        }}>*/}

                    <li className="customli" style={indiceCss(indice + 1) as React.CSSProperties}>
                        <div className="align-items-center justify-content-center">
                            <h3>{t.nombre}</h3>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'left' }} className="align-items-center align-top">
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <h4>CUIT {t.cuit}</h4>
                                </div>
                                <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                                    {
                                        (t.inicioSesion) ?
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}><FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: "#2d85d7", }} /><h4 className="ml-2"> sesion iniciada</h4></div>
                                            :
                                            <Button disabled={t.inicioSesion} className="btn btn-info" onClick={e => {
                                                iniciarSesion(t);
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}>Iniciar Sesion</Button>
                                    }{
                                        t?.inicioSesion && <Button style={{ marginLeft: 15 }} className="btn btn-info" onClick={e => {
                                            CerrarSesionAFIP(t?.cuit);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}><FontAwesomeIcon icon={faTrash} /></Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ol>
        </>;
    }


    return (
        <>
            <Container className="d-flex justify-content-center flex-nowrap">
                <BlockUi blocking={cargando} >
                    <div>
                        <br /><p className="h1"><b>Administrador</b> <br /> Sesiones de AFIP</p><br />
                    </div>
                    <div className="mt-1">
                        <button className="btn btn-info" onClick={e => {
                            iniciarSesion(null);
                            e.preventDefault();
                            e.stopPropagation();
                        }}>Agregar sesion</button>
                        <GrillaDespachos despachantes={resulado}></GrillaDespachos>
                    </div>
                </BlockUi>
            </Container>

            <LoginAFIP ref={loginAFIPRef} />
        </>

    )
}