import React from "react";
import { useHistory } from "react-router";
import { AppContext, NotFoundComponent } from "App";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { MyForm, MyFormControl, MySelect, MyTextarea } from "../../FormikHooks";
import { useDecodedParams, cargarDatosGrillaDeArray, convertirDatosGenericosAOption, optionLabelConCodigo, } from "../../Utilidades";
import Grilla, { GrillaRef } from "../../Grilla";
import { useApi } from "ApiHooks";

export function DetalleBitacoraArticuloCatalogo() {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let grillaVentajasRef = React.useRef<GrillaRef>(null);
    let grillaInfCompsRef = React.useRef<GrillaRef>(null);
    let grillaObservacionesRef = React.useRef<GrillaRef>(null);
    let grillaIntervencionesRef = React.useRef<GrillaRef>(null);
    let { id } = useDecodedParams();
    let { mostrarError } = React.useContext(AppContext);
    let [cargando, updateCargando] = React.useState(true);
    // let [mensajeErrorAlert, updateMensajeErrorAlert] = React.useState('');
    let [mensajeUnidadEstadistica, updateMensajeUnidadEstadistica] = React.useState('');
    let [notFound, updateNotFound] = React.useState(false);
    let [ventajas, updateVentajas] = React.useState([]);
    let [infComps, updateInfComps] = React.useState([]);
    let [observaciones, updateObservaciones] = React.useState([]);
    let [estadoIntervenciones, updateIntervenciones] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'setIntervencionesArticulo') {
            let intervencionesNcm = estado.intervencionesNcm.map((item: string) => ({ Descripcion: item }));
            return {
                ...estado, intervencionesArticulo: accion.intervenciones,
                listaGrilla: intervencionesNcm.concat(accion.intervenciones).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        } else if (accion.tipo === 'setIntervencionesNcm') {
            return {
                ...estado, intervencionesNcm: accion.intervenciones,
                listaGrilla: accion.intervenciones.map((item: string) => ({ Descripcion: item })).concat(estado.intervencionesArticulo).map((item: any, indice: number) => ({ ...item, Indice: indice }))
            };
        }
        return estado;
    }, { intervencionesArticulo: [], intervencionesNcm: [], listaGrilla: [], creando: false });
    let schema = Yup.object({
        'CodigoCatalogo': Yup.string().nullable().required('Debe ingresar el codigo de catalogo'),
        'CodigoArticulo': Yup.string().nullable().required('Debe ingresar el codigo de articulo'),
        'Descripcion': Yup.string().nullable(),
        'DescripcionMaria': Yup.string().nullable(),
        'Ncm': Yup.string().nullable().required('Debe ingresar el NCM'),
        'CodigoUnidadDeclarada': Yup.string().nullable(),
        'CodigoPaisOrigen': Yup.string().nullable(),
        'Sufijos': Yup.string().nullable(),
        'CodigoEstadoMercaderia': Yup.string().nullable(),
        'PrecioUnitario': Yup.number().nullable().typeError('Debe ingresar un número')
            .min(0, 'El precio unitario debe ser mayor a 0'),
        'KgUnitario': Yup.number().nullable().typeError('Debe ingresar un número')
            .min(0, 'El kg neto unitario debe ser mayor o igual a 0'),
        'BienUso': Yup.string().nullable(),
        'Otros': Yup.string().nullable(),
        'CertificadoOrigen': Yup.string().nullable(),
        'Naladisa': Yup.string().nullable(),
        'Estado': Yup.string().nullable()
    });
    let history = useHistory();
    let api = useApi();
    React.useEffect(() => {
        async function cargar() {
            try {
                if (!id) {
                    updateNotFound(true);
                    return;
                }
                let respuesta = await api.getBitacora(id);
                if (!respuesta) {
                    updateNotFound(true);
                    return;
                }
                updateIntervenciones({ tipo: 'setIntervencionesArticulo', intervenciones: respuesta.Intervenciones ?? [] });
                updateVentajas(respuesta.Bitacora.Ventajas ?? []);
                updateInfComps(respuesta.Bitacora.InformacionComplementaria ?? []);
                updateObservaciones(respuesta.Bitacora.Observaciones?.map((observacion: any, indice: number) => ({
                    Valor: observacion, Indice: indice
                }) ?? []));
                updateCargando(false);
                let certOrigen;
                if (respuesta.Bitacora.CertificadoOrigen === null || respuesta.Bitacora.CertificadoOrigen === undefined) {
                    certOrigen = null;
                } else if (respuesta.Bitacora.CertificadoOrigen) {
                    certOrigen = 'Sí';
                } else {
                    certOrigen = 'No';
                }
                formikRef.current?.resetForm({
                    values: {
                        'CodigoCatalogo': respuesta.Bitacora.CodigoCatalogo,
                        'CodigoArticulo': respuesta.Bitacora.CodigoArticulo,
                        'Ncm': respuesta.Bitacora.Ncm,
                        'Descripcion': respuesta.Bitacora.Descripcion,
                        'DescripcionMaria': respuesta.Bitacora.DescripcionMaria,
                        'KgUnitario': respuesta.Bitacora.KgUnitario,
                        'CodigoUnidadDeclarada': respuesta.Bitacora.CodigoUnidadDeclarada,
                        'CodigoPaisOrigen': respuesta.Bitacora.CodigoPaisOrigen,
                        'Sufijos': respuesta.Bitacora.Sufijos,
                        'CodigoEstadoMercaderia': respuesta.Bitacora.CodigoEstadoMercaderia,
                        'PrecioUnitario': respuesta.Bitacora.PrecioUnitario,
                        'BienUso': respuesta.Bitacora.BienUso,
                        'Otros': respuesta.Bitacora.Otros,
                        'CertificadoOrigen': certOrigen,
                        'Naladisa': respuesta.Bitacora.Naladisa,
                        'Estado': respuesta.Bitacora.Estado
                    }
                });
                cargarUnidadEstadisticaEIntervenciones(respuesta.Bitacora.Ncm);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar bitacora', error);
                    mostrarError('Error al cargar bitacora');
                }
            }
        }
        cargar();
        //eslint-disable-next-line 
    }, []);
    React.useEffect(() => {
        grillaVentajasRef.current?.recargar();
    }, [ventajas]);
    React.useEffect(() => {
        grillaInfCompsRef.current?.recargar();
    }, [infComps]);
    React.useEffect(() => {
        grillaObservacionesRef.current?.recargar();
    }, [observaciones]);
    React.useEffect(() => {
        grillaIntervencionesRef.current?.recargar();
    }, [estadoIntervenciones.listaGrilla]);
    async function cargarUnidadEstadisticaEIntervenciones(ncm: string) {
        try {
            let respuesta = await api.validarNcmCompleto(ncm);
            if (respuesta) {
                updateIntervenciones({ tipo: 'setIntervencionesNcm', intervenciones: respuesta.Intervenciones ?? [] });
                let codigoUnidadEstadistica = respuesta.Ncm.m012;
                try {
                    let unidades = await api.getUnidades();
                    let unidad = unidades.find((unidad: any) => unidad.Codigo === codigoUnidadEstadistica);
                    if (unidad) {
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica + ' - ' + unidad.Descripcion);
                    } else {
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica);
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al buscar unidad estadistica de ncm', error);
                        updateMensajeUnidadEstadistica(codigoUnidadEstadistica);
                    }
                }
            } else {
                formikRef.current?.setFieldValue('Ncm', '', true);
                updateMensajeUnidadEstadistica('');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al buscar unidad estadistica de ncm', error);
                formikRef.current?.setFieldValue('Ncm', '', true);
                updateMensajeUnidadEstadistica('');
            }
        }
    }
    async function cargarEstados() {
        try {
            let respuesta = await api.getEstadosArticulo();
            return respuesta.map((item: any) => ({ value: item.Nombre, label: item.Nombre }));
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al cargar los estados disponibles', error);
                mostrarError('Error al cargar los estados disponibles');
            }
            return [];
        }
    }
    return !notFound ?
        (<>
            <h2>Bitacora de articulo</h2>
            {/* {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)} */}
            <Formik initialValues={{}} innerRef={formikRef} validationSchema={schema} onSubmit={() => { }}>
                <MyForm blocking={cargando}>
                    <Tabs id="tabsCrearEditarArticulo">
                        <Tab eventKey="principal" title="Datos">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormControl type="text" disabled plaintext name="CodigoCatalogo" label="Catalogo"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl type="text" disabled plaintext name="CodigoArticulo" label="Codigo Articulo"></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea disabled plaintext name="Descripcion" label="Descripción Catalogo"></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea disabled plaintext name="DescripcionMaria" label="Descripción SEDI"></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormControl type="text" disabled plaintext name="Ncm" label="Posición"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col} controlId="unidadEstadistica">
                                    <Form.Label>Unidad Estadistica</Form.Label>
                                    <Form.Control type="text" disabled value={mensajeUnidadEstadistica}></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl type="number" disabled plaintext name="PrecioUnitario" label="Precio Unitario"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl type="number" disabled plaintext name="KgUnitario" label="Kg Neto Unitario"></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MySelect name="CodigoPaisOrigen" isDisabled label="Pais Origen/Provincia" options={() => api.getPaises().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect name="CodigoUnidadDeclarada" isDisabled label="Unidad Declarada" options={() => api.getUnidades().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect name="CodigoEstadoMercaderia" isDisabled label="Estado Mercaderia" options={() => api.getEstadosMercaderia().then(convertirDatosGenericosAOption)}
                                        getOptionLabel={optionLabelConCodigo} />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect name="BienUso" label="Bien de Uso" isDisabled options={[{ value: 'S', label: 'S' }, { value: 'N', label: 'N' }]} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyFormControl name="Naladisa" disabled plaintext label="Naladisa" type="text"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl name="Otros" disabled plaintext label="Otros" type="text"></MyFormControl>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MySelect isDisabled name="Estado" label="Estado Articulo"
                                        options={cargarEstados} ></MySelect>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <MyFormControl name="CertificadoOrigen" disabled plaintext label="Certificado Origen" type="text"></MyFormControl>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <MyTextarea name="Sufijos" label="Sufijos" disabled plaintext readOnly></MyTextarea>
                                </Form.Group>
                            </Form.Row>
                        </Tab>
                        <Tab eventKey="ventajas" title="Ventajas">
                            <div className="mt-2"><Grilla ref={grillaVentajasRef}
                                cargarDatos={cargarDatosGrillaDeArray(ventajas)}
                                campos={[{ propiedad: 'Codigo', titulo: 'Codigo Ventaja', clave: true }, {
                                    propiedad: 'Subregimenes', titulo: 'Subregimenes',
                                    plantillaFormato: (subregimenes: any) => subregimenes?.join(',')
                                }]} mostrarFormCambiarPagina={false} /></div>
                        </Tab>
                        <Tab eventKey="infComp" title="Información Complementaria">
                            <div className="mt-2">
                                <Grilla ref={grillaInfCompsRef} cargarDatos={cargarDatosGrillaDeArray(infComps)}
                                    campos={[{ propiedad: 'Codigo', titulo: 'Codigo Inf. Comp.', clave: true },
                                    { propiedad: 'Valor', titulo: 'Valor' }, {
                                        propiedad: 'Subregimenes', titulo: 'Subregimenes',
                                        plantillaFormato: (subregimenes: any) => subregimenes?.join(',')
                                    }]} mostrarFormCambiarPagina={false} /></div>
                        </Tab>
                        <Tab eventKey="observaciones" title="Observaciones">
                            <div className="mt-2">
                                <Grilla ref={grillaObservacionesRef} cargarDatos={cargarDatosGrillaDeArray(observaciones)}
                                    campos={[{ propiedad: 'Indice', titulo: '', visible: false, clave: true },
                                    { propiedad: 'Valor', titulo: 'Valor' }]} mostrarFormCambiarPagina={false} /></div>
                        </Tab>
                        <Tab eventKey="intervenciones" title="Intervenciones">
                            <div className="mt-2">
                                <Grilla ref={grillaIntervencionesRef} cargarDatos={cargarDatosGrillaDeArray(estadoIntervenciones.listaGrilla)}
                                    campos={[{ propiedad: 'Indice', titulo: '', visible: false, clave: true }, {
                                        propiedad: 'Descripcion', titulo: 'Intervencion',
                                        plantillaFormato: (valor: any, item: any) => item.Id ? valor : `* ${valor} *`
                                    }]} mostrarFormCambiarPagina={false} />
                            </div>
                        </Tab>
                    </Tabs>
                    <Button variant="danger" className="mt-2 mb-2" onClick={() => history.goBack()}>Volver</Button>
                </MyForm>
            </Formik>
        </>) : (<NotFoundComponent />);
}