import { userClient, mostrarError, AppContext } from './App';
import { createQueryString, isNullOrWhiteSpace, sanitizarNombreInternoParaArchivo, strCmp } from './Utilidades';
import { useCancel, UnmountedError, CancelToken } from "SintiaHooks";
import { useContext } from 'react';
import contentDisposition from 'content-disposition';
import { DateTime } from 'luxon';
import base64js from 'base64-js';
import { GoogleDriveAuthStore } from "GoogleDriveHooks";
import axios from 'axios';


export enum TipoLock {
    Caratula = 0,
    CaratulaProcesoExcel,
    Catalogo,
    Importador,
    Despachante,
    Vendedor,
    ReglaValidacionMaria
};

export enum TipoNcm {
    Ncm = 0,
    TRAM,
    Particular,
    Rancho
}

type DatoGenerico = {
    Codigo: string,
    Nombre: string | null | undefined,
    Descripcion: string | null | undefined
};

type ModeloConfiguracion = { Configuracion: any, ConfiguracionAdmin: any };

function cargarDatosGenericos(path: string, mensajeError: string): Promise<any> {
    let intentos = 0;
    function llamarPromesa(resolve: any, reject: any) {
        userClient.get(path).then(respuesta => resolve(respuesta.data)).catch(error => {
            intentos++;
            console.error(mensajeError, error);
            if (intentos < 5) {
                return llamarPromesa(resolve, reject);
            } else {
                mostrarError(mensajeError);
                reject(error);
            }
        });
    }
    return new Promise(llamarPromesa);
}

function cargarDatosGenericosCacheado(path: string, mensajeError: string) {
    const vacio = {};
    let resultado: any = vacio;
    return () => {
        if (resultado === vacio) {
            resultado = cargarDatosGenericos(path, mensajeError);
        }
        return resultado;
    }
}
function ordenarDatosGenericosPorDescripcion(lista: DatoGenerico[]) {
    return lista.sort((a, b) => {
        let valorA = '';
        let valorB = '';
        if (!isNullOrWhiteSpace(a.Nombre)) {
            valorA = a.Nombre as string;
        } else {
            valorA = a.Descripcion ?? '';
        }
        if (!isNullOrWhiteSpace(b.Nombre)) {
            valorB = b.Nombre as string;
        } else {
            valorB = b.Descripcion ?? '';
        }
        return strCmp(valorA, valorB);
    });
}

const getPaises = cargarDatosGenericosCacheado('/DatosGenericos/Pais', 'Error al cargar paises');
const getUnidades = cargarDatosGenericosCacheado('/DatosGenericos/Unidad', 'Error al cargar unidades');
const getEstadosMercaderia = cargarDatosGenericosCacheado('/DatosGenericos/EstadoMercaderia', 'Error al cargar estados de mercaderia');
const getSubregimenes = cargarDatosGenericosCacheado('/DatosGenericos/Subregimen', 'Error al cargar subregimenes');
const getVentajas = cargarDatosGenericosCacheado('/DatosGenericos/Ventaja', 'Error al cargar ventajas');
const getInformacionComplementaria = cargarDatosGenericosCacheado('/DatosGenericos/InformacionComplementaria', 'Error al cargar informacion complementaria');
const getLista = cargarDatosGenericosCacheado('/DatosGenericos/Lista', 'Error al cargar lista');
const getTiposResponsable = cargarDatosGenericosCacheado('/DatosGenericos/TipoResponsable', 'Error al cargar tipos responsable');
const getAduanas = cargarDatosGenericosCacheado('/DatosGenericos/Aduana', 'Error al cargar aduanas');
const getMonedas = cargarDatosGenericosCacheado('/DatosGenericos/Moneda', 'Error al cargar monedas');
const getCondicionesVenta = cargarDatosGenericosCacheado('/DatosGenericos/CondicionVenta', 'Error al cargar condiciones venta');
const getMotivosSuspensiva = cargarDatosGenericosCacheado('/DatosGenericos/MotivoDestSuspensiva', 'Error al cargar motivos suspensiva');
const getBancosTerceros = cargarDatosGenericosCacheado('/DatosGenericos/BancoTerceros', 'Error al cargar bancos terceros');
const getCamposSubregimen = cargarDatosGenericosCacheado('/DatosGenericos/CamposSubregimen', 'Error al cargar campos subregimen');
const getConceptosLiquidacion = cargarDatosGenericosCacheado('/DatosGenericos/ConceptoLiquidacion', 'Error al cargar conceptos liquidacion');
const getVias = cargarDatosGenericosCacheado('/DatosGenericos/Via', 'Error al cargar vias');
const getCodigosEmbarque = cargarDatosGenericosCacheado('/DatosGenericos/CodigoEmbarque', 'Error al cargar codigos embarque');
const getTiposEmbarque = cargarDatosGenericosCacheado('/DatosGenericos/TipoEmbarque', 'Error al cargar tipos embarque');

const errorCatalogosNoAutorizado = 'No está autorizado a modificar los catalogos';
export function useApi() {
    let { getCancelToken, cancelCurrentTokens, isUnmounted, isCancel } = useCancel();
    let { userInfo } = useContext(AppContext);
    function cargarNcm(tipos?: TipoNcm[], throwOnCancel?: boolean) {
        tipos = tipos ?? [TipoNcm.Ncm, TipoNcm.TRAM, TipoNcm.Rancho, TipoNcm.Particular];
        return async (inputValue: string) => {
            try {
                let respuesta = await userClient.post('/Ncm/BusquedaNcm', {
                    BusquedaPosicion: inputValue,
                    Tipos: tipos
                }, { cancelToken: getCancelToken() });
                return respuesta.data.map((item: any) => ({ value: item.Posicion, label: item.Posicion }));
            } catch (error) {
                if (isCancel(error)) {
                    if (throwOnCancel) {
                        throw error;
                    } else {
                        return [];
                    }
                } else {
                    console.error('Error al cargar ncm', error);
                    mostrarError('Error al cargar ncm');
                }
            }
        }
    }

    async function validarNcmCompleto(ncm: string) {
        try {
            let respuesta = await userClient.get('/Ncm/Detalle' + createQueryString({ PosicionArancelaria: ncm }),
                { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            }
            throw error;
        }
    }

    async function validarNcmPorPartes(parte1: string, parte2: string) {
        try {
            let respuesta = await userClient.get('/Ncm/DetallePorPartes' + createQueryString({ Parte1: parte1, Parte2: parte2 }),
                { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            }
            throw error;
        }
    }

    async function validarNcmArreglandoPuntos(ncm: string | null | undefined) {
        if (isNullOrWhiteSpace(ncm)) {
            return null;
        }
        ncm = ncm!.replaceAll(',', '.');
        switch (ncm.length) {
            case 11: //sin punto,sin letra
                if (!/^\d+$/.test(ncm)) { //ver si tiene caracteres que no son digitos
                    return null;
                }
                let parte1 = ncm.substring(0, 4) + '.' + ncm.substring(4, 2) + '.' + ncm.substring(6, 2);
                let parte2 = ncm.substring(8, 3);
                return await validarNcmPorPartes(parte1, parte2);
            case 12://sin punto,con letra
                if (!/^\d+$/.test(ncm.substring(0, 10))) { //ver si tiene caracteres que no son digitos
                    return null;
                }
                let ncmCompleto = ncm.substring(0, 4) + '.' + ncm.substring(4, 2) + '.' + ncm.substring(6, 2) + '.' + ncm.substring(8, 4);
                return await validarNcmCompleto(ncmCompleto);
            case 14: //con puntos,sin letra
                if (ncm.charAt(4) !== '.' || ncm.charAt(7) !== '.' || ncm.charAt(10) !== '.') {
                    return null;
                }
                if (!/^\d+$/.test(ncm.replaceAll('.', ''))) { //ver si tiene caracteres que no son digitos
                    return null;
                }
                parte1 = ncm.substring(0, 10);
                parte2 = ncm.substring(8, 3);
                return await validarNcmPorPartes(parte1, parte2);
            case 15: //completo:
                if (ncm.charAt(4) !== '.' || ncm.charAt(7) !== '.' || ncm.charAt(10) !== '.') {
                    return null;
                }
                if (!/^\d+$/.test(ncm.substring(0, 14).replaceAll('.', ''))) { //ver si tiene caracteres que no son digitos
                    return null;
                }
                return await validarNcmCompleto(ncm);
        }
        return null;
    }

    async function cargarDatosGenericosOrdenadoPorCodigo(promise: Promise<any>) {
        let datos = await promise;
        if (isUnmounted()) {
            throw new UnmountedError();
        }
        return datos.sort((a: any, b: any) => strCmp(a.Codigo, b.Codigo));
    }

    async function cargarDatosGenericosOrdenadoPorDescripcion(promise: Promise<any>) {
        let datos = await promise;
        if (isUnmounted()) {
            throw new UnmountedError();
        }
        return ordenarDatosGenericosPorDescripcion(datos) as any[];
    }

    const getPaisesExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getPaises());
    const getUnidadesExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getUnidades());
    const getEstadosMercaderiaExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getEstadosMercaderia());
    const getSubregimenesExt = () => cargarDatosGenericosOrdenadoPorCodigo(getSubregimenes());
    const getVentajasExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getVentajas());
    const getInformacionComplementariaExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getInformacionComplementaria());
    const getListaExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getLista());
    const getTiposResponsableExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getTiposResponsable());
    const getAduanasExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getAduanas());
    const getMonedasExt = async () => {
        let datos = await getMonedas();
        if (isUnmounted()) {
            throw new UnmountedError();
        }
        datos = ordenarDatosGenericosPorDescripcion(datos);
        let indiceDol = datos.findIndex((d: any) => d.Codigo === 'DOL');
        if (indiceDol > -1) {
            let itemDol = datos[indiceDol];
            datos.splice(indiceDol, 1);
            datos.unshift(itemDol);
        }
        return datos as any[];
    }
    const getCondicionesVentaExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getCondicionesVenta());
    const getMotivosSuspensivaExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getMotivosSuspensiva());
    const getBancosTercerosExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getBancosTerceros());
    const getCamposSubregimenExt = async () => {
        let datos = await getCamposSubregimen();
        if (isUnmounted()) {
            throw new UnmountedError();
        }
        return datos;
    };
    const getConceptosLiquidacionExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getConceptosLiquidacion());
    const getViasExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getVias());
    const getCodigosEmbarqueExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getCodigosEmbarque());
    const getTiposEmbarqueExt = () => cargarDatosGenericosOrdenadoPorDescripcion(getTiposEmbarque());

    async function subregimenLlevaBultos(codigo: string | null | undefined) {
        if (isNullOrWhiteSpace(codigo)) {
            return false;
        }
        let subregimenes = await getSubregimenesExt();
        let subregimen = subregimenes.find((s: any) => s.Codigo === codigo);
        return subregimen?.Tipo === 'E' || subregimen?.Codigo?.startsWith('ZFI');
    }

    async function getLiquidacion(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/Liquidacion' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }


    async function getPuertosBusqueda(valorPuerto: string) {
        try 
        {

            let respuesta = await userClient.get('/DatosGenericos/PuertosBusqueda' + createQueryString({
                                                            descripcionPuerto: valorPuerto}) 
                                                            //,{ cancelToken: getCancelToken() }
                                                );
            return cargarDatosGenericosOrdenadoPorDescripcion(respuesta.data);

        } catch (error) {
            console.log('getPuertosBusqueda ' + error);
        }

    }


    async function getCompaniasAereas(valorAereo: string) {

        try 
        {

            let respuesta = await userClient.get('/DatosGenericos/CompaniasAereas' + createQueryString({
                                                descripcionAereo: valorAereo}) 
                                                //,{ cancelToken: getCancelToken() }
                                           );
            return cargarDatosGenericosOrdenadoPorDescripcion(respuesta.data);

        } catch (error) {
            console.log('getCompaniasAereas '  + error);
        }
    
    }


    async function getDepositos(aduana: string, codigo: string) {

        try 
        {

            let respuesta = await userClient.get('/DatosGenericos/Depositos' + createQueryString(
                                                {
                                                    Aduana: aduana,
                                                    Codigo: codigo
                                                }) 
                                                //,{ cancelToken: getCancelToken() }
                                           );
            return respuesta.data;

        } catch (error) {
            console.log('getDepositos '  + error);
        }
    
    }





    async function getArmadoItems(interno: string) {
        try {
            let respuesta = await userClient.get('/CaratulasSintia/GetArmadoItems' + createQueryString({
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }

    async function getCotizacionesDivisas() {
        let respuesta = await axios.get('https://api.sintia.com.ar/api/divisas',
            { cancelToken: getCancelToken() });
        return respuesta.data ?? [];
    }

    async function getAvisosArmadoItems(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetAvisosArmadoItems' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function getCertificacionesArmadoItems(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetCertificacionesArmadoItems' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function obtenerLock(tipoLock: TipoLock, nombre: string, nombre2: string | undefined = undefined)
        : Promise<boolean> {
        let parametros = {
            EmpresaId: userInfo.empresaActual, Tipo: tipoLock as number, Nombre: nombre,
            Nombre2: nombre2
        };
        try {
            let resultado = await userClient.post('/Lock/Obtener', parametros, { cancelToken: getCancelToken() });
            return resultado.data.exito;
        } catch (error) {
            if (axios.isCancel(error)) {
                throw error;
            } else {
                console.error('Error al obtener lock', error);
            }
            return false;
        }
    }

    async function eliminarLock(tipoLock: TipoLock, nombre: string,
        nombre2: string | undefined = undefined) {
        let parametros = {
            EmpresaId: userInfo.empresaActual, Tipo: tipoLock as number, Nombre: nombre,
            Nombre2: nombre2
        };
        try {
            await userClient.post('/Lock/Eliminar', parametros);
        } catch (error) {
            console.error('Error al eliminar lock', error);
        }
        if (isUnmounted()) {
            throw new UnmountedError();
        }
    }


    async function insertLiquidacion(caratulaId: any, liquidacionesItems: any, basesItem: any) {
        await userClient.post('/CaratulasSintia/InsertLiquidacion', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            CaratulaId: caratulaId,
            LiquidacionesItems: liquidacionesItems,
            BasesItem: basesItem
        }, { cancelToken: getCancelToken() });
    }

    async function insertArmadoItems(caratulaId: any, items: any, subitems: any, mapeoSubitems: any,
        infComps: any, cancelaciones: any, ventajas: any) {
        let respuesta = await userClient.post('/CaratulasSintia/InsertArmadoItem', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
            CaratulaId: caratulaId,
            Items: items,
            SubitemsAgrupados: subitems,
            MapeoSubitems: mapeoSubitems,
            InformacionComplementaria: infComps,
            CancelacionesItems: cancelaciones,
            Ventajas: ventajas
        }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function exportarArmadoItemsAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Armado items de carpeta ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }

    async function exportarAvisosArmadoItemsAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelAvisosArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Avisos de carpeta ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }

    async function exportarCertificacionesArmadoItemsAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelCertificacionesArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Certificaciones de carpeta ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }

    async function exportarReporteLnaArmadoItemsAExcel(interno: string, ordenarPorAnexo: boolean) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteLNAArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno, OrdenarPorAnexo: ordenarPorAnexo }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte Items con LNA de interno ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }

    async function exportarReporteValorCriterioArmadoItemsExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteValorCriterioArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte Items con valor criterio de interno ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }

    async function getReporteSubitemsCaratulaConIntervenciones(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ReporteSubitemsCaratulaConIntervenciones' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteSubitemsCaratulaConIntervencionesAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteSubitemsCaratulaConIntervenciones'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte intervenciones subitems ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getReporteParaCancelaciones(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ReporteParaCancelaciones' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteParaCancelacionesAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteParaCancelaciones'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte para cancelaciones ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getEstadoCaratula(interno: string) {
        try {
            let respuesta = await userClient.get('/CaratulasSintia/GetEstadoCaratula'
                + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
                cancelToken: getCancelToken()
            });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getMensajesArmadoItems(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/SelectMensajesArmadoItems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function getReporteGravamenesSubitemsCaratula(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ReporteGravamenesSubitemsCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteGravamenesSubitemsCaratulaAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteGravamenesSubitemsCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte gravamenes subitems ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getReporteSubitemsDetallado(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ReporteSubitemsCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteSubitemsDetalladoAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteSubitemsCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte subitems detallado ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getSubitemsCaratula(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetSubitems'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getCancelacionesSubitemsCaratula(interno: string, subitemId?: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetCancelacionesSubitems' + createQueryString({
            EmpresaId: userInfo.empresaActual, Carpeta: interno, SubitemId: subitemId
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteMineriaAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteMineria'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte subitems mineria ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getReporteSubitemsCaratulaConValorCriterio(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ReporteSubitemsCaratulaConValorCriterio'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function exportarReporteSubitemsCaratulaConValorCriterioAExcel(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/ExcelReporteSubitemsCaratulaConValorCriterio'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte subitems valor criterio ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getCatalogo(codigoCatalogo: string) {
        try {
            let respuesta = await userClient.get('/Catalogo/GetCatalogo' +
                createQueryString({ Codigo: codigoCatalogo }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getCatalogos() {
        let respuesta = await userClient.get('/Catalogo/GetCatalogos',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaArticulosCompletoPaginado(catalogos: any, codigoArticulo: any,
        descripcion: any, ncm: any, buscarPorFecha: boolean, fechaDesde: any, fechaHasta: any,
        desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.post('/Catalogo/BusquedaArticulosCompletoPaginado', {
            CodigosCatalogos: catalogos, CodigoArticulo: codigoArticulo,
            Descripcion: descripcion, Ncm: ncm,
            ModificadoDesde: buscarPorFecha ? fechaDesde : null,
            ModificadoHasta: buscarPorFecha ? fechaHasta : null,
            Desde: desde, Hasta: hasta
        }, {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function getDespachante(cuit: string) {
        let respuesta = await userClient.get('/DespachanteImportador/GetDespachante'
            + createQueryString({ Cuit: cuit }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getDespachantes() {
        let respuesta = await userClient.get('/DespachanteImportador/GetDespachantes', {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function getDespachantesPaginado(busqueda: any, desde: number, hasta: number,
        cancelToken: CancelToken) {
        let respuesta = await userClient.get('/DespachanteImportador/GetDespachantesPaginado'
            + createQueryString({ Busqueda: busqueda, Desde: desde, Hasta: hasta }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function getImportadores() {
        let respuesta = await userClient.get('/DespachanteImportador/GetImportadores', {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function getEmpresasUsuario() {
        let respuesta = await userClient.get('/Account/GetEmpresasUsuario',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaCaratulaPaginado(despachante: any, importador: any,
        usarFechas: boolean, fechaDesde: any, fechaHasta: any, carpeta: any, desde: number, hasta: number,
        cancelToken: CancelToken) {
        let fechaDesdeFinal = usarFechas ? fechaDesde : null;
        let fechaHastaFinal = usarFechas ? DateTime.fromISO(fechaHasta)
            .plus({ days: 1 }).plus({ seconds: -1 }).toISODate() : null;
        let respuesta = await userClient.post('/CaratulasSintia/BusquedaCaratulaPaginado',
            {
                EmpresaId: userInfo.empresaActual,
                CuitDespachante: despachante,
                CuitImportador: importador,
                FechaCreacionDesde: fechaDesdeFinal,
                FechaCreacionHasta: fechaHastaFinal,
                Carpeta: carpeta,
                Desde: desde, Hasta: hasta
            }, { cancelToken: cancelToken });
        return respuesta.data;
    }
    async function exportarBusquedaCaratulaAExcel(despachante: any, importador: any,
        usarFechas: boolean, fechaDesde: any, fechaHasta: any, carpeta: any) {
        let fechaDesdeFinal = usarFechas ? fechaDesde : null;
        let fechaHastaFinal = usarFechas ? DateTime.fromISO(fechaHasta)
            .plus({ days: 1 }).plus({ seconds: -1 }).toISODate() : null;
        let respuesta = await userClient.post('/CaratulasSintia/ExcelBusquedaCaratula',
            {
                EmpresaId: userInfo.empresaActual,
                CuitDespachante: despachante,
                CuitImportador: importador,
                FechaCreacionDesde: fechaDesdeFinal,
                FechaCreacionHasta: fechaHastaFinal,
                Carpeta: carpeta,
            }, {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte caratulas.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function busquedaCaratulaExtendidaPaginado(despachante: any, importador: any,
        usarFechas: boolean, fechaDesde: any, fechaHasta: any, carpeta: any, desde: number, hasta: number,
        cancelToken: CancelToken) {
        let fechaDesdeFinal = usarFechas ? fechaDesde : null;
        let fechaHastaFinal = usarFechas ? DateTime.fromISO(fechaHasta)
            .plus({ days: 1 }).plus({ seconds: -1 }).toISODate() : null;
        let respuesta = await userClient.post('/CaratulasSintia/BusquedaCaratulaExtendidaPaginado',
            {
                EmpresaId: userInfo.empresaActual,
                CuitDespachante: despachante,
                CuitImportador: importador,
                FechaCreacionDesde: fechaDesdeFinal,
                FechaCreacionHasta: fechaHastaFinal,
                Carpeta: carpeta,
                Desde: desde, Hasta: hasta
            }, { cancelToken: cancelToken });
        return respuesta.data;
    }
    async function exportarBusquedaCaratulaExtendidaAExcel(despachante: any, importador: any,
        usarFechas: boolean, fechaDesde: any, fechaHasta: any, carpeta: any) {
        let fechaDesdeFinal = usarFechas ? fechaDesde : null;
        let fechaHastaFinal = usarFechas ? DateTime.fromISO(fechaHasta)
            .plus({ days: 1 }).plus({ seconds: -1 }).toISODate() : null;
        let respuesta = await userClient.post('/CaratulasSintia/ExcelBusquedaCaratulaExtendida',
            {
                EmpresaId: userInfo.empresaActual,
                CuitDespachante: despachante,
                CuitImportador: importador,
                FechaCreacionDesde: fechaDesdeFinal,
                FechaCreacionHasta: fechaHastaFinal,
                Carpeta: carpeta,
            }, {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte caratulas extendido.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function getConfiguracionSintia(): Promise<ModeloConfiguracion> {
        let respuesta = await userClient.get('/Configuracion/GetConfiguracionSintia',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getConfiguracionEmpresa(): Promise<ModeloConfiguracion> {
        let respuesta = await userClient.get('/Configuracion/GetConfiguracionEmpresa' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getTiposOperacion() {
        let respuesta = await userClient.get('/CaratulasSintia/SelectTiposOperacion' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getCaratula(interno: string, empresaId?: string) {
        try {
            let respuesta = await userClient.get('/CaratulasSintia/GetCaratula' + createQueryString({
                EmpresaId: empresaId ?? userInfo.empresaActual,
                Carpeta: interno
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function crearCaratula(interno: string, tipo?: string) {
        try {
            await userClient.post('/CaratulasSintia/CrearCaratula', {
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                TipoOperacion: tipo
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function crearCaratulaAutonumerico(tipo: string) {
        try {
            await userClient.post('/CaratulasSintia/CrearCaratulaAutonumerico', {
                EmpresaId: userInfo.empresaActual,
                Tipo: tipo
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function copiarCaratula(carpetaACopiar: string, empresaCopia: string, nombreCopia: string, convertirncmtocodafip: boolean) {
        try {
            await userClient.post('/CaratulasSintia/CopiarCaratula', {
                EmpresaId: userInfo.empresaActual,
                CarpetaACopiar: carpetaACopiar,
                EmpresaCopia: empresaCopia,
                NombreCopia: nombreCopia,
                ConvertirNCMtoCODAFIP: convertirncmtocodafip
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function unificarCaratulas(carpetasAUnificar: string[], nuevaCarpeta: string) {
        try {
            await userClient.post('/CaratulasSintia/UnificarCaratulas', {
                EmpresaId: userInfo.empresaActual,
                CarpetasAUnificar: carpetasAUnificar,
                NuevaCarpeta: nuevaCarpeta,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function eliminarCaratula(interno: string) {
        try {
            await userClient.post('/CaratulasSintia/DeleteCaratula', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getVendedores() {
        let respuesta = await userClient.get('/Vendedor/Select' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getVendedor(codigo: string) {
        try {
            let respuesta = await userClient.get('/Vendedor/Select' +
                createQueryString({ EmpresaId: userInfo.empresaActual, Codigo: codigo }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getImportador(cuit: string) {
        let respuesta = await userClient.get('/DespachanteImportador/GetImportador' +
            createQueryString({ Cuit: cuit }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getTipoCambioGuardado(divisa: string) {
        let respuesta = await userClient.get('/TipoCambio/Select'
            + createQueryString({ CodigoMoneda: divisa }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function guardarTipoCambio(divisa: string, valor: string) {
        await userClient.post('/TipoCambio/Insert', {
            NroClienteAlpha: userInfo.nroClienteAlpha, CodigoMoneda: divisa, Valor: valor
        }, { cancelToken: getCancelToken() });
    }
    async function updateCaratula(modelo: any) {
        try {
            await userClient.post('/CaratulasSintia/UpdateCaratula', {
                ...modelo, EmpresaId: userInfo.empresaActual
            }, {
                cancelToken: getCancelToken()
            });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getTiposReferencia() {
        let respuesta = await userClient.get('/CaratulasSintia/GetTiposReferencia', {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function getPlantillasCaratula() {
        let respuesta = await userClient.get('/CaratulasSintia/GetPlantillasCaratula' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function exportarCaratulaPdf(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/PdfImprimirCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        return respuesta.data;
    }
    async function exportarResumenCaratulaPdf(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/PdfResumenCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        return respuesta.data;
    }
    async function updateDatosCarpeta(modelo: any) {
        await userClient.post('/CaratulasSintia/UpdateDatosCarpeta', {
            ...modelo,
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
        }, { cancelToken: getCancelToken() });
    }
    async function cargarPlantillaEnCaratula(interno: string, nombrePlantilla: string, soloTareas: boolean) {
        let respuesta = await userClient.post('/CaratulasSintia/CargarPlantillaEnCaratula', {
            NombrePlantilla: nombrePlantilla,
            Carpeta: interno,
            EmpresaId: userInfo.empresaActual,
            SoloTareas: soloTareas
        }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function borrarArmadoItems(interno: string) {
        await userClient.post('/CaratulasSintia/BorrarArmadoItems', {
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }, { cancelToken: getCancelToken() });
    }
    async function getCantidadSubitemsSinDescripcionMaria(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/CantidadSubitemsSinDescripcionMaria' +
            createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return parseInt(respuesta.data);
    }
    async function getCantidadSubitemsSinDescripcion(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/CantidadSubitemsSinDescripcion' +
            createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return parseInt(respuesta.data);
    }
    async function caratulaTieneSubitemsConImpuestosInternos(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/CaratulaTieneSubitemsConImpuestosInternos' + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }),
            { cancelToken: getCancelToken() });
        return respuesta.data.TieneImpuestosInternos as boolean;
    }
    async function solicitarArmadoItems(interno: string, orden: any, calcularCoeficienteExpo: boolean,
        calcularRegimenMineria: any, calcularMulta: any, calcularImpuestosInternos: boolean,
        paisCooperanteAnticipoGanancias: boolean | null, agrupamientoDescripcionSimi: any,
        agregarReduccionIva: boolean, agregarReduccionGanancias: boolean) {
        await userClient.post('/CaratulasSintia/SolicitarArmadoItems', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno,
            Ordenamiento: orden,
            AgruparPorNroArticulo: false,
            CalcularCoeficienteExpo: calcularCoeficienteExpo,
            CalcularRegimenMineria: calcularRegimenMineria,
            CalcularMultas: calcularMulta,
            CalcularImpuestosInternos: calcularImpuestosInternos,
            PaisCooperanteAnticipoGanancias: paisCooperanteAnticipoGanancias,
            AgrupamientoDescripcionSimi: agrupamientoDescripcionSimi,
            AgregarReduccionIva: agregarReduccionIva,
            AgregarReduccionGanancias: agregarReduccionGanancias
        }, { cancelToken: getCancelToken() });
    }
    async function updateBloqueoCaratula(interno: string, valor: boolean) {
        await userClient.post('/CaratulasSintia/UpdateBloqueoCaratula', {
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno,
            Valor: valor
        }, { cancelToken: getCancelToken() });
    }
    async function getTransportistas() {
        let respuesta = await userClient.get('/Transportista/Select',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertTransportista(transportista: { CUIT: string, Nombre: string }) {
        try {
            let respuesta = await userClient.post('/Transportista/Insert', {
                ...transportista,
                NroClienteAlpha: userInfo.nroClienteAlpha
            }, { cancelToken: getCancelToken() });
            return { exito: true, respuesta: respuesta.data };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los transportistas' };
            } else {
                throw error;
            }
        }
    }
    async function updateBultos(modelo: any) {
        await userClient.post('/CaratulasSintia/UpdateBultos', {
            ...modelo,
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
        }, { cancelToken: getCancelToken() });
    }
    async function generarTxtSintiaAdvanced(parametros: any) {
        let respuesta = await userClient.post('/CaratulasSintia/GenerarTxtMariaSintiaAdvanced',
            { ...parametros, EmpresaId: userInfo.empresaActual }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function generarTxtMaria(parametros: any) {
        let respuesta = await userClient.post('/CaratulasSintia/GenerarTxtMaria',
            { ...parametros, EmpresaId: userInfo.empresaActual },
            { cancelToken: getCancelToken(), responseType: 'blob' });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || sanitizarNombreInternoParaArchivo(parametros.Carpeta) + '.txt';
        return { fileName, txt: respuesta.data };
    }
    async function updateDatosIDA4(modelo: any) {
        let respuesta = await userClient.post('/CaratulasSintia/UpdateDatosIDA4', {
            ...modelo,
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
        }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaSubitemsCaratulaPaginado(interno: string, tipoBusqueda: any, valor: string,
        orden: any, sentidoOrden: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/CaratulasSintia/BusquedaSubitemsCaratulaPaginado'
            + createQueryString({
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                TipoBusqueda: tipoBusqueda,
                Valor: valor,
                TipoOrden: sentidoOrden,
                ColumnaOrden: orden,
                Desde: desde,
                Hasta: hasta
            }), { cancelToken: cancelToken });
        return respuesta.data;
    }
    async function deleteVariosSubitems(interno: string, ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/DeleteVariosSubitems', {
                Carpeta: interno,
                SubitemIds: ids,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerKilosEnCeroSubitems(interno: string, ids: string[],
        establecerCantidadEstadistica: boolean) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerKilosEnCeroSubitems', {
                Carpeta: interno,
                EstablecerCantidadEstadistica: establecerCantidadEstadistica,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerPaisOrigenSubitems(interno: string, ids: string[],
        codigoPaisOrigen: string | null) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerPaisOrigenSubitems', {
                Carpeta: interno,
                CodigoPais: codigoPaisOrigen,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerPaisProcedenciaSubitems(interno: string, ids: string[],
        codigoPaisProcedencia: string | null) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerPaisProcedenciaSubitems', {
                Carpeta: interno,
                CodigoPais: codigoPaisProcedencia,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerFillerSubitems(interno: string, ids: string[],
        filler: string | null) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerFillerSubitems', {
                Carpeta: interno,
                Filler: filler,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }

    async function establecerSufijoSubitems(interno: string, sufijo: string, nuevovalor: string , ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerSufijosSubitems', {
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                Sufijo: sufijo,
                nuevoValor: nuevovalor,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }


    async function vaciarCodigoProrrateoSubitems(interno: string, ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/VaciarCodigoProrrateoSubitems', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerFillerDistintosSubitems(interno: string, ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerFillerDistintosSubitems', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerFillerPorCodigoArticuloSubitems(interno: string, ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerFillerPorCodigoArticuloSubitems', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerFillerPorNroFacturaSubitems(interno: string, ids: string[]) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerFillerPorNroFacturaSubitems', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual,
                Ids: ids
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerNcmSubitems(interno: string, ncmViejo: string, ncmNuevo: string) {
        try {
            await userClient.post('/CaratulasSintia/EstablecerNcmSubitems', {
                Carpeta: interno,
                EmpresaId: userInfo.empresaActual,
                Ncm: ncmViejo,
                NcmNuevo: ncmNuevo
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getPdfReporteSubitemsCaratula(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/PdfReporteSubitemsCaratula' + createQueryString({
            EmpresaId: userInfo.empresaActual, Carpeta: interno
        }), { responseType: 'blob', cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaArticulos(codigosCatalogos: string[], codigoArticulo: string, ignorarLimite: boolean) {
        let respuesta = await userClient.post('/Catalogo/BusquedaArticulos',
            { CodigosCatalogos: codigosCatalogos, CodigoArticulo: codigoArticulo, IgnorarLimite: ignorarLimite },
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getValorCriterio(ncm: string, codigoPaisOrigen: string) {
        try {
            let respuesta = await userClient.get('/Ncm/ValorCriterio' + createQueryString({
                PosicionArancelaria: ncm, CodigoPaisOrigen: codigoPaisOrigen
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getArticulo(codigoCatalogo: string, codigoArticulo: string) {
        try {
            let respuesta = await userClient.get('/Catalogo/GetArticulo'
                + createQueryString({ CodigoCatalogo: codigoCatalogo, CodigoArticulo: codigoArticulo }),
                { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getSubitem(id: string) {
        try {
            let respuesta = await userClient.get('/CaratulasSintia/GetSubitem' +
                createQueryString({ SubitemId: id }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function insertSubitem(subitemNuevo: any) {
        try {
            await userClient.post('/CaratulasSintia/InsertSubitem', {
                ...subitemNuevo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof (error.response.data) === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getCaratulaPresupuesto(interno: string) {
        try {
            let respuesta = await userClient.get('/Presupuesto/GetCaratulaPresupuesto' + createQueryString({
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getPresupuesto(id: string) {
        try {
            let respuesta = await userClient.get('/Presupuesto/GetPresupuesto' + createQueryString({ Id: id }),
                { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getPlantillaImportador(id: string) {
        try {
            let respuesta = await userClient.get('/DespachanteImportador/GetPlantillaImportador'
                + createQueryString({ Id: id }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getPlantillasImportador(cuitImportador: string) {
        let respuesta = await userClient.get('/DespachanteImportador/GetTodasLasPlantillasImportador'
            + createQueryString({ Cuit: cuitImportador }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertPresupuesto(presupuesto: any) {
        try {
            let respuesta = await userClient.post('/Presupuesto/InsertPresupuesto', {
                ...presupuesto,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
            }, { cancelToken: getCancelToken() });
            return { exito: true, nuevoId: respuesta.data.Id };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getPdfPresupuesto(id: string) {
        let respuesta = await userClient.get('/Presupuesto/GetPdfPresupuesto'
            + createQueryString({ Id: id }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        return respuesta.data;
    }
    async function getTiposConceptoPresupuesto() {
        let respuesta = await userClient.get('/Presupuesto/GetTiposConcepto', {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function deleteTipoConceptoPresupuesto(nombre: string) {
        await userClient.post('/Presupuesto/DeleteTipoConcepto', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            Nombre: nombre
        }, { cancelToken: getCancelToken() });
    }
    async function insertTipoConceptoPresupuesto(nuevoConcepto: any) {
        await userClient.post('/Presupuesto/InsertTipoConcepto', {
            ...nuevoConcepto,
            NroClienteAlpha: userInfo.nroClienteAlpha,
        }, { cancelToken: getCancelToken() });
    }
    async function getTiposReferenciaPresupuesto() {
        let respuesta = await userClient.get('/Presupuesto/GetTiposReferencia', {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function deleteTipoReferenciaPresupuesto(nombre: string) {
        await userClient.post('/Presupuesto/DeleteTipoReferencia', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            Nombre: nombre
        }, { cancelToken: getCancelToken() });
    }
    async function insertTipoReferenciaPresupuesto(nuevaReferencia: any) {
        await userClient.post('/Presupuesto/InsertTipoConcepto', {
            ...nuevaReferencia,
            NroClienteAlpha: userInfo.nroClienteAlpha,
        }, { cancelToken: getCancelToken() });
    }
    async function getPresupuestosDeCaratula(interno: string) {
        let respuesta = await userClient.get('/Presupuesto/GetPresupuestosCaratula'
            + createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function deletePresupuesto(id: string) {
        await userClient.post('/Presupuesto/DeletePresupuesto', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            Id: id
        }, { cancelToken: getCancelToken() });
    }
    async function getTareas() {
        let respuesta = await userClient.get('/CaratulasSintia/GetTareas' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteTarea(id: string) {
        await userClient.post('/CaratulasSintia/DeleteTarea', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
            Id: id
        }, { cancelToken: getCancelToken() });
    }
    async function insertTarea(nuevaTarea: any) {
        try {
            let respuesta = await userClient.post('/CaratulasSintia/InsertTarea',
                {
                    ...nuevaTarea,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                    EmpresaId: userInfo.empresaActual,
                }, { cancelToken: getCancelToken() });
            return { exito: true, nuevaTarea: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getTareasCaratula(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetTareasCaratula' +
            createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function insertTareaCaratula(nuevaTarea: any) {
        try {
            let respuesta = await userClient.post('/CaratulasSintia/InsertTareaCaratula', {
                ...nuevaTarea,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
            }, { cancelToken: getCancelToken() });
            return { exito: true, tarea: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteTareaCaratula(id: string) {
        await userClient.post('/CaratulasSintia/DeleteTareaCaratula', { Id: id },
            { cancelToken: getCancelToken() });
    }
    async function subirOrdenTareaCaratula(id: string) {
        await userClient.post('/CaratulasSintia/SubirOrdenTareaCaratula', { Id: id },
            { cancelToken: getCancelToken() });
    }
    async function bajarOrdenTareaCaratula(id: string) {
        await userClient.post('/CaratulasSintia/BajarOrdenTareaCaratula', { Id: id },
            { cancelToken: getCancelToken() });
    }
    async function deletePlantillaCaratula(nombre: string) {
        await userClient.post('/CaratulasSintia/DeletePlantillaCaratula', {
            EmpresaId: userInfo.empresaActual,
            Nombre: nombre
        }, { cancelToken: getCancelToken() });
    }
    async function insertPlantillaCaratula(plantilla: any) {
        await userClient.post('/CaratulasSintia/InsertPlantillaCaratula',
            {
                ...plantilla,
                EmpresaId: userInfo.empresaActual,
                NroClienteAlpha: userInfo.nroClienteAlpha
            }, { cancelToken: getCancelToken() });
    }
    async function insertVariasCaratulas(caratulas: any[], subitems: any[], cancelaciones: any[]) {
        await userClient.post('/CaratulasSintia/InsertVariasCaratulas', {
            Caratulas: caratulas,
            Subitems: subitems,
            Cancelaciones: cancelaciones
        }, { cancelToken: getCancelToken() });
    }
    async function parsearArchivoSintia3(archivo: any) {
        let formData = new FormData();
        formData.append('archivo', archivo);
        let respuesta = await userClient.post('/CaratulasSintia/ParsearArchivoSintia3', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function busquedaCaratulasEliminadasPaginado(carpeta: string, fechaCreacionDesde: any,
        fechaCreacionHasta: any, fechaEliminacionDesde: any, fechaEliminacionHasta: any,
        desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/CaratulasSintia/BusquedaCaratulasEliminadasPaginado'
            + createQueryString({
                Desde: desde, Hasta: hasta, EmpresaId: userInfo.empresaActual,
                Carpeta: carpeta,
                FechaCreacionDesde: fechaCreacionDesde, FechaCreacionHasta: fechaCreacionHasta,
                FechaEliminacionDesde: fechaEliminacionDesde, FechaEliminacionHasta: fechaEliminacionHasta
            }), { cancelToken: cancelToken });
        return respuesta.data;
    }
    async function recuperarCaratulaEliminada(id: string) {
        await userClient.post('/CaratulasSintia/RecuperarCaratulaEliminada', {
            CaratulaEliminadaId: id
        }, { cancelToken: getCancelToken() });
    }
    async function getAvisos(id?: string) {
        let respuesta = await userClient.get('/Aviso/SelectAvisos' + createQueryString({
            EmpresaId: userInfo.empresaActual, Id: id
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteAviso(id: string) {
        try {
            await userClient.post('/Aviso/DeleteAviso', { Id: id },
                { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function insertAviso(nuevoAviso: any) {
        try {
            await userClient.post('/Aviso/InsertAviso', {
                ...nuevoAviso,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getTextosAviso() {
        let respuesta = await userClient.get('/Aviso/SelectTextosAviso' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getTiposAviso() {
        let respuesta = await userClient.get('/Aviso/SelectTiposAviso' + createQueryString({
            EmpresaId: userInfo.empresaActual
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertTextoAviso(nuevoTexto: any) {
        try {
            await userClient.post('/Aviso/InsertTextoAviso', {
                ...nuevoTexto,
                EmpresaId: userInfo.empresaActual,
                NroClienteAlpha: userInfo.nroClienteAlpha,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteTextoAviso(id: string) {
        try {
            await userClient.post('/Aviso/DeleteTextoAviso', { Id: id },
                { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function insertTipoAviso(nuevoTipo: any) {
        try {
            await userClient.post('/Aviso/InsertTipoAviso', {
                ...nuevoTipo,
                EmpresaId: userInfo.empresaActual,
                NroClienteAlpha: userInfo.nroClienteAlpha,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteTipoAviso(id: string) {
        try {
            await userClient.post('/Aviso/DeleteTipoAviso', { Id: id },
                { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function busquedaMapeosEstadoMercaderia() {
        let respuesta = await userClient.get('/CargaMasiva/BusquedaMapeosEstadoMercaderia',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertMapeos(modelo: {
        MapeosPais?: any[], MapeosUnidad?: any[], MapeosEstadoMercaderia?: any[]
    }) {
        modelo.MapeosPais = modelo.MapeosPais?.map((m: any) => ({ ...m, NroClienteAlpha: userInfo.nroClienteAlpha }));
        modelo.MapeosUnidad = modelo.MapeosUnidad?.map((m: any) => ({ ...m, NroClienteAlpha: userInfo.nroClienteAlpha }));
        modelo.MapeosEstadoMercaderia = modelo.MapeosEstadoMercaderia?.map((m: any) => ({ ...m, NroClienteAlpha: userInfo.nroClienteAlpha }));
        await userClient.post('/CargaMasiva/InsertMapeos', modelo, { cancelToken: getCancelToken() });
    }
    async function deleteMapeoEstadoMercaderia(valor: any) {
        await userClient.post('/CargaMasiva/DeleteMapeoEstadoMercaderia',
            { Valor: valor }, { cancelToken: getCancelToken() });
    }
    async function busquedaMapeosPais() {
        let respuesta = await userClient.get('/CargaMasiva/BusquedaMapeosPais',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteMapeoPais(valor: any) {
        await userClient.post('/CargaMasiva/DeleteMapeoPais',
            { Valor: valor }, { cancelToken: getCancelToken() });
    }
    async function busquedaMapeosUnidad() {
        let respuesta = await userClient.get('/CargaMasiva/BusquedaMapeosUnidad',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteMapeoUnidad(valor: any) {
        await userClient.post('/CargaMasiva/DeleteMapeoUnidad',
            { Valor: valor }, { cancelToken: getCancelToken() });
    }
    async function insertCatalogo(catalogo: any) {
        try {
            await userClient.post('/Catalogo/InsertCatalogo', {
                ...catalogo,
                NroClienteAlpha: userInfo.claims.NroClienteAlpha,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function insertArticulosCargaMasiva(modelo: any) {
        let respuesta = await userClient.post('/CargaMasiva/InsertArticulosACatalogo', modelo,
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function articulosExisten(items: Array<{ CodigoCatalogo: string, CodigoArticulo: string }>) {
        let resultado = await userClient.post('/Catalogo/ArticulosExisten', {
            Items: items
        }, { cancelToken: getCancelToken() });
        return resultado.data;
    }
    async function getCatalogosPaginado(busquedaActual: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/Catalogo/GetCatalogosPaginado'
            + createQueryString({ Busqueda: busquedaActual, Desde: desde, Hasta: hasta }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function deleteCatalogoVacio(codigo: string) {
        try {
            let respuesta = await userClient.post('/Catalogo/DeleteCatalogoVacio', {
                CodigoCatalogo: codigo,
            }, { cancelToken: getCancelToken() });
            if (respuesta.data.exito) {
                return { exito: true };
            } else {
                return { exito: false, error: 'No se pudo eliminar el catalogo porque tiene articulos' };
            }
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    async function deleteArticulo(codigoCatalogo: string, codigoArticulo: string) {
        try {
            await userClient.post('/Catalogo/DeleteArticulo', {
                CodigoCatalogo: codigoCatalogo,
                CodigoArticulo: codigoArticulo,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    async function deleteVariosArticulos(codigoCatalogo: string, codigosArticulo: string[]) {
        try {
            await userClient.post('/Catalogo/DeleteVariosArticulos', {
                CodigoCatalogo: codigoCatalogo,
                CodigosArticulo: codigosArticulo,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    async function exportarBusquedaArticulosAExcel(codigosCatalogos: string[], codigoArticulo: string,
        descripcion: any, ncm: any, buscarPorFecha: boolean, fechaDesde: any, fechaHasta: any,
        ignorarLimite: boolean) {
        let respuesta = await userClient.post('/Catalogo/ExcelBusquedaArticulos', {
            CodigosCatalogos: codigosCatalogos, CodigoArticulo: codigoArticulo,
            Descripcion: descripcion, Ncm: ncm,
            ModificadoDesde: buscarPorFecha ? fechaDesde : null,
            ModificadoHasta: buscarPorFecha ? fechaHasta : null,
            IgnorarLimite: ignorarLimite
        }, {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte Articulos Catalogo.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function establecerNcmArticulos(codigoCatalogo: string, ncmViejo: string, ncmNuevo: string) {
        try {
            await userClient.post('/Catalogo/EstablecerNcmArticulos', {
                CodigoCatalogo: codigoCatalogo,
                Ncm: ncmViejo,
                NcmNuevo: ncmNuevo,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    async function getIntervenciones() {
        let respuesta = await userClient.get('/Catalogo/GetIntervenciones', { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function copiarArticulo(codigoCatalogo: string, articuloACopiar: string, catalogoCopia: string,
        codigoArticuloCopia: string) {
        try {
            await userClient.post('/Catalogo/CopiarArticulo', {
                EmpresaId: userInfo.empresaActual,
                CodigoArticulo: articuloACopiar,
                CodigoArticuloCopia: codigoArticuloCopia,
                CodigoCatalogo: codigoCatalogo,
                CodigoCatalogoCopia: catalogoCopia
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function moverArticulos(codigoCatalogo: string, articulosAMover: string[],
        nuevoCatalogo: string) {
        try {
            await userClient.post('/Catalogo/MoverArticulosACatalogo', {
                EmpresaId: userInfo.empresaActual,
                CodigoCatalogoOrigen: codigoCatalogo,
                CodigosArticulos: articulosAMover,
                CodigoCatalogoDestino: nuevoCatalogo
            }, { cancelToken: getCancelToken() })
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function agregarIntervencionArticulos(codigoCatalogo: string, intervencionId: string,
        ncm?: string, codigoPaisOrigen?: string) {
        try {
            await userClient.post('/Catalogo/AgregarIntervencionArticulos', {
                EmpresaId: userInfo.empresaActual,
                CodigoCatalogo: codigoCatalogo,
                IntervencionId: intervencionId,
                Ncm: ncm,
                CodigoPaisOrigen: codigoPaisOrigen
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function eliminarIntervencionArticulos(codigoCatalogo: string, intervencionId: string,
        ncm?: string, codigoPaisOrigen?: string) {
        try {
            await userClient.post('/Catalogo/EliminarIntervencionArticulos', {
                EmpresaId: userInfo.empresaActual,
                CodigoCatalogo: codigoCatalogo,
                IntervencionId: intervencionId,
                Ncm: ncm,
                CodigoPaisOrigen: codigoPaisOrigen
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function insertArticulo(nuevoArticulo: any) {
        try {
            await userClient.post('/Catalogo/InsertArticulo', nuevoArticulo, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getEstadosArticulo() {
        let respuesta = await userClient.get('/Catalogo/GetEstadosArticulo', { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaBitacoraArticulosCatalogoPaginado(catalogo: string, codigoArticulo: string,
        fechaDesde: any, fechaHasta: any, usuario: any, desde: number, hasta: number, cancelToken: CancelToken) {
        if (fechaHasta) {
            fechaHasta = DateTime.fromISO(fechaHasta).plus({ days: 1 }).minus({ seconds: 1 }).toISO();
        }
        let respuesta = await userClient.get('/Catalogo/BusquedaBitacoraArticuloCatalogoPaginado' + createQueryString({
            CodigoCatalogo: catalogo, CodigoArticulo: codigoArticulo,
            FechaDesde: fechaDesde, fechaHasta: fechaHasta,
            Desde: desde, Hasta: hasta, NombreUsuario: usuario
        }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function getBitacora(id: string) {
        try {
            let respuesta = await userClient.get('/Catalogo/GetBitacora'
                + createQueryString({ Id: id }), {
                cancelToken: getCancelToken()
            });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getEstadosArticuloPaginado(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/Catalogo/GetEstadosArticuloPaginado' + createQueryString({
            Desde: desde, Hasta: hasta
        }), { cancelToken: cancelToken });
        return respuesta.data;
    }
    async function deleteEstadoArticulo(nombre: string) {
        await userClient.post('/Catalogo/DeleteEstadoArticulo', { Nombre: nombre },
            { cancelToken: getCancelToken() });
    }
    async function insertEstadoArticulo(estado: any) {
        await userClient.post('/Catalogo/InsertEstadoArticulo', {
            ...estado,
            NroClienteAlpha: userInfo.nroClienteAlpha
        }, { cancelToken: getCancelToken() });
    }
    async function insertIntervencion(nuevaIntervencion: any) {
        try {
            await userClient.post('/Catalogo/InsertIntervencion', {
                ...nuevaIntervencion,
                NroClienteAlpha: userInfo.nroClienteAlpha,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteIntervencion(id: string) {
        try {
            await userClient.post('/Catalogo/InsertIntervencion', {
                Id: id,
                NroClienteAlpha: userInfo.nroClienteAlpha,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getReglasValidacion() {
        let respuesta = await userClient.get('/ValidacionMaria/GetReglasValidacion',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteReglaValidacion(id: string) {
        try {
            await userClient.post('/ValidacionMaria/DeleteReglaValidacion', {
                ReglaId: id,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    async function getReglaValidacion(id: string) {
        try {
            let respuesta = await userClient.get('/ValidacionMaria/GetReglaValidacion'
                + createQueryString({ ReglaId: id }), {
                cancelToken: getCancelToken()
            });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function insertReglaValidacion(nuevaRegla: any) {
        try {
            await userClient.post('/ValidacionMaria/InsertReglaValidacion', {
                ...nuevaRegla,
                NroClienteAlpha: userInfo.nroClienteAlpha
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: errorCatalogosNoAutorizado };
            } else {
                throw error;
            }
        }
    }
    type ParametrosBusquedaReporteArticulos = {
        codigoCatalogo?: string,
        codigoArticulo?: string,
        ncm?: string,
        soloConLna?: boolean,
        buscarPorFecha?: boolean,
        fechaDesde?: any,
        fechaHasta?: any
    }
    async function busquedaReporteArticulos(busqueda: ParametrosBusquedaReporteArticulos) {
        let fechaDesde = null;
        let fechaHasta = null;
        if (busqueda.buscarPorFecha && busqueda.fechaDesde && busqueda.fechaHasta) {
            fechaDesde = busqueda.fechaDesde;
            fechaHasta = DateTime.fromISO(busqueda.fechaHasta).plus({ days: 1 }).minus({ seconds: 1 }).toISO();
        }
        let respuesta = await userClient.get('/Catalogo/BusquedaReporteArticulos' + createQueryString({
            CodigoCatalogo: busqueda.codigoCatalogo,
            CodigoArticulo: busqueda.codigoArticulo,
            Ncm: busqueda.ncm,
            SoloConLNA: busqueda.soloConLna,
            FechaModificadoDesde: fechaDesde,
            FechaModificadoHasta: fechaHasta
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function excelBusquedaReporteArticulos(busqueda: ParametrosBusquedaReporteArticulos) {
        let fechaDesde = null;
        let fechaHasta = null;
        if (busqueda.buscarPorFecha && busqueda.fechaDesde && busqueda.fechaHasta) {
            fechaDesde = busqueda.fechaDesde;
            fechaHasta = DateTime.fromISO(busqueda.fechaHasta).plus({ days: 1 }).minus({ seconds: 1 }).toISO();
        }
        let respuesta = await userClient.get('/Catalogo/ExcelBusquedaReporteArticulos' + createQueryString({
            CodigoCatalogo: busqueda.codigoCatalogo,
            CodigoArticulo: busqueda.codigoArticulo,
            Ncm: busqueda.ncm,
            SoloConLNA: busqueda.soloConLna,
            FechaModificadoDesde: fechaDesde,
            FechaModificadoHasta: fechaHasta
        }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte Articulos Detallado.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function getReporteLNA(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ReporteLNA' + createQueryString({ CodigoCatalogo: codigoCatalogo }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getReporteLNAConNovedades(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ReporteLNAConNovedades' + createQueryString({ CodigoCatalogo: codigoCatalogo }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function excelReporteLNA(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteLNA' +
            createQueryString({ CodigoCatalogo: codigoCatalogo }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte LNA ${codigoCatalogo}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function excelReporteLNAConNovedades(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteLNAConNovedades' +
            createQueryString({ CodigoCatalogo: codigoCatalogo }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte LNA con novedades ${codigoCatalogo}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function busquedaReporteArticulosNCMNoVigente(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/BusquedaReporteArticulosNcmNoVigente' + createQueryString({ CodigoCatalogo: codigoCatalogo }), {
            cancelToken: getCancelToken()
        });
        return respuesta.data;
    }
    async function excelReporteArticulosNCMNoVigente(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteArticulosNcmNoVigente' + createQueryString({ CodigoCatalogo: codigoCatalogo }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte NCM No Vigente ${codigoCatalogo}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function getReporteNCMPorCatalogo(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ReporteNcmPorCatalogo' + createQueryString({
            CodigoCatalogo: codigoCatalogo,
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function excelReporteNCMPorCatalogo(codigoCatalogo: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteNcmPorCatalogo' + createQueryString({
            CodigoCatalogo: codigoCatalogo
        }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte NCM.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function getReporteNCMPorInterno(interno: string) {
        let respuesta = await userClient.get('/Catalogo/ReporteNcmPorInterno' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function excelReporteNCMPorInterno(interno: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteNcmPorInterno' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno
        }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte NCM.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function getReporteNCMPorNCM(ncm: string) {
        let respuesta = await userClient.get('/Catalogo/ReporteNcmPorNcm' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Ncm: ncm
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function excelReporteNCMPorNCM(ncm: string) {
        let respuesta = await userClient.get('/Catalogo/ExcelReporteNcmPorNcm' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Ncm: ncm
        }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || 'Reporte NCM.xlsx';
        return { fileName, excel: respuesta.data };
    }
    async function insertDespachante(despachante: any) {
        try {
            await userClient.post('/DespachanteImportador/InsertDespachante', {
                Despachante: {
                    ...despachante,
                    NroClienteAlpha: userInfo.nroClienteAlpha,
                },
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los despachantes' };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteDespachante(cuit: string) {
        try {
            await userClient.post('/DespachanteImportador/DeleteDespachante', {
                CUIT: cuit,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los despachantes' };
            } else {
                throw error;
            }
        }
    }
    async function getDespachantesDisponiblesParaInsertar() {
        let respuesta = await userClient.get('/DespachanteImportador/GetDespachantesDisponiblesParaInsertar',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getTransportistasPaginado(busqueda: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/Transportista/SelectPaginado'
            + createQueryString({
                Busqueda: busqueda, Desde: desde, Hasta: hasta
            }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function deleteTransportista(cuit: string) {
        try {
            await userClient.post('/Transportista/Delete', {
                CUIT: cuit,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los transportistas' };
            } else {
                throw error;
            }
        }
    }
    async function getVendedoresPaginado(busqueda: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/Vendedor/SelectPaginado'
            + createQueryString({
                EmpresaId: userInfo.empresaActual,
                Busqueda: busqueda, Desde: desde, Hasta: hasta
            }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function deleteVendedor(codigo: string) {
        try {
            await userClient.post('/Vendedor/Delete', {
                Codigo: codigo,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los vendedores' };
            } else {
                throw error;
            }
        }
    }
    async function insertVendedor(nuevoVendedor: any) {
        try {
            await userClient.post('/Vendedor/Insert', {
                ...nuevoVendedor,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los vendedores' };
            } else {
                throw error;
            }
        }
    }
    async function getImportadoresPaginado(busqueda: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.get('/DespachanteImportador/GetImportadoresPaginado'
            + createQueryString({ Busqueda: busqueda, Desde: desde, Hasta: hasta }), {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function deleteImportador(id: string) {
        try {
            await userClient.post('/DespachanteImportador/DeleteImportador', {
                ImportadorId: id,
                EmpresaId: userInfo.empresaActual
            }, { cancelToken: getCancelToken() })
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los clientes' };
            } else {
                throw error;
            }
        }
    }
    async function insertImportador(nuevoImportador: any, ventajas?: any[], plantillas?: any[]) {
        try {
            await userClient.post('/DespachanteImportador/InsertImportadorConVentajas', {
                Importador: {
                    ...nuevoImportador, NroClienteAlpha: userInfo.nroClienteAlpha,
                },
                Ventajas: ventajas,
                Plantillas: plantillas,
                EmpresaId: userInfo.empresaActual
            });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 403) {
                return { exito: false, error: 'No está autorizado a modificar los clientes' };
            } else if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getImportadorPorId(id: string) {
        try {
            let respuesta = await userClient.get('/DespachanteImportador/GetImportadorPorId' +
                createQueryString({ Id: id }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getVentajasImportador(cuit: string) {
        let respuesta = await userClient.get('/DespachanteImportador/GetTodasLasVentajasImportador' +
            createQueryString({ Cuit: cuit }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaCaratulasProcesoExcelPaginado(busqueda: any, desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await userClient.post('/ProcesoExcel/BusquedaCaratulasPaginado',
            { Carpeta: busqueda, Desde: desde, Hasta: hasta, EmpresaId: userInfo.empresaActual }, {
            cancelToken: cancelToken
        });
        return respuesta.data;
    }
    async function getCaratulaProcesoExcel(interno: string) {
        try {
            let respuesta = await userClient.get('/ProcesoExcel/SelectCaratula' + createQueryString({
                EmpresaId: userInfo.empresaActual, Carpeta: interno
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function transferirCaratulaProcesoExcelASintia(interno: string, internoSintia: string,
        subregimen: string, sobreescribir: boolean, tipoOperacion?: string) {
        await userClient.post('/ProcesoExcel/GenerarCarpetaSintia', {
            EmpresaId: userInfo.empresaActual,
            CarpetaExcel: interno,
            CarpetaSintia: internoSintia,
            Subregimen: subregimen,
            Sobreescribir: sobreescribir,
            TipoOperacion: tipoOperacion
        }, { cancelToken: getCancelToken() });
    }
    async function transferirCaratulaProcesoExcelASintiaAutonumerico(interno: string,
        subregimen: string, tipoOperacion?: string) {
        try {
            let respuesta = await userClient.post('/ProcesoExcel/GenerarCarpetaSintiaAutonumerico', {
                EmpresaId: userInfo.empresaActual,
                CarpetaExcel: interno,
                Subregimen: subregimen,
                TipoOperacion: tipoOperacion
            }, { cancelToken: getCancelToken() });
            return { exito: true, internoSintia: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function reporteArticulosProcesoExcel(interno: string, articulosAMostrar: any) {
        let respuesta = await userClient.get('/ProcesoExcel/ReporteArticulos' + createQueryString({
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno,
            Filtro: articulosAMostrar
        }), {
            cancelToken: getCancelToken(),
            responseType: 'blob',
        });
        let parsedContentDisposition = contentDisposition.parse(respuesta.headers['content-disposition']);
        let fileName = parsedContentDisposition.parameters.filename || `Reporte articulos proceso Excel ${interno}.xlsx`;
        return { fileName, excel: respuesta.data };
    }
    async function establecerNcmArticulosProcesoExcel(interno: string, nrosLinea: any[], ncm: any) {
        await userClient.post('/ProcesoExcel/EstablecerNCMArticulos', {
            EmpresaId: userInfo.empresaActual,
            Carpeta: interno,
            NrosLinea: nrosLinea,
            NCM: ncm
        }, { cancelToken: getCancelToken() });
    }
    async function establecerSufijosAutomaticoProcesoExcel(interno: string, nrosLinea: any[], sufijosNuevos: any) {
        try {
            await userClient.post('/ProcesoExcel/EstablecerSufijosArticulosAutomatico', {
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                NrosLinea: nrosLinea,
                SufijosNuevos: sufijosNuevos
            }, { cancelToken: getCancelToken() });
            return { exito: true }
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function establecerSufijosManualProcesoExcel(interno: string, nrosLinea: any[], dictSufijos: any) {
        try {
            await userClient.post('/ProcesoExcel/EstablecerSufijosArticulos', {
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                NrosLinea: nrosLinea,
                Sufijos: dictSufijos
            }, { cancelToken: getCancelToken() });
            return { exito: true }
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function insertConfiguracionSintia(configuracion: any, configuracionAdmin: any) {
        await userClient.post('/Configuracion/InsertConfiguracionSintia', {
            Configuracion: {
                ...configuracion,
                NroClienteAlpha: userInfo.nroClienteAlpha
            },
            ConfiguracionAdmin: {
                ...configuracionAdmin,
                NroClienteAlpha: userInfo.nroClienteAlpha
            }
        }, { cancelToken: getCancelToken() });
    }
    async function insertCaratulaProcesoExcel(caratula: any, articulos: any[], mapeosPais: any[],
        mapeosUnidad: any[], mapeosEstadoMerc: any) {
        await userClient.post('/ProcesoExcel/InsertCaratula', {
            Caratula: caratula,
            Articulos: articulos,
            MapeosPais: mapeosPais.map((mapeo: any) => ({
                ...mapeo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            })),
            MapeosUnidad: mapeosUnidad.map((mapeo: any) => ({
                ...mapeo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            })),
            MapeosEstadoMercaderia: mapeosEstadoMerc.map((mapeo: any) => ({
                ...mapeo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                CreadoPor: userInfo.claims.nameid,
                CreadoEl: DateTime.local().toISO(),
                ModificadoPor: userInfo.claims.nameid,
                ModificadoEl: DateTime.local().toISO()
            }))
        }, { cancelToken: getCancelToken() });
    }
    async function getArticuloProcesoExcel(interno: string, nroLinea: any) {
        try {
            let respuesta = await userClient.get('/ProcesoExcel/SelectArticulo' +
                createQueryString({ EmpresaId: userInfo.empresaActual, Carpeta: interno, LineaNro: nroLinea }), {
                cancelToken: getCancelToken()
            });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function updateArticuloProcesoExcel(articulo: any) {
        try {
            await userClient.post('/ProcesoExcel/UpdateArticulo', {
                ...articulo,
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
            }, { cancelToken: getCancelToken() });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getModelosProcesoExcel() {
        let respuesta = await userClient.get('/ProcesoExcel/SelectModelosProcesoExcelDisponibles'
            + createQueryString({ EmpresaId: userInfo.empresaActual }),
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function deleteModeloProcesoExcel(nombre: string) {
        await userClient.post('/ProcesoExcel/DeleteModeloProcesoExcel', {
            EmpresaId: userInfo.empresaActual,
            Nombre: nombre
        }, { cancelToken: getCancelToken() });
    }

    async function deleteCaratulaProcesoExcel(caratula: string) {
        await userClient.post('/ProcesoExcel/DeleteCaratula', {
            EmpresaId: userInfo.empresaActual,
            Carpeta: caratula
        }, { cancelToken: getCancelToken() });
    }


    async function insertModeloProcesoExcel(modelo: any) {
        await userClient.post('/ProcesoExcel/InsertModeloProcesoExcel', {
            ...modelo,
            NroClienteAlpha: userInfo.nroClienteAlpha,
            EmpresaId: userInfo.empresaActual,
            CreadoPor: userInfo.claims.nameid,
            CreadoEl: DateTime.local().toISO(),
        }, { cancelToken: getCancelToken() });
    }
    async function getModeloProcesoExcel(nombreModelo: string) {
        try {
            let respuesta = await userClient.get('/ProcesoExcel/SelectModeloProcesoExcel'
                + createQueryString({ EmpresaId: userInfo.empresaActual, Nombre: nombreModelo }),
                { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getSufijos(ncm: string) {
        let respuesta = await userClient.get('/Ncm/Sufijos' +
            createQueryString({ PosicionArancelaria: ncm }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertConfiguracionEmpresa(configuracionEmpresa: any): Promise<ModeloConfiguracion> {
        let respuesta = await userClient.post('/Configuracion/UpdateConfiguracionEmpresa', {
            ...configuracionEmpresa,
            EmpresaId: userInfo.empresaActual,
        }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function setEnviarComisionesAExteriorEnCero(valor: boolean) {
        await userClient.post('/Configuracion/SetEnviarComisionesAExteriorEnCero', {
            EmpresaId: userInfo.empresaActual,
            Valor: valor
        }, { cancelToken: getCancelToken() });
    }
    async function getLogoEmpresa() {
        try {
            let respuesta = await userClient.get('/Configuracion/DownloadLogoEmpresa' + createQueryString({
                EmpresaId: userInfo.empresaActual
            }), { cancelToken: getCancelToken(), responseType: 'blob' });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function uploadLogoEmpresa(logoBase64: string | null) {
        try {
            await userClient.post('/Configuracion/UploadLogoEmpresa', {
                EmpresaId: userInfo.empresaActual,
                Logo: logoBase64
            });
            return { exito: true };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function getContadorAutonumericoActual(tipo: string | null | undefined) {
        try {
            let respuesta = await userClient.get('/Configuracion/GetContadorAutonumericoActual' + createQueryString({
                EmpresaId: userInfo.empresaActual, Tipo: tipo
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function resetearContadorAutonumerico(tipo: string | null | undefined, valor: number | null) {
        await userClient.post('/Configuracion/ResetearNumeracionAutonumerico', {
            EmpresaId: userInfo.empresaActual,
            Tipo: tipo,
            Valor: valor === null ? 0 : (valor - 1)
        }, { cancelToken: getCancelToken() });
    }
    async function insertTipoOperacion(tipo: any, cancelToken?: CancelToken) {
        await userClient.post('/CaratulasSintia/InsertTipoOperacion', {
            ...tipo,
            EmpresaId: userInfo.empresaActual
        }, { cancelToken: cancelToken ?? getCancelToken() });
    }
    async function deleteTipoOperacion(codigo: string) {
        await userClient.post('/CaratulasSintia/DeleteTipoOperacion', {
            EmpresaId: userInfo.empresaActual,
            Codigo: codigo
        }, { cancelToken: getCancelToken() });
    }
    async function deleteTipoReferencia(id: string) {
        await userClient.post('/CaratulasSintia/DeleteTipoReferencia', {
            NroClienteAlpha: userInfo.nroClienteAlpha,
            Id: id
        }, { cancelToken: getCancelToken() });
    }
    async function insertTipoReferencia(nuevaReferencia: any) {
        let respuesta = await userClient.post('/CaratulasSintia/InsertTipoReferencia',
            {
                ...nuevaReferencia,
                NroClienteAlpha: userInfo.nroClienteAlpha,
            }, { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function busquedaInformeSimiPaginado(desde: number, hasta: number, cancelToken: CancelToken,
        buscarPorFecha: boolean, fechaDesde: any, fechaHasta: any, cuitCliente?: string, codigoVendedor?: string,
        nroCarpeta?: string, vuestraReferencia?: string, estadoSimi?: string) {
        let respuesta = await userClient.get('/Informe/BusquedaSIMIPaginado' + createQueryString({
            Desde: desde, Hasta: hasta, FechaDesde: buscarPorFecha ? fechaDesde : null,
            FechaHasta: buscarPorFecha ? fechaHasta : null, CuitCliente: cuitCliente,
            NroCarpeta: nroCarpeta,
            CodigoVendedor: codigoVendedor, VuestraReferencia: vuestraReferencia, EstadoSimi: estadoSimi
        }), { cancelToken: cancelToken ?? getCancelToken() });
        return respuesta.data;
    }
    async function busquedaInformeDefinitivasPaginado(desde: number, hasta: number, cancelToken: CancelToken,
        buscarPorFecha: boolean, fechaDesde: any, fechaHasta: any, cuitCliente?: string, codigoVendedor?: string,
        nroCarpeta?: string, vuestraReferencia?: string, estado?: string, tipoDestinacion?: string) {
        let respuesta = await userClient.get('/Informe/BusquedaDefinitivasPaginado' + createQueryString({
            Desde: desde, Hasta: hasta, FechaDesde: buscarPorFecha ? fechaDesde : null,
            FechaHasta: buscarPorFecha ? fechaHasta : null, CuitCliente: cuitCliente,
            NroCarpeta: nroCarpeta, TipoDestinacion: tipoDestinacion,
            CodigoVendedor: codigoVendedor, VuestraReferencia: vuestraReferencia, Estado: estado
        }), { cancelToken: cancelToken ?? getCancelToken() });
        return respuesta.data;
    }
    async function busquedaInformeIndicadoresPaginado(desde: number, hasta: number, cancelToken: CancelToken,
        buscarPorFechaApertura: boolean, fechaAperturaDesde: any, fechaAperturaHasta: any,
        buscarPorFechaArribo: boolean, fechaArriboDesde: any, fechaArriboHasta: any,
        buscarPorFechaOfic: boolean, fechaOficDesde: any, fechaOficHasta: any) {
        let respuesta = await userClient.get('/Informe/BusquedaIndicadoresPaginado' + createQueryString({
            Desde: desde, Hasta: hasta,
            FechaAperturaDesde: buscarPorFechaApertura ? fechaAperturaDesde : null,
            FechaAperturaHasta: buscarPorFechaApertura ? fechaAperturaHasta : null,
            FechaArriboDesde: buscarPorFechaArribo ? fechaArriboDesde : null,
            FechaArriboHasta: buscarPorFechaArribo ? fechaArriboHasta : null,
            FechaOficDesde: buscarPorFechaOfic ? fechaOficDesde : null,
            FechaOficHasta: buscarPorFechaOfic ? fechaOficHasta : null
        }), { cancelToken: cancelToken ?? getCancelToken() });
        return respuesta.data;
    }

    // async function busquedaInformeExpoPaginado(desde: number, hasta: number, cancelToken: CancelToken,
    //     buscarPorFecha: boolean, fechaDesde: any, fechaHasta: any, cuitCliente?: string,
    //     nroCarpeta?: string, vuestraReferencia?: string, estadoExpo?: string) {
    //     let respuesta = await userClient.get('/Informe/BusquedaExpoPaginado' + createQueryString({
    //         Desde: desde, Hasta: hasta, FechaDesde: buscarPorFecha ? fechaDesde : null,
    //         FechaHasta: buscarPorFecha ? fechaHasta : null, CuitCliente: cuitCliente,
    //         NroCarpeta: nroCarpeta,
    //         VuestraReferencia: vuestraReferencia, EstadoExpo: estadoExpo
    //     }), { cancelToken: cancelToken ?? getCancelToken() });
    //     return respuesta.data;
    // }

    async function getEstadisticas(año: number, mes: number) {
        try {
            let respuesta = await userClient.get('/Informe/GetEstadisticas' + createQueryString({
                Año: año, Mes: mes
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function getTiposCertificacion() {
        let respuesta = await userClient.get('/Certificacion/GetTiposCertificacion', { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function insertTipoCertificacion(tipo: any) {
        let respuesta = await userClient.post('/Certificacion/InsertTipoCertificacion', tipo,
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function deleteTipoCertificacion(id: string) {
        await userClient.post('/Certificacion/DeleteTipoCertificacion', { Id: id }, { cancelToken: getCancelToken() });
    }
    async function busquedaCertificaciones(nroArticulo?: string) {
        let respuesta = await userClient.get('/Certificacion/BusquedaCertificaciones' + createQueryString({
            NroArticulo: nroArticulo
        }), { cancelToken: getCancelToken() });
        return respuesta.data;
    }
    async function getCertificacion(id: string) {
        try {
            let respuesta = await userClient.get('/Certificacion/GetCertificacion' + createQueryString({
                Id: id
            }), { cancelToken: getCancelToken() });
            return respuesta.data;
        } catch (error) {
            if (error.response?.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    }
    async function insertCertificacion(certificacion: any) {
        try {
            let respuesta = await userClient.post('/Certificacion/InsertCertificacion', certificacion,
                { cancelToken: getCancelToken() });
            return { exito: true, certificacion: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteCertificacion(id: string) {
        await userClient.post('/Certificacion/DeleteCertificacion', { Id: id }, { cancelToken: getCancelToken() });
    }

    async function getGoogleDriveAuthStoreValue(key: string) {
        let respuesta = await userClient.get('/Account/GetGoogleDriveAuthStoreValue',
            { params: { Key: key }, cancelToken: getCancelToken() });
        if (isNullOrWhiteSpace(respuesta.data)) {
            return null;
        }
        let array = base64js.toByteArray(respuesta.data);
        let decoder = new TextDecoder();
        return JSON.parse(decoder.decode(array));
    }

    async function setGoogleDriveAuthStoreValue(key: string, value: any) {
        let base64 = null;
        if (value) {
            let encoder = new TextEncoder();
            base64 = base64js.fromByteArray(encoder.encode(JSON.stringify(value)));
        }
        await userClient.post('/Account/SetGoogleDriveAuthStoreValue', {
            Key: key,
            Value: base64
        }, { cancelToken: getCancelToken() });
    }
    const googleDriveAuthStore: GoogleDriveAuthStore = {
        getAccessToken: async () => {
            let value = await getGoogleDriveAuthStoreValue('<javascript_tokens>-');
            return value?.accessToken;
        },
        setAccessToken: async (accessToken) => {
            if (accessToken) {
                await setGoogleDriveAuthStoreValue('<javascript_tokens>-', {
                    accessToken: accessToken
                });
            } else {
                await setGoogleDriveAuthStoreValue('<javascript_tokens>-', null);
            }
        },
        getLinkedUser: async () => {
            let { ConfiguracionAdmin } = await getConfiguracionSintia();
            return ConfiguracionAdmin?.UsuarioGoogleDrive;
        }, setLinkedUser: updateUsuarioGoogleDrive
    }

    async function getTiposDocumentoArchivoAdjunto() {
        let respuesta = await userClient.get('/CaratulasSintia/SelectTiposDocumentoArchivoAdjuntoCaratula',
            { params: { ConPredeterminados: true }, cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function insertTipoDocumentoArchivoAdjunto(tipo: any) {
        let respuesta = await userClient.post('/CaratulasSintia/InsertTipoDocumentoArchivoAdjuntoCaratula', tipo,
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function deleteTipoDocumentoArchivoAdjunto(id: string) {
        await userClient.post('/CaratulasSintia/DeleteTipoDocumentoArchivoAdjuntoCaratula', { Id: id },
            { cancelToken: getCancelToken() });
    }

    async function getArchivosAdjuntosCaratula(interno: string) {
        let respuesta = await userClient.get('/CaratulasSintia/GetArchivosAdjuntosCaratula',
            { params: { EmpresaId: userInfo.empresaActual, Carpeta: interno }, cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function insertArchivoAdjuntoCaratula(archivo: any) {
        try {
            archivo.NroClienteAlpha = userInfo.nroClienteAlpha;
            archivo.EmpresaId = userInfo.empresaActual;
            let respuesta = await userClient.post('/CaratulasSintia/InsertArchivoAdjuntoCaratula',
                archivo, { cancelToken: getCancelToken() });
            return { exito: true, archivo: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function updateArchivoAdjuntoCaratula(archivo: any) {
        try {
            archivo.NroClienteAlpha = userInfo.nroClienteAlpha;
            archivo.EmpresaId = userInfo.empresaActual;
            let respuesta = await userClient.post('/CaratulasSintia/UpdateArchivoAdjuntoCaratula',
                archivo, { cancelToken: getCancelToken() });
            return { exito: true, archivo: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteArchivoAdjuntoCaratula(interno: string, googleDriveId: string) {
        await userClient.post('/CaratulasSintia/DeleteArchivoAdjuntoCaratula',
            {
                NroClienteAlpha: userInfo.nroClienteAlpha,
                EmpresaId: userInfo.empresaActual,
                Carpeta: interno,
                GoogleDriveId: googleDriveId
            }, { cancelToken: getCancelToken() });
    }

    async function getArchivosAdjuntosArticuloCatalogo(catalogo: string, articulo: string) {
        let respuesta = await userClient.get('/Catalogo/GetArchivosAdjuntosArticuloCatalogo',
            { params: { Catalogo: catalogo, Articulo: articulo }, cancelToken: getCancelToken() });
        return respuesta.data;
    }

    async function insertArchivoAdjuntoArticuloCatalogo(archivo: any) {
        try {
            archivo.NroClienteAlpha = userInfo.nroClienteAlpha;
            let respuesta = await userClient.post('/Catalogo/InsertArchivoAdjuntoArticuloCatalogo',
                archivo, { cancelToken: getCancelToken() });
            return { exito: true, archivo: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function updateArchivoAdjuntoArticuloCatalogo(archivo: any) {
        try {
            archivo.NroClienteAlpha = userInfo.nroClienteAlpha;
            let respuesta = await userClient.post('/Catalogo/UpdateArchivoAdjuntoArticuloCatalogo',
                archivo, { cancelToken: getCancelToken() });
            return { exito: true, archivo: respuesta.data };
        } catch (error) {
            if (error.response?.status === 400 && typeof error.response.data === 'string') {
                return { exito: false, error: error.response.data };
            } else {
                throw error;
            }
        }
    }
    async function deleteArchivoAdjuntoArticuloCatalogo(catalogo: string, articulo: string, googleDriveId: string) {
        await userClient.post('/Catalogo/DeleteArchivoAdjuntoArticuloCatalogo',
            {
                NroClienteAlpha: userInfo.nroClienteAlpha,
                Catalogo: catalogo,
                Articulo: articulo,
                GoogleDriveId: googleDriveId
            }, { cancelToken: getCancelToken() });
    }

    async function updateUsuarioGoogleDrive(usuario: string | null | undefined) {
        await userClient.post("/Configuracion/UpdateUsuarioGoogleDrive", {
            UsuarioGoogleDrive: usuario
        }, { cancelToken: getCancelToken() });
    }
    async function updateCruzarConCatalogoEnProcesoExcel(valor: boolean) {
        await userClient.post('/Configuracion/UpdateCruzarConCatalogoEnProcesoExcel', { Valor: valor },
            { cancelToken: getCancelToken() });
    }
    async function generarTokenLoginLNA() {
        let respuesta = await userClient.post('/Account/GenerarTokenLoginLNA',
            { cancelToken: getCancelToken() });
        return respuesta.data;
    }


/*SESION AFIP*/
/////////////////////

    async function getSesionesAFIPHistorico() {
        try 
        {
            let respuesta = await userClient.get('/SesionAFIP/GetSesionesAFIPHistorico');
            return respuesta.data;
                /* devuelve array [
                    {
                        "CUIT": "20926154215",
                        "Nombre": "Mendel Berniger",
                        "UltimaConexion": "2023-10-03T16:47:38.581Z"
                    }, 
                */
        } catch (error) {
            console.log('GetSesionesAFIPHistorico '  + error);
        }

    }

    async function insertSesionesAFIPHistorico(cuit: string, nombre: string) {
        await userClient.post('/SesionAFIP/InsertSesionesAFIPHistorico',
            {
                CUIT: cuit,
                Nombre: nombre,
                UltimaConexion: DateTime.local().toISO()
            }, { cancelToken: getCancelToken() });
    }


    async function deleteSesionesAFIPHistorico(cuit: string) {
        await userClient.post('/SesionAFIP/DeleteSesionesAFIPHistorico',
            {
                CUIT: cuit
            }, { cancelToken: getCancelToken() });
    }

//////////////////////
/*SESION AFIP*/




    return {
        getCancelToken, cancelCurrentTokens, isUnmounted, isCancel,
        cargarNcm, validarNcmArreglandoPuntos, validarNcmCompleto,
        getPaises: getPaisesExt, getUnidades: getUnidadesExt, getEstadosMercaderia: getEstadosMercaderiaExt,
        getSubregimenes: getSubregimenesExt, getVentajas: getVentajasExt,
        getInformacionComplementaria: getInformacionComplementariaExt, getLista: getListaExt,
        getTiposEmbarque: getTiposEmbarqueExt, getTiposResponsable: getTiposResponsableExt,
        getAduanas: getAduanasExt, getMonedas: getMonedasExt, getCondicionesVenta: getCondicionesVentaExt,
        getMotivosSuspensiva: getMotivosSuspensivaExt, getBancosTerceros: getBancosTercerosExt,
        getCamposSubregimen: getCamposSubregimenExt, getConceptosLiquidacion: getConceptosLiquidacionExt,
        getVias: getViasExt, getCodigosEmbarque: getCodigosEmbarqueExt,
        getLiquidacion, getPuertosBusqueda, getCompaniasAereas,  getArmadoItems, getCotizacionesDivisas, getAvisosArmadoItems,
        obtenerLock, eliminarLock, insertLiquidacion, insertArmadoItems, exportarArmadoItemsAExcel,
        exportarAvisosArmadoItemsAExcel, exportarReporteLnaArmadoItemsAExcel,
        exportarReporteValorCriterioArmadoItemsExcel, getReporteSubitemsCaratulaConIntervenciones,
        exportarReporteSubitemsCaratulaConIntervencionesAExcel, getReporteParaCancelaciones,
        exportarReporteParaCancelacionesAExcel, getEstadoCaratula, getMensajesArmadoItems,
        getReporteGravamenesSubitemsCaratula, exportarReporteGravamenesSubitemsCaratulaAExcel,
        getReporteSubitemsDetallado, exportarReporteSubitemsDetalladoAExcel, getSubitemsCaratula,
        exportarReporteMineriaAExcel, getReporteSubitemsCaratulaConValorCriterio,
        exportarReporteSubitemsCaratulaConValorCriterioAExcel, getCatalogos,
        busquedaArticulosCompletoPaginado, getDespachantes, getImportadores, getEmpresasUsuario,
        busquedaCaratulaPaginado, getConfiguracionSintia, getConfiguracionEmpresa, getTiposOperacion,
        getCaratula, crearCaratula, crearCaratulaAutonumerico, copiarCaratula, unificarCaratulas,
        eliminarCaratula, exportarBusquedaCaratulaAExcel, getVendedores, getCancelacionesSubitemsCaratula,
        getImportador, getTipoCambioGuardado, guardarTipoCambio, updateCaratula, getTiposReferencia,
        getPlantillasCaratula, exportarCaratulaPdf, updateDatosCarpeta, cargarPlantillaEnCaratula,
        subregimenLlevaBultos, borrarArmadoItems, getCantidadSubitemsSinDescripcionMaria,
        getCantidadSubitemsSinDescripcion, caratulaTieneSubitemsConImpuestosInternos,
        solicitarArmadoItems, updateBloqueoCaratula, getTransportistas, insertTransportista,
        updateBultos, generarTxtSintiaAdvanced, generarTxtMaria, updateDatosIDA4,
        busquedaSubitemsCaratulaPaginado, deleteVariosSubitems, establecerKilosEnCeroSubitems,
        establecerPaisOrigenSubitems, establecerPaisProcedenciaSubitems,
        vaciarCodigoProrrateoSubitems, establecerFillerSubitems, establecerSufijoSubitems, establecerFillerDistintosSubitems,
        establecerFillerPorCodigoArticuloSubitems, establecerFillerPorNroFacturaSubitems,
        establecerNcmSubitems, getPdfReporteSubitemsCaratula, busquedaArticulos, getValorCriterio,
        getArticulo, getSubitem, getCatalogo, insertSubitem, getCaratulaPresupuesto, getPresupuesto,
        getDespachante, getPlantillaImportador, getPlantillasImportador, insertPresupuesto,
        getPdfPresupuesto, getTiposConceptoPresupuesto, deleteTipoConceptoPresupuesto,
        insertTipoConceptoPresupuesto, getTiposReferenciaPresupuesto,
        deleteTipoReferenciaPresupuesto, insertTipoReferenciaPresupuesto, getPresupuestosDeCaratula,
        deletePresupuesto, getTareas, deleteTarea, insertTarea, getTareasCaratula, insertTareaCaratula,
        deleteTareaCaratula, deletePlantillaCaratula, insertPlantillaCaratula, insertVariasCaratulas,
        parsearArchivoSintia3, busquedaCaratulasEliminadasPaginado, recuperarCaratulaEliminada,
        getAvisos, deleteAviso, getTextosAviso, getTiposAviso, insertAviso, insertTextoAviso,
        deleteTextoAviso, insertTipoAviso, deleteTipoAviso, busquedaMapeosEstadoMercaderia,
        insertMapeos, deleteMapeoEstadoMercaderia, busquedaMapeosPais, deleteMapeoPais,
        busquedaMapeosUnidad, deleteMapeoUnidad, insertCatalogo, insertArticulosCargaMasiva,
        articulosExisten, getCatalogosPaginado, deleteCatalogoVacio, deleteArticulo,
        deleteVariosArticulos, exportarBusquedaArticulosAExcel, establecerNcmArticulos,
        getIntervenciones, copiarArticulo, moverArticulos, agregarIntervencionArticulos,
        eliminarIntervencionArticulos, insertArticulo, getEstadosArticulo,
        busquedaBitacoraArticulosCatalogoPaginado, getBitacora, getEstadosArticuloPaginado,
        deleteEstadoArticulo, insertEstadoArticulo, insertIntervencion, deleteIntervencion,
        getReglasValidacion, deleteReglaValidacion, getReglaValidacion, insertReglaValidacion,
        busquedaReporteArticulos, excelBusquedaReporteArticulos, getReporteLNA, getReporteLNAConNovedades,
        excelReporteLNA, excelReporteLNAConNovedades, busquedaReporteArticulosNCMNoVigente,
        excelReporteArticulosNCMNoVigente, getReporteNCMPorCatalogo, excelReporteNCMPorCatalogo,
        getReporteNCMPorInterno, excelReporteNCMPorInterno, getReporteNCMPorNCM, excelReporteNCMPorNCM,
        getDespachantesPaginado, insertDespachante, deleteDespachante, getDespachantesDisponiblesParaInsertar,
        getTransportistasPaginado, deleteTransportista, getVendedoresPaginado, deleteVendedor,
        insertVendedor, getVendedor, getImportadoresPaginado, deleteImportador, insertImportador,
        getImportadorPorId, getVentajasImportador, busquedaCaratulasProcesoExcelPaginado,
        getCaratulaProcesoExcel, transferirCaratulaProcesoExcelASintia,
        transferirCaratulaProcesoExcelASintiaAutonumerico, reporteArticulosProcesoExcel,
        establecerNcmArticulosProcesoExcel, establecerSufijosAutomaticoProcesoExcel,
        establecerSufijosManualProcesoExcel, insertConfiguracionSintia, insertCaratulaProcesoExcel,
        getArticuloProcesoExcel, updateArticuloProcesoExcel, getModelosProcesoExcel,
        deleteModeloProcesoExcel,deleteCaratulaProcesoExcel , insertModeloProcesoExcel, getModeloProcesoExcel, getSufijos,
        insertConfiguracionEmpresa, getLogoEmpresa, uploadLogoEmpresa, getContadorAutonumericoActual,
        resetearContadorAutonumerico, insertTipoOperacion, deleteTipoOperacion, deleteTipoReferencia,
        insertTipoReferencia, subirOrdenTareaCaratula, bajarOrdenTareaCaratula,
        busquedaInformeSimiPaginado, busquedaInformeDefinitivasPaginado,
        busquedaInformeIndicadoresPaginado, getEstadisticas, getTiposCertificacion, insertTipoCertificacion,
        deleteTipoCertificacion, busquedaCertificaciones, getCertificacion,
        insertCertificacion, deleteCertificacion, getCertificacionesArmadoItems,
        exportarCertificacionesArmadoItemsAExcel, googleDriveAuthStore, getTiposDocumentoArchivoAdjunto,
        insertTipoDocumentoArchivoAdjunto, deleteTipoDocumentoArchivoAdjunto,
        getArchivosAdjuntosCaratula, insertArchivoAdjuntoCaratula, updateArchivoAdjuntoCaratula,
        deleteArchivoAdjuntoCaratula, getArchivosAdjuntosArticuloCatalogo, insertArchivoAdjuntoArticuloCatalogo,
        updateArchivoAdjuntoArticuloCatalogo, deleteArchivoAdjuntoArticuloCatalogo,
        setEnviarComisionesAExteriorEnCero, busquedaCaratulaExtendidaPaginado,
        exportarBusquedaCaratulaExtendidaAExcel, updateCruzarConCatalogoEnProcesoExcel,
        generarTokenLoginLNA, exportarResumenCaratulaPdf, getDepositos,
        getSesionesAFIPHistorico, insertSesionesAFIPHistorico, deleteSesionesAFIPHistorico
    }
}
