import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApi } from 'ApiHooks';
import { MySelect, OnValueChangeArgument, SelectOption } from 'FormikHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import { convertirDatosGenericosAOption } from 'Utilidades';
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Alert, Button, Col, Container, Form, Modal } from 'react-bootstrap';

interface infCompProps {
    CCPL: string,
    MCPL: string
    id: string
    ICPL: string
    NART: string
    ICPLDIF: string
    AutomatizadorMaria_DDTID: string
    setReloadForm: (args: boolean) => void
}

const ModalEditarInfComp = ({ CCPL, MCPL, id, ICPL, NART, ICPLDIF, AutomatizadorMaria_DDTID, setReloadForm }: infCompProps) => {

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [errorCarga, setErrorCarga] = useState('')
    const [MCPLValue, setMCPLValue] = useState('')
    const [CCPLValue, setCCPLValue] = useState<OnValueChangeArgument>()
    let apiSintia = useApi();

    const handleClose = () => {
        setErrorCarga('')
        setShow(false)
        setMCPLValue('')
        setCCPLValue('')
    }

    async function getOptionsInformacionComplementaria() {
        const filterInfoComp = [
            'SIMI-DESC-MERC01',
            'SIMI-DESC-MERC02',
            'SIMI-DESC-MERC03',
            'SIMI-DESC-MERC04',
            'SIMI-DESC-MERC05',
            'SIMI-DESC-MERC06',
            'SIMI-DESC-MERC07',
            'SIMI-DESC-MERC08',
            'SIMI-DESC-MERC09',
            'SIMI-DESC-MERC10',
            'SIMI-PLAZO-GIRO',
            'SIMI-FECHA-ARRIB',
            'FECHA INIC.ACTIV',
            'DOMICIL.ESTABLEC',
            'IDTRIB-PROVEEDOR'
        ];
        let infComps = await apiSintia.getInformacionComplementaria();
        infComps = infComps.filter((item: any) => !filterInfoComp.includes(item.Codigo));
        return convertirDatosGenericosAOption(infComps);

    }

    const handleShow = () => setShow(true);
    let api = useAdvAPI();

    const updateInfo = async () => {
        updateCargando(true)
        try {
            await api.updateCPL({
                "ID": id,
                "ICPL": ICPL,
                "NART": NART,
                "CCPL": CCPL,
                "ICPLDIF": ICPLDIF,
                "MCPL": MCPLValue,
                "AutomatizadorMaria_DDTID": AutomatizadorMaria_DDTID
            })
            updateCargando(false)
            setReloadForm(true)
            setShow(false)
        } catch (error) {
            updateCargando(false)
            setErrorCarga('Ocurrío un error al editar')
            console.log('error', error)
        }
    }
    useEffect(() => {
        setCCPLValue(CCPL)
        setMCPLValue(MCPL)
    // eslint-disable-next-line
    }, [show])


    return (
        <>
       <FontAwesomeIcon style={{ fontSize: 18, color: 'blue', cursor:'pointer', marginRight:15 }} title='Editar' onClick={handleShow} icon={faEdit} />
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <Container>
                                {errorCarga?.length > 0 && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                    {errorCarga}
                                </Alert>}
                                <Form.Row>
                                    <Form.Group as={Col} md="6" lg="6">
                                        <MySelect name="CCPL" label="Código" isDisabled onValueChange={(e) => setCCPLValue(e)} valueSelected={CCPLValue?.toString()}
                                            options={getOptionsInformacionComplementaria}
                                            getOptionLabel={(option: SelectOption) => option.value}></MySelect>
                                    </Form.Group>
                                    <Form.Group as={Col} md="5" lg="4" className='col-lg-3' >
                                        <Form.Label>Valor:</Form.Label>
                                        <Form.Control
                                            style={{ height: 37, marginTop: 0 }}
                                            type="text"
                                            name="MCPL"
                                            value={MCPLValue}
                                            onChange={(e) => setMCPLValue(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Container>
                        </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                        <Button variant="success" onClick={() => updateInfo()}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEditarInfComp