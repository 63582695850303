import React from "react";
import { AppContext } from "App";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { strCmp } from "../../Utilidades";
import { MyModal } from "../../MyModal";
import { Button, Modal } from "react-bootstrap";
import { CrearEditarPlantillaCaratula, CrearEditarPlantillaCaratulaRef } from "./CrearEditarPlantillaCaratula";
import BlockUi from "react-block-ui";
import { GrillaSync } from "Grilla";
import { useApi } from "ApiHooks";

export type PlantillasCaratulaRef = { mostrar: () => void };

enum EstadoModal {
    Cerrado,
    CargandoPlantillas,
    Cargando,
    Abierto
}
export const PlantillasCaratula = React.forwardRef((props: {
    onSelectPlantilla: (nombre: string) => void
}, ref: any) => {
    let api = useApi();
    let { mostrarError } = React.useContext(AppContext);
    let dialogoRef = React.useRef<DialogoConfirmarRef>(null);
    let crearEditarPlantillaRef = React.useRef<CrearEditarPlantillaCaratulaRef>(null);
    let [mensajeDialogo, updateMensajeDialogo] = React.useState('');
    let [estado, updateEstado] = React.useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { modal: EstadoModal.CargandoPlantillas, plantillas: [] };
        } else if (accion.tipo === 'setPlantillas') {
            return { modal: EstadoModal.Abierto, plantillas: accion.plantillas?.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre)) ?? [] };
        } else if (accion.tipo === 'cerrar') {
            return { modal: EstadoModal.Cerrado, plantillas: [] };
        } else if (accion.tipo === 'insertPlantilla') {
            let nuevasPlantillas = Array.from(estado.plantillas);
            let indice = nuevasPlantillas.findIndex((p: any) => p.Nombre === accion.valor.Nombre);
            if (indice > -1) {
                nuevasPlantillas.splice(indice, 1);
            }
            nuevasPlantillas.push(accion.valor);
            nuevasPlantillas.sort((a: any, b: any) => strCmp(a.Nombre, b.Nombre));
            return { ...estado, plantillas: nuevasPlantillas };
        } else if (accion.tipo === 'deletePlantilla') {
            let nuevasPlantillas = estado.plantillas.filter((p: any) => p.Nombre !== accion.valor);
            return { ...estado, modal: EstadoModal.Abierto, plantillas: nuevasPlantillas };
        } else if (accion.tipo === 'setCargando') {
            return { ...estado, modal: accion.valor ? EstadoModal.Cargando : EstadoModal.Abierto };
        }
        return estado;
    }, { modal: EstadoModal.Cerrado, plantillas: [] });
    React.useEffect(() => {
        if (estado.modal === EstadoModal.CargandoPlantillas) {
            async function cargarPlantillas() {
                try {
                    let respuesta = await api.getPlantillasCaratula();
                    updateEstado({ tipo: 'setPlantillas', plantillas: respuesta });
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar plantillas', error);
                        mostrarError('Error al cargar plantillas');
                        updateEstado({ tipo: 'cerrar' });
                    }
                }
            }
            cargarPlantillas();
        }
        //eslint-disable-next-line
    }, [estado.modal]);
    React.useImperativeHandle(ref, () => ({
        mostrar: () => updateEstado({ tipo: 'mostrar' })
    }));
    const cerrar = () => {
        api.cancelCurrentTokens();
        updateEstado({ tipo: 'cerrar' });
    }
    function eventoEliminar(plantilla: any) {
        updateMensajeDialogo(`¿Está seguro que desea eliminar la plantilla ${plantilla.Nombre}?`);
        dialogoRef.current!.mostrar().then(async () => {
            updateEstado({ tipo: 'setCargando', valor: true });
            try {
                await api.deletePlantillaCaratula(plantilla.Nombre);
                updateEstado({ tipo: 'deletePlantilla', valor: plantilla.Nombre });
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al eliminar plantilla', error);
                    mostrarError('Error al eliminar plantilla');
                    updateEstado({ tipo: 'setCargando', valor: false });
                }
            }
        }).catch(() => { });
    }
    return <>
        <MyModal show={estado.modal !== EstadoModal.Cerrado} onHide={cerrar}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Plantillass</h2>
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={estado.modal === EstadoModal.Cargando || estado.modal === EstadoModal.CargandoPlantillas}>
                        <GrillaSync datos={estado.plantillas} campos={[{ titulo: 'Nombre', propiedad: 'Nombre', clave: true }]}
                            eventoAgregar={() => {
                                crearEditarPlantillaRef.current!.mostrarCrear()
                                    .then((plantilla: any) => updateEstado({ tipo: 'insertPlantilla', valor: plantilla })).catch(() => { });
                            }}
                            eventoDetalle={(plantilla: any) => {
                                props.onSelectPlantilla(plantilla.Nombre);
                                updateEstado({ tipo: 'cerrar' });
                            }}
                            eventoModificar={(plantilla: any) => {
                                crearEditarPlantillaRef.current!.mostrarModificar(plantilla)
                                    .then((nuevaPlantilla: any) => updateEstado({ tipo: 'insertPlantilla', valor: nuevaPlantilla })).catch(() => { });
                            }} eventoEliminar={eventoEliminar}></GrillaSync>
                    </BlockUi>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={cerrar}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <CrearEditarPlantillaCaratula ref={crearEditarPlantillaRef}
            plantillasExistentes={estado.plantillas.map((p: any) => p.Nombre)}></CrearEditarPlantillaCaratula>
        <DialogoConfirmar ref={dialogoRef} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>;
});