import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Button, Form, Modal, Dropdown, Tabs, Tab } from "react-bootstrap";
import { useHistory } from "react-router";
import { AppContext } from "App";
import Grilla, { GrillaRef, TipoCampo, CampoGrilla } from "../../Grilla";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { BaseSelect } from "BaseSelect";
import BlockUi from "react-block-ui";
import { MyModal } from "../../MyModal";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps, Field } from "formik";
import { MyForm, MyFormControl, MySelect, SelectOption, MyFormCheck } from "../../FormikHooks";
import { isNullOrWhiteSpace, sanitizarNombreInternoParaArchivo, optionLabelConCodigo, strCmp } from "Utilidades";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { useApi, TipoLock } from "ApiHooks";
import { CancelToken } from "SintiaHooks";
import { useGoogleDrive, WrongUserGoogleDriveLoginError, GoogleDriveLoginError, GoogleDriveAuthError } from "GoogleDriveHooks";
import styled from "styled-components";
import ConfiguracionStorage from 'ConfiguracionStorage';
import { storageFactory } from "storage-factory";
import ModalProgresoTXT from "Paginas/Caratulas/ModalProgresoTXT";



const CustomSelect = styled(BaseSelect)`
    width:400px;
`;


export function Caratulas() {
    let refGrilla = useRef<GrillaRef>(null);
    let refGrillaExtendida = useRef<GrillaRef>(null);
    let refFormikCrearCaratula = useRef<FormikProps<any>>(null);
    let refFormikCopiarCaratula = useRef<FormikProps<any>>(null);
    let refFormikUnificarCaratula = useRef<FormikProps<any>>(null);
    let refDialogo = useRef<DialogoConfirmarRef>(null);
    let history = useHistory();
    let { mostrarError, userInfo } = useContext(AppContext);
    let campos = [{ titulo: 'Carpeta', propiedad: 'Carpeta', clave: true }, { titulo: "Despachante", propiedad: 'NombreDespachante' },
    { titulo: 'Cliente', propiedad: 'NombreImportador' }, { titulo: 'Vuestra Referencia', propiedad: 'VuestraReferencia' },
    { titulo: 'Vendedor', propiedad: 'Vendedor' }, { titulo: 'Subregimen', propiedad: 'CodigoSubregimen' },
    { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number },
    { titulo: 'Fecha Creación', propiedad: 'FechaCreacion', tipo: TipoCampo.Date },
    { titulo: 'Usuario Modificación', propiedad: 'UsuarioModificacion' },
    { titulo: 'Fecha Modificación', propiedad: 'FechaModificacion', tipo: TipoCampo.Date }];
    let [camposExtendida, updateCamposExtendida] = useState<CampoGrilla[]>([{ propiedad: 'Carpeta', clave: true }]);
    let api = useApi();
    let googleDrive = useGoogleDrive({ authStore: api.googleDriveAuthStore });
    let [cargando, updateCargando] = useState(true);
    let [tiposOperacion, updateTiposOperacion] = useState<any[]>([]);
    let [optionsDespachantes, updateOptionsDespachantes] = useState<SelectOption[]>([]);
    let [optionsImportadores, updateOptionsImportadores] = useState<SelectOption[]>([]);
    let [optionsEmpresas, updateOptionsEmpresas] = useState<SelectOption[]>([]);
    let [creandoCaratula, updateCreandoCaratula] = useState(false);
    let [estadoModalCrearCaratulaAutonumerico, updateEstadoModalCrearCaratulaAutonumerico] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false };
        }
        return estado;
    }, { abierto: false });
    let [carpetaACopiar, updateCarpetaACopiar] = useState('');
    let [carpetasAUnificar, updateCarpetasAUnificar] = useState<string[]>([]);
    let [tabActual, updateTabActual] = useState('normal');
    function busquedaReducer(estado: any, accion: any) {
        if (accion.tipo === 'carpeta') {
            return { ...estado, carpeta: accion.valor };
        } else if (accion.tipo === 'importador') {
            return { ...estado, importador: accion.valor };
        } else if (accion.tipo === 'despachante') {
            return { ...estado, despachante: accion.valor };
        } else if (accion.tipo === 'usarFechas') {
            return { ...estado, usarFechas: accion.valor };
        } else if (accion.tipo === 'fechaDesde') {
            return { ...estado, fechaDesde: accion.valor };
        } else if (accion.tipo === 'fechaHasta') {
            return { ...estado, fechaHasta: accion.valor };
        }
        return estado;
    }
    let busquedaInicial = {
        carpeta: '', importador: '', despachante: '', usarFechas: false, fechaDesde: '', fechaHasta: ''
    }
    const storage = storageFactory(() => localStorage);
    let [busquedaActual, updateBusquedaActual] = useState(busquedaInicial);
    let [busqueda, updateBusqueda] = useReducer(busquedaReducer, { ...busquedaInicial });
    let [mensajeDialogo, updateMensajeDialogo] = useState('');
    let [mensajeSpinner, updateMensajeSpinner] = useState('');
    const [showModalProgresoTXT, setShowModalProgresoTXT] = useState(false);


    useEffect(() => {
        if (userInfo) {
            storage.setItem('userName', userInfo?.nombreUsuario!)

        }
        async function cargar() {
            try {
                let despachantes = await api.getDespachantes();
                updateOptionsDespachantes(despachantes.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let importadores = await api.getImportadores();
                updateOptionsImportadores(importadores.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                let empresas = await api.getEmpresasUsuario();
                updateOptionsEmpresas(empresas.map((item: any) => ({ value: item.CUIT, label: item.Nombre })));
                await CargarConfiguracion();
                updateCargando(false);
            } catch (error) {
                if (!api.isCancel(error)) {
                    console.error('Error al cargar caratulas', error);
                    mostrarError('Error al cargar caratulas');
                }
            }
        }
        cargar();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (tabActual === 'extendida') {
            refGrillaExtendida.current?.ponerFocoEnTabla();
        } else {
            refGrilla.current?.ponerFocoEnTabla();
        }
    }, [tabActual]);
    function recargarGrillas() {
        refGrilla.current!.recargar();
        refGrillaExtendida.current!.recargar();
    }
    useEffect(() => {
        recargarGrillas();
    }, [busquedaActual]);
    async function cargarDatos(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaCaratulaPaginado(busquedaActual.despachante, busquedaActual.importador,
            busquedaActual.usarFechas, busquedaActual.fechaDesde, busquedaActual.fechaHasta,
            busquedaActual.carpeta, desde, hasta, cancelToken);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }
    async function cargarDatosExtendida(desde: number, hasta: number, cancelToken: CancelToken) {
        let respuesta = await api.busquedaCaratulaExtendidaPaginado(busquedaActual.despachante, busquedaActual.importador,
            busquedaActual.usarFechas, busquedaActual.fechaDesde, busquedaActual.fechaHasta,
            busquedaActual.carpeta, desde, hasta, cancelToken);
        let camposExtendida: CampoGrilla[] = [
            { titulo: 'Carpeta', propiedad: 'Carpeta', clave: true },
            { titulo: "Despachante", propiedad: 'NombreDespachante' },
            { titulo: 'Cliente', propiedad: 'NombreImportador' },
            { titulo: 'Referencia Cliente', propiedad: 'ReferenciaCliente' },
            { titulo: 'Vendedor', propiedad: 'Vendedor' }, { titulo: 'Subregimen', propiedad: 'CodigoSubregimen' },
            { titulo: 'FOB', propiedad: 'TotalFob', tipo: TipoCampo.Number },
            { titulo: 'Fecha Creación', propiedad: 'FechaCreacion', tipo: TipoCampo.Date },
            { titulo: 'Pedido Fondos', propiedad: 'TienePresupuesto', plantillaFormato: (valor: any) => valor ? 'Sí' : 'No' },
            { titulo: 'Nro. Anticipo', propiedad: 'NroAnticipo' },
            { titulo: 'Total Anticipo', propiedad: "TotalAnticipo", tipo: TipoCampo.Number }];
        let tareasDistinct = new Set(respuesta.Items.flatMap((i: any) => i.Tareas).map((t: any) => t.Nombre));
        for (const nombreTarea of Array.from(tareasDistinct).sort((a: any, b: any) => strCmp(a, b))) {
            camposExtendida.push({
                titulo: nombreTarea as string,
                plantillaFormato: (valor: unknown, item: any) => {
                    let tarea = item.Tareas?.find((t: any) => t.Nombre === nombreTarea);
                    if (tarea) {
                        let valorCelda = '';
                        if (!isNullOrWhiteSpace(tarea.Descripcion)) {
                            valorCelda = `${tarea.Descripcion} `;
                        }
                        valorCelda += `FINALIZADA ${tarea.Finalizada ? 'Sí' : 'No'}`;
                        return valorCelda;
                    }
                    return '';
                }
            });
        }
        updateCamposExtendida(camposExtendida);
        return { cantidadItems: respuesta.CantidadTotal, items: respuesta.Items };
    }


    //cargo configuracion en localstorage
    async function CargarConfiguracion() {

        try {
            let respuesta = await api.getConfiguracionSintia();
            ConfiguracionStorage.setConfigStorage('SeleccionFobTotalPrecioUnitario', respuesta.Configuracion?.SeleccionFobTotalPrecioUnitario)

        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al obtener configuración', error);
                mostrarError('Error al obtener configuración');
            }
            return;
        }


    }

    async function eventoBotonCrearCaratula() {
        let usarAutonumerico: boolean;
        let mascaraAutonumerico: string | null | undefined;
        try {
            updateCargando(true);
            let configuracion = await api.getConfiguracionSintia();
            if (configuracion.ConfiguracionAdmin.AutonumericoPorEmpresa) {
                let configuracionEmpresa = await api.getConfiguracionEmpresa();
                if (configuracionEmpresa) {
                    usarAutonumerico = configuracionEmpresa.ConfiguracionAdmin.UsarAutonumerico ?? false;
                    mascaraAutonumerico = configuracionEmpresa.ConfiguracionAdmin.MascaraAutonumerico;
                } else {
                    usarAutonumerico = false;
                    mascaraAutonumerico = undefined;
                }
            } else {
                usarAutonumerico = configuracion.ConfiguracionAdmin.UsarAutonumerico ?? false;
                mascaraAutonumerico = configuracion.ConfiguracionAdmin.MascaraAutonumerico;
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al obtener configuración', error);
                mostrarError('Error al obtener configuración');
                updateCargando(false);
            }
            return;
        }
        try {
            async function cargarTiposOperacion() {
                let respuesta = await api.getTiposOperacion();
                updateTiposOperacion(respuesta ?? []);
                return respuesta ?? [];
            }
            if (usarAutonumerico) {
                if (isNullOrWhiteSpace(mascaraAutonumerico)) {
                    mostrarError('Se ha habilitado crear caratulas con autonumerico pero no hay una máscara creada. Debe crear una máscara para crear carpetas con autonumerico');
                    updateCargando(false);
                    return;
                }
                if (mascaraAutonumerico!.includes('##tipo##')) {
                    let tipos = await cargarTiposOperacion();
                    if (tipos.length > 0) {
                        updateEstadoModalCrearCaratulaAutonumerico({ tipo: 'mostrar' });
                    } else {
                        mostrarError('La máscara tiene tipo pero no hay ningún tipo creado');
                    }
                    updateCargando(false);
                } else {
                    updateMensajeDialogo('¿Está seguro que desea crear una nueva carpeta?');
                    refDialogo.current!.mostrar().then(async () => {
                        await crearCaratulaAutonumerico('');
                    }).catch(() => updateCargando(false));
                }
            } else {
                await cargarTiposOperacion();
                updateCargando(false);
                updateCreandoCaratula(true);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al obtener tipos de operación', error);
                mostrarError('Error al obtener tipos de operación');
                updateCargando(false);
            }
        }
    }
    async function crearCaratula(values: { Tipo?: string, Valor: string }, actions: FormikHelpers<any>) {
        try {
            let respuesta = await api.getCaratula(values.Valor);
            if (respuesta) {
                actions.setFieldError('Valor', `La caratula ${values.Valor} ya existe`);
                return;
            }
            let puedeCrear = await api.obtenerLock(TipoLock.Caratula, values.Valor);
            if (!puedeCrear) {
                actions.setFieldError('Valor', `La caratula ${values.Valor} ya existe`);
                return;
            }
            let { exito, error } = await api.crearCaratula(values.Valor, values.Tipo);
            if (exito) {
                recargarGrillas();
                updateCreandoCaratula(false);
            } else {
                mostrarError(error);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear caratula', error);
                mostrarError('Error al crear caratula');
            }
        }
    }
    async function crearCaratulaAutonumerico(tipo: string) {
        try {
            let { exito, error } = await api.crearCaratulaAutonumerico(tipo);
            if (exito) {
                recargarGrillas();
                updateEstadoModalCrearCaratulaAutonumerico({ tipo: 'cerrar' });
            } else {
                mostrarError(error);
            }
            updateCargando(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al crear caratula', error);
                mostrarError('Error al crear caratula');
                updateCargando(false);
            }
        }
    }
    //debe ser función async para que formik bloquee el form mientras se esté procesando
    async function submitCrearCaratulaAutonumerico(values: { Tipo: string }, actions: FormikHelpers<any>) {
        await crearCaratulaAutonumerico(values.Tipo);
    }
    async function copiarCaratula(values: { EmpresaId: string, Valor: string, ConvertirNCMtoCODAFIP: boolean }, actions: FormikHelpers<any>) {
        try {
            let respuesta = await api.getCaratula(values.Valor, values.EmpresaId);
            if (respuesta) {
                actions.setFieldError('Valor', `La caratula ${values.Valor} ya existe en la empresa seleccionada`);
                return;
            }
            let { exito, error } = await api.copiarCaratula(carpetaACopiar, values.EmpresaId, values.Valor, values.ConvertirNCMtoCODAFIP);
            if (exito) {
                recargarGrillas();
                updateCarpetaACopiar('');
            } else {
                mostrarError(error);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al copiar caratula', error);
                mostrarError('Error al copiar caratula');
            }
        }
    }
    async function unificarCaratulas(values: { Valor: string }, actions: FormikHelpers<any>) {
        try {
            let respuesta = await api.getCaratula(values.Valor);
            if (respuesta) {
                actions.setFieldError('Valor', `La caratula ${values.Valor} ya existe`);
                return;
            }
            let puedeCrear = await api.obtenerLock(TipoLock.Caratula, values.Valor);
            if (!puedeCrear) {
                actions.setFieldError('Valor', `La caratula ${values.Valor} ya existe`);
                return;
            }
            let { exito, error } = await api.unificarCaratulas(carpetasAUnificar, values.Valor);
            if (exito) {
                recargarGrillas();
                updateCarpetasAUnificar([]);
            } else {
                mostrarError(error);
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al unificar caratulas', error);
                mostrarError('Error al unificar caratulas');
            }
        }
    }
    async function eliminarCaratula(carpeta: string) {
        refGrilla.current?.setBloqueado(true);
        refGrillaExtendida.current?.setBloqueado(true);
        try {
            let archivosAdjuntos = await api.getArchivosAdjuntosCaratula(carpeta);
            if (archivosAdjuntos?.length > 0) {
                updateMensajeDialogo(`La caratula ${carpeta} tiene ${archivosAdjuntos.length} archivos adjuntos. ¿Está seguro que desea eliminar los archivos adjuntos junto con la caratula?`);
                let promesa = refDialogo.current?.mostrar().then(() => true).catch(() => false);
                if (await promesa) {
                    try {
                        await googleDrive.login();
                        await googleDrive.deleteFiles(archivosAdjuntos.map((a: any) => a.GoogleDriveId));
                    } catch (error) {
                        if (!api.isCancel(error)) {
                            console.error('Error al eliminar archivos adjuntos de Google Drive', error);
                            if (error instanceof WrongUserGoogleDriveLoginError) {
                                mostrarError(error.message);
                            } else if (error instanceof GoogleDriveLoginError || error instanceof GoogleDriveAuthError) {
                                mostrarError('Hubo un error al iniciar sesión en Google Drive. No se pudo eliminar la caratula');
                            } else {
                                mostrarError('Error al eliminar archivos adjuntos de Google Drive');
                            }
                            refGrilla.current?.setBloqueado(false);
                            refGrillaExtendida.current?.setBloqueado(false);
                        }
                        return;
                    }
                } else {
                    refGrilla.current?.setBloqueado(false);
                    refGrillaExtendida.current?.setBloqueado(false);
                    return;
                }
            }
            let puedeEliminar = await api.obtenerLock(TipoLock.Caratula, carpeta);
            if (puedeEliminar) {
                let { exito, error } = await api.eliminarCaratula(carpeta);
                if (exito) {
                    recargarGrillas();
                } else {
                    mostrarError(error);
                }
            } else {
                mostrarError(`No se puede eliminar la caratula ${carpeta} porque otra persona la está utilizando`);
            }
            refGrilla.current?.setBloqueado(false);
            refGrillaExtendida.current?.setBloqueado(false);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al eliminar caratula', error);
                mostrarError('Hubo un error al eliminar la caratula');
                refGrilla.current?.setBloqueado(false);
                refGrillaExtendida.current?.setBloqueado(false);
            }
        }
    }
    async function exportarAExcel() {
        try {
            updateMensajeSpinner('Generando Excel...');
            let fileName: string, excel: Blob;
            if (tabActual === 'extendida') {
                ({ fileName, excel } = await api.exportarBusquedaCaratulaExtendidaAExcel(busquedaActual.despachante,
                    busquedaActual.importador, busquedaActual.usarFechas, busquedaActual.fechaDesde,
                    busquedaActual.fechaHasta, busquedaActual.carpeta));
            } else {
                ({ fileName, excel } = await api.exportarBusquedaCaratulaAExcel(busquedaActual.despachante,
                    busquedaActual.importador, busquedaActual.usarFechas, busquedaActual.fechaDesde,
                    busquedaActual.fechaHasta, busquedaActual.carpeta));
            }
            updateMensajeSpinner('');
            FileSaver.saveAs(excel, fileName);
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error(error);
                mostrarError('Error al exportar reporte caratulas a Excel');
            }
            if (!api.isUnmounted()) {
                updateMensajeSpinner('');
            }
        }
    }
    function getCarpetasSeleccionadas() {
        if (tabActual === 'extendida') {
            return refGrillaExtendida.current!.getClavesSeleccionadas();
        } else {
            return refGrilla.current!.getClavesSeleccionadas();
        }
    }
    async function exportarCaratulas() {
        let carpetasSeleccionadas = getCarpetasSeleccionadas();
        if (!carpetasSeleccionadas || carpetasSeleccionadas.length === 0) {
            mostrarError('Debe seleccionar al menos una carpeta para exportarla');
            return;
        }
        try {
            updateMensajeSpinner('Exportando caratulas...');
            const despachantes = await api.getDespachantes();
            const vendedores = await api.getVendedores();
            const importadores = await api.getImportadores();
            let contenido: { Entradas: any[] } = { Entradas: [] };
            for (let interno of carpetasSeleccionadas) {
                const caratula = await api.getCaratula(interno);
                const subitems = await api.getSubitemsCaratula(interno);
                const cancelaciones = await api.getCancelacionesSubitemsCaratula(interno);
                contenido.Entradas.push({
                    Caratula: caratula, Subitems: subitems, Cancelaciones: cancelaciones,
                    NombreDespachante: despachantes.find((d: any) => d.CUIT === caratula.CuitDespachante)?.Nombre,
                    NombreImportador: importadores.find((i: any) => i.CUIT === caratula.CuitImportador)?.Nombre,
                    NombreVendedor: vendedores.find((v: any) => v.Codigo === caratula.CodigoVendedor)?.Nombre
                });
            }
            let blob = new Blob([JSON.stringify(contenido)]);
            let nombreArchivo = 'caratulas.sint4';
            if (carpetasSeleccionadas.length === 1) {
                nombreArchivo = sanitizarNombreInternoParaArchivo(carpetasSeleccionadas[0]) + '.sint4';
            }
            FileSaver.saveAs(blob, nombreArchivo);
            updateMensajeSpinner('');
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al exportar caratulas', error);
                mostrarError('Error al exportar caratulas');
            }
            if (!api.isUnmounted()) {
                updateMensajeSpinner('');
            }
        }
    }
    const cerrarModalCrearCaratula = () => {
        api.cancelCurrentTokens();
        updateCreandoCaratula(false);
    }
    const cerrarModalCrearCaratulaAutonumerico = () => {
        api.cancelCurrentTokens();
        updateEstadoModalCrearCaratulaAutonumerico({ tipo: 'cerrar' });
    }
    const cerrarModalCopiarCaratula = () => {
        api.cancelCurrentTokens();
        updateCarpetaACopiar('');
    }
    const cerrarModalUnificarCaratulas = () => {
        api.cancelCurrentTokens();
        updateCarpetasAUnificar([]);
    }
    function getTipoOperacionPorDefecto() {
        if (tiposOperacion.length === 1) {
            return tiposOperacion[0].Codigo;
        } else {
            return tiposOperacion.find((t: any) => t.PorDefecto)?.Codigo ?? '';
        }
    }
    const optionsTiposOperacion = tiposOperacion.map((t: any) => ({ value: t.Codigo, label: t.Descripcion }));
    const nombreEmpresa = optionsEmpresas.find(o => o.value === userInfo.empresaActual)?.label ?? '';

    //Ejecuto modal para ver progresos, si se inicia la sesión automaticamente y el valor loginAutomatico es true.
    useEffect(() => {
        let loginAutomatico = storage?.getItem('loginAutomatico')
        const isLoginAutomatico = loginAutomatico === "true";
        if (isLoginAutomatico) {
            setShowModalProgresoTXT(true)
        }

     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <ModalProgresoTXT handleClose={() => setShowModalProgresoTXT(false)} showModalProgresoTXT={showModalProgresoTXT} />
        <h2>Operaciones y carpetas</h2>
        <BlockUi blocking={cargando}>
            <Form inline onSubmit={e => {
                updateBusquedaActual(busqueda);
                e.preventDefault();
            }}>
                <Form.Group className="my-2">
                    <Form.Label htmlFor="cboDespachante" className="mx-2">Despachante</Form.Label>
                    <CustomSelect id="cboDespachante" options={optionsDespachantes}
                        placeholder="Seleccionar..." isClearable onChange={(option: SelectOption | null | undefined) =>
                            updateBusqueda({ tipo: 'despachante', valor: option?.value ?? '' })}
                        getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></CustomSelect>
                </Form.Group>
                <Form.Group className="my-2">
                    <Form.Label htmlFor="cboImportador" className="mx-2">Cliente</Form.Label>
                    <CustomSelect id="cboImportador" options={optionsImportadores}
                        placeholder="Seleccionar..." isClearable onChange={(option: SelectOption | null | undefined) =>
                            updateBusqueda({ tipo: 'importador', valor: option?.value ?? '' })}
                        getOptionLabel={(option: SelectOption) => option.value + ' - ' + option.label}></CustomSelect>
                </Form.Group>
                <Form.Group className="my-2">
                    <Form.Label htmlFor="dateDesde" className="mx-2">Fecha Desde</Form.Label>
                    <Form.Control type="date" id="dateDesde" value={busqueda.fechaDesde} className="mr-2 mb-2"
                        disabled={!busqueda.usarFechas} onChange={e => updateBusqueda({ tipo: 'fechaDesde', valor: e.target.value })}></Form.Control>
                    <Form.Label htmlFor="dateHasta" className="mx-2">Fecha Hasta</Form.Label>
                    <Form.Control type="date" id="dateHasta" value={busqueda.fechaHasta} className="mr-2 mb-2"
                        disabled={!busqueda.usarFechas} onChange={e => updateBusqueda({ tipo: 'fechaHasta', valor: e.target.value })}></Form.Control>
                    <Form.Check type="checkbox" custom id="checkUsarFechas" label="Usar Fechas"
                        checked={busqueda.usarFechas} onChange={(e: any) => updateBusqueda({ tipo: 'usarFechas', valor: e.target.checked })}></Form.Check>
                </Form.Group>
                <Form.Group className="my-2">
                    <Form.Label htmlFor="txtCarpeta" className="mx-2">Carpeta</Form.Label>
                    <Form.Control type="text" id="txtCarpeta" value={busqueda.carpeta} className="mr-2 mb-2"
                        onChange={e => updateBusqueda({ tipo: 'carpeta', valor: e.target.value })}></Form.Control>
                </Form.Group>
                <Button type="submit" className="mb-2">Buscar</Button>
            </Form>
            <Dropdown className="d-inline">
                <Dropdown.Toggle id="menuOperaciones" className="mb-2">Operaciones y carpetas</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={eventoBotonCrearCaratula}>Crear carpeta</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        let carpetasSeleccionadas = getCarpetasSeleccionadas();
                        if (carpetasSeleccionadas.length === 1) {
                            updateCarpetaACopiar(carpetasSeleccionadas[0]);
                        } else if (carpetasSeleccionadas.length === 0) {
                            mostrarError('Debe seleccionar una carpeta para copiarla');
                        } else {
                            mostrarError('Debe seleccionar solamente una carpeta para copiarla');
                        }
                    }}>Copiar carpeta</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        let carpetasSeleccionadas = getCarpetasSeleccionadas();
                        if (carpetasSeleccionadas.length > 1) {
                            updateCarpetasAUnificar(carpetasSeleccionadas);
                        } else {
                            mostrarError('Debe seleccionar al menos dos carpetas para unificarlas');
                        }
                    }}>Unificar carpetas</Dropdown.Item>
                    <Dropdown.Item onClick={() => history.push('/recuperarCaratulas')}>Recuperar carpetas</Dropdown.Item>
                    <Dropdown.Item onClick={() => history.push('/importarCaratulas')}>Importar carpetas</Dropdown.Item>
                    <Dropdown.Item onClick={exportarCaratulas}>Exportar carpetas</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Button className="ml-2 mb-2" onClick={() => history.push('/catalogos')}>Catalogos</Button>
            <Button className="ml-2 mb-2" onClick={() => history.push('/despachantes')}>Despachantes</Button>
            <Button className="ml-2 mb-2" onClick={() => history.push('/importadores')}>Clientes</Button>
            <Button className="ml-2 mb-2" onClick={() => history.push('/vendedores')}>Vendedores</Button>
            <Button className="ml-2 mb-2" onClick={() => history.push('/transportistas')}>Transportistas</Button>
            <Button variant="success" className="ml-2 mb-2" onClick={exportarAExcel}>
                <FontAwesomeIcon icon={faFileExcel} />
                <span>Exportar a Excel</span>
            </Button>
            <Button className="ml-2 mb-2" onClick={() => history.push('/configuracion')}>Configuración</Button>
            <Tabs activeKey={tabActual} onSelect={selectedTab => {
                updateTabActual(selectedTab ?? 'normal');
            }}>
                <Tab eventKey="normal" title="Normal">
                    <div className="mt-2">
                        <Grilla seleccionMultiple campos={campos} cargarDatos={cargarDatos}
                            eventoDetalle={item => history.push('/caratulas/' + encodeURIComponent(item.Carpeta), { mostrarTabInicial: true })}
                            eventoEliminar={item => {
                                updateMensajeDialogo(`¿Está seguro que desea eliminar la caratula ${item.Carpeta}?`);
                                refDialogo.current?.mostrar().then(() => eliminarCaratula(item.Carpeta)).catch(() => { });
                            }}
                            ref={refGrilla} onGrillaCargada={() => {
                                if (tabActual === 'normal') {
                                    refGrilla.current?.ponerFocoEnTabla();
                                }
                            }} />
                    </div>
                </Tab>
                <Tab eventKey="extendida" title="Extendida">
                    <div className="mt-2">
                        <Grilla seleccionMultiple campos={camposExtendida} cargarDatos={cargarDatosExtendida}
                            eventoDetalle={item => history.push('/caratulas/' + encodeURIComponent(item.Carpeta), { mostrarTabInicial: true })}
                            eventoEliminar={item => {
                                updateMensajeDialogo(`¿Está seguro que desea eliminar la caratula ${item.Carpeta}?`);
                                refDialogo.current?.mostrar().then(() => eliminarCaratula(item.Carpeta)).catch(() => { });
                            }}
                            ref={refGrillaExtendida} onGrillaCargada={() => {
                                if (tabActual === 'extendida') {
                                    refGrillaExtendida.current?.ponerFocoEnTabla();
                                }
                            }} />
                    </div>
                </Tab>
            </Tabs>
        </BlockUi>
        <MyModal show={creandoCaratula} onHide={cerrarModalCrearCaratula}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Crear Carpeta</h2>
                </Modal.Header>
                <Formik innerRef={refFormikCrearCaratula} validationSchema={Yup.object({
                    'Tipo': Yup.string().nullable().test('requerir-tipos-si-existen', 'Debe seleccionar un tipo',
                        valor => tiposOperacion.length > 0 ? !isNullOrWhiteSpace(valor) : true),
                    'Valor': Yup.string().nullable().required('Debe ingresar el nombre de la carpeta')
                })} initialValues={{
                    'Valor': '',
                    'Tipo': getTipoOperacionPorDefecto()
                }} onSubmit={crearCaratula}>
                    {({ submitForm, isSubmitting }) =>
                        <>
                            <Modal.Body>
                                <MyForm>
                                    <Form.Group controlId="txtEmpresa">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control type="text" disabled plaintext value={nombreEmpresa} className="font-weight-bold"></Form.Control>
                                    </Form.Group>
                                    {tiposOperacion.length > 0 ?
                                        <Form.Group>
                                            <MySelect label="Tipo Operación" name="Tipo" autoFocus
                                                options={optionsTiposOperacion}></MySelect>
                                        </Form.Group> : <Field type="hidden" name="Tipo"></Field>}
                                    <Form.Group>
                                        <MyFormControl type="text" label="Nombre carpeta" name="Valor" autoFocus={tiposOperacion.length === 0}></MyFormControl>
                                    </Form.Group>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={cerrarModalCrearCaratula}>
                                    Cancelar
                                </Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>
                                    Ingresar
                                </Button>
                            </Modal.Footer>
                        </>
                    }
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalCrearCaratulaAutonumerico.abierto} onHide={cerrarModalCrearCaratulaAutonumerico}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Crear Carpeta</h2>
                </Modal.Header>
                <Formik validationSchema={Yup.object({
                    'Tipo': Yup.string().nullable().required('Debe seleccionar el tipo de operación de la nueva carpeta')
                })} initialValues={{
                    'Tipo': getTipoOperacionPorDefecto(),
                }} onSubmit={submitCrearCaratulaAutonumerico}>
                    {({ submitForm, isSubmitting }) =>
                        <>
                            <Modal.Body>
                                <MyForm>
                                    <Form.Group controlId="txtEmpresa">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control type="text" disabled plaintext value={nombreEmpresa} className="font-weight-bold"></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <p className="form-text">Seleccione el tipo de operación de la nueva caratula</p>
                                        <MySelect options={optionsTiposOperacion} label="Tipo de operación" hideLabel name="Tipo"></MySelect>
                                    </Form.Group>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={cerrarModalCrearCaratulaAutonumerico}>
                                    Cancelar
                                </Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>
                                    Ingresar
                                </Button>
                            </Modal.Footer>
                        </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!isNullOrWhiteSpace(carpetaACopiar)}
            onHide={cerrarModalCopiarCaratula}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Copiar Carpeta</h2>
                </Modal.Header>
                <Formik innerRef={refFormikCopiarCaratula}
                    validationSchema={Yup.object({
                        'EmpresaId': Yup.string().nullable().required('Debe seleccionar la empresa'),
                        'Valor': Yup.string().nullable().required('Debe ingresar el nombre de la copia'),
                        'ConvertirNCMtoCODAFIP': Yup.boolean()
                    })}
                    initialValues={{
                        'EmpresaId': userInfo.empresaActual ?? '',
                        'Valor': '',
                        'ConvertirNCMtoCODAFIP': false,
                    }}
                    onSubmit={copiarCaratula}>
                    {({ isSubmitting, submitForm }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group controlId="txtCaratulaACopiar">
                                    <Form.Label>Carpeta a copiar</Form.Label>
                                    <Form.Control type="text" disabled plaintext value={carpetaACopiar}></Form.Control>
                                </Form.Group>
                                {optionsEmpresas.length > 1 ?
                                    <Form.Group>
                                        <MySelect name="EmpresaId" label="Empresa de la copia" options={optionsEmpresas}
                                            getOptionLabel={optionLabelConCodigo}></MySelect>
                                    </Form.Group> : <Field name="EmpresaId" type="hidden"></Field>}
                                <Form.Group>
                                    <MyFormControl autoFocus type="text" label="Nombre de la copia" name="Valor"></MyFormControl>
                                </Form.Group>
                                <Form.Group>
                                    <MyFormCheck name="ConvertirNCMtoCODAFIP" label="Convertir NCM a Codigo AFIP (TRAM)" />
                                </Form.Group>

                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={cerrarModalCopiarCaratula}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={carpetasAUnificar.length > 1}
            onHide={cerrarModalUnificarCaratulas}>
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <h2>Unificar Carpetas</h2>
                </Modal.Header>
                <Formik innerRef={refFormikUnificarCaratula} validationSchema={Yup.object({
                    'Valor': Yup.string().nullable().required('Debe ingresar el nombre de la carpeta unificada')
                })} initialValues={{
                    'Valor': '',
                }} onSubmit={unificarCaratulas}>
                    {({ submitForm, isSubmitting }) => <>
                        <Modal.Body>
                            <MyForm>
                                <Form.Group>
                                    <MyFormControl autoFocus type="text" label="Nombre carpeta unificada" name="Valor"></MyFormControl>
                                </Form.Group>
                            </MyForm>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={cerrarModalUnificarCaratulas}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting} onClick={submitForm}>
                                Ingresar
                            </Button>
                        </Modal.Footer>
                    </>}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={!!mensajeSpinner}>
            <Modal.Dialog>
                <Modal.Body>
                    <p className="lead">{mensajeSpinner}</p>
                    <div className="loader-container">
                        <Loader type="ball-spin-fade-loader" active></Loader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <DialogoConfirmar ref={refDialogo} mensaje={mensajeDialogo} textoBotonConfirmar="Sí" textoBotonCancelar="No" />
    </>)
}