import { EstadoDespacho } from 'Enums';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import React, { useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Container, Form, Modal, Table } from 'react-bootstrap';

interface subItemSufijosProps {
    id: string
    setReloadForm: (args: boolean) => void
    sufijosData: string
    NART: string
    IDDT: string
    ISBT: string
    despachoID: string
    estadoDespacho: string
}

const ModalEditarSubItemSufijos = ({ setReloadForm, estadoDespacho, sufijosData, NART, IDDT, ISBT, despachoID }: subItemSufijosProps) => {

    const generarArrayDeObjetosSufijos = (cadena: string) => {
        const regex = /([A-Z0-9]+)\(([^)]+)\)|([A-Z0-9]+)/g;
        let matches;
        const resultado = [];

        while ((matches = regex.exec(cadena)) !== null) {
            let clave, valor;

            if (matches[1]) {
                clave = matches[1];
                valor = matches[2];
            } else if (matches[3]) {
                clave = matches[3];
                valor = " ";
            }

            resultado.push({ clave, valor });
        }
        return resultado;
    }

    const [show, setShow] = useState(false);
    const [cargando, updateCargando] = useState(false);
    const [tableDataSufijos, setTableDataSufijos] = useState(generarArrayDeObjetosSufijos(sufijosData))
    const [valorEdit, setValorEdit] = useState<string | null>(null);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => setShow(true);
    let api = useAdvAPI();

    const handleInputChange = (index: number, newValue: string) => {
        const updatedData = [...tableDataSufijos];
        updatedData[index].valor = newValue;
        setTableDataSufijos(updatedData);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const invalidChars = ['-', '(', ')'];
        if (invalidChars.includes(e.key)) {
            e.preventDefault(); // Evita que el carácter no permitido se agregue al input
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedText = e.clipboardData.getData('text');
        const invalidChars = ['-', '(', ')'];

        if (invalidChars.some(char => pastedText.includes(char))) {
            e.preventDefault(); // Evita que el texto pegado contenga caracteres no permitidos
        }
    };


    const updateInfo = async () => {
        updateCargando(true)
        const combinedValue = tableDataSufijos
            ?.map(row => `${row?.clave}(${row?.valor})`)
            ?.join('-') + '-';
        try {
            await api.UpdateSufijo({
                "IDDT": IDDT,
                "NART": NART,
                "ISBT": ISBT,
                "CSBTSVL": combinedValue,
                "DespachoID": despachoID
            })
            updateCargando(false)
            setReloadForm(true)
            setShow(false)
        } catch (error) {
            updateCargando(false)
            console.log('error', error)
        }
    }

    return (
        <>
            <p className={estadoDespacho === EstadoDespacho.Oficializado ? '' : "styleLink"} title='Editar' onClick={() => {
                if (estadoDespacho !== EstadoDespacho.Oficializado) {
                    handleShow()
                }
            }} style={{ fontSize: 13, }}>{sufijosData}</p>
            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar Sufijos</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <Container>

                                <Table striped bordered hover size="sm">

                                    <tbody>

                                        {tableDataSufijos?.map((row: any, index: number) => (
                                            <>
                                                <tr >
                                                    <td onClick={() => setValorEdit(row?.valor)} style={{ backgroundColor: valorEdit === row?.valor ? 'blue' : '', color: valorEdit === row?.valor ? 'white' : 'black', fontWeight: 'bold' }}>{row?.clave}</td>
                                                    <td onClick={() => setValorEdit(row?.valor)}><Form.Control type={row?.clave?.startsWith("Z") && "number"} onPaste={handlePaste}
                                                        onKeyPress={handleKeyPress}
                                                        maxLength={row?.clave?.startsWith("A") && 20} disabled={row?.clave?.startsWith("CA")} size='sm' value={row?.valor} onChange={(e) => handleInputChange(index, e.target.value)}
                                                    /></td>

                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>

                            </Container>
                        </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="success" onClick={() => updateInfo()}>
                            Guardar
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEditarSubItemSufijos