import { EstadoDespacho } from 'Enums';
import React, { useEffect, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Col, Container, Dropdown, Form, Modal } from 'react-bootstrap';

interface EditarSufijosProps {
    setReloadForm: (args: boolean) => void
    handleShowModalModificarItems: any
    typeButton?: number
    setSufijosGlobalesValueUno: any
    setSufijosGlobalesValueDos: any
    showModalSufijosGlobales: boolean
    setCloseModalSufijosGlobales: any
    estadoDespacho: string
}

const ModalEditarSufijos = ({ handleShowModalModificarItems,estadoDespacho,setCloseModalSufijosGlobales,showModalSufijosGlobales, typeButton,setSufijosGlobalesValueUno, setSufijosGlobalesValueDos }: EditarSufijosProps) => {

    const [show, setShow] = useState(false);
    const [cargando] = useState(false);
    const [sufijosValueUno, setSufijosValueUno] = useState('')
    const [sufijosValueDos, setSufijosValueDos] = useState('')

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
        setCloseModalSufijosGlobales(false)
}

    useEffect(() => {
     if(showModalSufijosGlobales){
        setShow(false)
     }
    }, [showModalSufijosGlobales])
    

    return (
        <>
            {typeButton === 1 ? <Dropdown.Item onClick={handleShow} disabled={estadoDespacho === EstadoDespacho.Oficializado}>Establecer Sufijos</Dropdown.Item> : <Button variant='success' style={{ marginTop: 28 }} disabled={estadoDespacho === EstadoDespacho.Oficializado} onClick={handleShow} >Establecer Sufijos</Button>
            }            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter" style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Establecer Sufijos</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <Container>
                                <Form.Row>
                                    <Form.Group as={Col} md="2" lg="2">
                                        <Form.Text style={{fontSize:15, marginBottom:-20,fontWeight:'bold'}}>Sufijo</Form.Text>
                                        <Form.Control
                                            style={{ height: 38, marginTop: 26 }}
                                            type="text"
                                            maxLength={2}
                                            name="SUFIJOSVALUEUNO"
                                            value={sufijosValueUno}
                                            onChange={(e) => {
                                                const upperCaseValue = e?.target?.value?.toUpperCase();
                                                setSufijosValueUno(upperCaseValue)
                                                setSufijosGlobalesValueUno(upperCaseValue)}}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="9" lg="9">
                                    <Form.Text style={{fontSize:15, marginBottom:-20,fontWeight:'bold'}}>Valor</Form.Text>
                                        <Form.Control
                                            style={{ height: 38, marginTop: 26 }}
                                            type="text"
                                            name="SUFIJOSVALUEDOS"
                                            value={sufijosValueDos}
                                            onChange={(e) => {
                                                const upperCaseValue = e?.target?.value?.toUpperCase();
                                                setSufijosValueDos(upperCaseValue)
                                                setSufijosGlobalesValueDos(upperCaseValue)}}
                                             />
                                    </Form.Group>

                                </Form.Row>
                            </Container>
                        </Modal.Body>}
                    <Modal.Footer>
                        <Button variant='success' disabled={(sufijosValueUno?.length === 0 || sufijosValueDos?.length === 0)} onClick={() => {
                            handleShowModalModificarItems()
                        }} >Establecer</Button>
                        <Button variant="danger" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </BlockUi>
            </Modal>
        </>
    )
}

export default ModalEditarSufijos