import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { AppContext } from "../../App";
import * as Yup from "yup";
import { Formik, FormikProps, Field } from "formik";
import { MyForm, MyFormCheck, MyFormControl, MyFormRadio, MySelect } from "../../FormikHooks";
import { Button, Col, Form, Alert, Modal, Container } from "react-bootstrap";
import { convertirDatosGenericosAOption, isNullOrWhiteSpace, optionLabelConCodigo, toFixedDecimal } from "../../Utilidades";
import { DateTime } from "luxon";
import { MyModal } from "../../MyModal";
import { DialogoConfirmar, DialogoConfirmarRef } from "../../DialogoConfirmar";
import { EstadoCaratula } from "Enums";
import FileSaver from "file-saver";
import Loader from "react-loaders";
import { TipoLock, useApi } from "ApiHooks";
import { storageFactory } from "storage-factory";
import { useAdvAPI } from "Paginas/advanced/AdvancedApi";
import { ObtenerEstadoArchivos } from "modelos/Advanced";
import ModalProgresoTXT from "Paginas/Caratulas/ModalProgresoTXT";

export type GenerarTxtMariaRef = {
    mostrar: () => Promise<void>
};

const storage = storageFactory(() => localStorage);
const claveOpciones = 'OpcionesGenerarTxtMaria';

type OpcionesGenerarTxt = {
    agregarInternoEnVendedor: boolean,
    agregarInternoEnMarcaBultos: boolean,
    agregarVendedorAtras: boolean,
    enviarDomicilioExpo: boolean,
    informarItemSubitemIext: boolean,
    // alertarDJCP: boolean,
    informarRefInterna: boolean
}

export const GenerarTxtMaria = React.forwardRef(({ interno }: { interno: string }, ref: any) => {
    let formikRef = React.useRef<FormikProps<any>>(null);
    let formikIDA4Ref = React.useRef<FormikProps<any>>(null);
    let { mostrarError, userInfo } = React.useContext(AppContext);
    let api = useApi();
    let apiSintia = useAdvAPI()
    const [showModalProgresoTXT, setShowModalProgresoTXT] = useState(false);
    const [dataArchivos, setDataArchivos] = useState<ObtenerEstadoArchivos[]>([])
    const [saveTXT, setSaveTXT] = useState(false)
    let [cargando, updateCargando] = useState(true);
    let [caratula, updateCaratula] = useState<any>({});
    let [subregimen, updateSubregimen] = useState<any>({});
    let [mensajeErrorAlert, updateMensajeErrorAlert] = useState('');
    let [generandoTxt, updateGenerandoTxt] = useState(false);
    let [habilitarReferenciaInterna, updateHabilitarReferenciaInterna] = useState(false);
    let [habilitarCheckIDA4, updateHabilitarCheckIDA4] = useState(false);
    let dialogoConfirmarRef = useRef<DialogoConfirmarRef>(null);
    let [mensajeDialogoConfirmar, updateMensajeDialogoConfirmar] = useState('');
    let [erroresValidacionSintiaAdvanced, updateErroresValidacionSintiaAdvanced] = useState<string[]>([]);
    let [showErrorCargarArchivo, setShowErrorCargarArchivo] = useState('')
    let [estadoModal, updateEstadoModal] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '', resolve: accion.resolve };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });
    let [estadoModalIDA4, updateEstadoModalIDA4] = useReducer((estado: any, accion: any) => {
        if (accion.tipo === 'mostrar') {
            return { abierto: true, opciones: accion.opciones, error: '' };
        } else if (accion.tipo === 'cerrar') {
            return { abierto: false, opciones: null, error: '' };
        } else if (accion.tipo === 'setError') {
            return { ...estado, error: accion.error };
        }
    }, { abierto: false, opciones: null, error: '' });
    /* como el componente no se rerenderiza cuando cambian los valores de formik hay que actualizar el estado
        manualmente cuando cambia el subregimen y el check generar como IDA4 */
    const puedeUsarSintiaAdvanced = useCallback((codigoSubregimen: string, generarSimiComoIDA4: boolean) => {
        //castear nro cliente alpha a numero porque puede estar guardado como string
        return (codigoSubregimen === 'SIMI' || codigoSubregimen === 'SIRA' || codigoSubregimen === 'SEDI') && !generarSimiComoIDA4 && userInfo.claims.hasOwnProperty('Advanced');
    }, []); //eslint-disable-line
    let [habilitarSintiaAdvanced, updateHabilitarUsarSintiaAdvanced] = React.useState(puedeUsarSintiaAdvanced('', false));
    let schema = Yup.object({
        'CodigoSubregimen': Yup.string().nullable(),
        'AgregarCarpetaAVendedor': Yup.boolean().nullable(),
        'AgregarCarpetaAMarcaBultos': Yup.boolean().nullable(),
        'AnexoVendedorAtras': Yup.boolean().nullable().required('Debe seleccionar donde poner el anexo vendedor'),
        'InformarIExtItemSubitem': Yup.boolean().nullable(),
        'EnviarDomicilioImportadorExportador': Yup.boolean().nullable(),
        'InformarReferenciaInterna': Yup.boolean().nullable(),
        'ReferenciaInterna': Yup.string().nullable(),
        'GenerarSIMIComoIDA4': Yup.boolean().nullable(),
        'EnviarASintiaAdvanced': Yup.boolean()
    });
    let schemaIDA4 = Yup.object({
        'CondicionVenta': Yup.string().nullable().required('Debe ingresar la condición de venta'),
        'FechaEmbarque': Yup.date().nullable().required('Debe ingresar la fecha de embarque'),
        'FechaEmisionFactura': Yup.date().nullable().required('Debe ingresar la fecha de emisión de la factura'),
        'MontoFlete': Yup.number().nullable().typeError('Debe ingresar un número')
            .required('Debe ingresar el monto del flete')
            .moreThan(0, 'Debe ser mayor a cero'),
        'MonedaFlete': Yup.string().nullable().typeError('Debe ingresar un número')
            .required('Debe ingresar la moneda del flete'),
        'MontoSeguro': Yup.number().nullable().typeError('Debe ingresar un número')
            .required('Debe ingresar el monto del seguro')
            .moreThan(0, 'Debe ser mayor a cero'),
        'MonedaSeguro': Yup.string().nullable().required('Debe ingresar la moneda del seguro'),
        'MotivoDestSuspensiva': Yup.string().nullable().required('Debe ingresar el motivo'),
        'Plazo': Yup.number().nullable().typeError('Debe ingresar un número')
            .required('Debe ingresar el plazo')
            .integer('Debe ser un número entero').moreThan(0, 'El plazo debe ser mayor a cero'),
        'PaisFacturacion': Yup.string().nullable().required('Debe ingresar el país de facturación'),
        'MontoFob': Yup.number().nullable().typeError('Debe ingresar un número'),
        'MonedaFob': Yup.string().nullable(),
        'Interno': Yup.string().nullable(),
    });
    useEffect(() => {
        if (estadoModal.abierto) {
            async function cargar() {
                try {
                    let respuesta = await api.getCaratula(interno);
                    if (respuesta.Estado === EstadoCaratula.ItemsArmados ||
                        respuesta.Estado === EstadoCaratula.ErrorLiquidacion ||
                        respuesta.Estado === EstadoCaratula.LiquidacionHecha) {
                        updateCaratula(respuesta);
                        let subregimenes = await api.getSubregimenes();
                        let subregimenCaratula = subregimenes.find((s: any) => s.Codigo === respuesta.CodigoSubregimen);
                        updateSubregimen(subregimenCaratula);
                        updateHabilitarCheckIDA4((respuesta.CodigoSubregimen === 'SIMI' || respuesta.CodigoSubregimen === 'SIRA' || respuesta.CodigoSubregimen === 'SEDI'));
                        updateHabilitarUsarSintiaAdvanced(puedeUsarSintiaAdvanced(respuesta.CodigoSubregimen, false));
                        let opciones: OpcionesGenerarTxt | null = null;
                        try {
                            let json = storage.getItem(claveOpciones);
                            if (!isNullOrWhiteSpace(json)) {
                                opciones = JSON.parse(json as string);
                            }
                        } catch (error) {
                            console.error('Error al leer opciones generar txt maria guardadas en localStorage', error);
                        }
                        formikRef.current?.resetForm({
                            values: {
                                'CodigoSubregimen': respuesta.CodigoSubregimen,
                                'ReferenciaInterna': isNullOrWhiteSpace(respuesta.IdentificadorExterno) ? interno : `${interno}|${respuesta.IdentificadorExterno}`,
                                'AgregarCarpetaAVendedor': opciones?.agregarInternoEnVendedor ?? false,
                                'AgregarCarpetaAMarcaBultos': opciones?.agregarInternoEnMarcaBultos ?? false,
                                'AnexoVendedorAtras': opciones?.agregarVendedorAtras ? 'true' : 'false', //ponerlo como string para que se marque el radio
                                'InformarIExtItemSubitem': opciones?.informarItemSubitemIext ?? false,
                                'EnviarDomicilioImportadorExportador': opciones?.enviarDomicilioExpo ?? false,
                                'InformarReferenciaInterna': opciones?.informarRefInterna ?? false,
                                'GenerarSIMIComoIDA4': false,
                            }
                        });
                        updateHabilitarReferenciaInterna(opciones?.informarRefInterna ?? false);
                        updateCargando(false);
                    } else {
                        // updateNotFound(true);
                    }
                } catch (error) {
                    if (!api.isCancel(error)) {
                        console.error('Error al cargar caratula', error);
                        mostrarError('Error al cargar caratula');
                    }
                }
            }
            cargar();
            return () => {
                api.cancelCurrentTokens();
            }
        }
        //eslint-disable-next-line
    }, [estadoModal.abierto]);
    let estadoModalIDA4Abierto = estadoModalIDA4?.abierto;
    useEffect(() => {
        if (estadoModalIDA4Abierto === true) {
            formikIDA4Ref.current?.resetForm({
                values: {
                    'Interno': interno,
                    'MontoFob': toFixedDecimal(caratula.TotalFob, 2),
                    'MontoFlete': toFixedDecimal(caratula.Flete, 2),
                    'MontoSeguro': toFixedDecimal(caratula.Seguro, 2),
                    'MonedaFob': caratula.CodigoDivisaFob,
                    'MonedaFlete': caratula.CodigoDivisaFlete,
                    'MonedaSeguro': caratula.CodigoDivisaSeguro,
                    'PaisFacturacion': caratula.CodigoPaisFacturacion,
                    'FechaEmbarque': isNullOrWhiteSpace(caratula.FechaEmbarque) ? DateTime.local().toISODate() : DateTime.fromISO(caratula.FechaEmbarque).toISODate(),
                    'FechaEmisionFactura': isNullOrWhiteSpace(caratula.FechaEmisionFactura) ? DateTime.local().toISODate() : DateTime.fromISO(caratula.FechaEmisionFactura).toISODate(),
                    'CondicionVenta': caratula.CondicionVenta,
                    'MotivoDestSuspensiva': caratula.MotivoDestSuspensiva,
                    'Plazo': caratula.PlazoAutorizacion
                }
            });
        }
        //eslint-disable-next-line
    }, [estadoModalIDA4Abierto]);

    const verificarDespachoIDNull = (array: any) => {
        if (array.some((despacho: ObtenerEstadoArchivos) => despacho.DespachoID === null)) {
            setShowModalProgresoTXT(true)
        }
    }

    useEffect(() => {
        if (dataArchivos?.length > 0) {
            verificarDespachoIDNull(dataArchivos)
        }
        // eslint-disable-next-line
    }, [dataArchivos, showModalProgresoTXT])

    const postCargarArchivoTXT = async (base64String: any) => {
        setShowErrorCargarArchivo('')
        try {
            let cargarArchivo =  await apiSintia.PostCargarArchivo(base64String)
            if(cargarArchivo?.Errores?.[0]?.Descripcion?.length > 0){
                setShowErrorCargarArchivo(cargarArchivo?.Errores?.[0]?.Descripcion)
            }else{
                setShowModalProgresoTXT(true)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    async function generarTxt(values: any) {
        try {
            updateGenerandoTxt(true);
            let opciones: OpcionesGenerarTxt = {
                agregarInternoEnVendedor: values.AgregarCarpetaAVendedor ?? false,
                agregarInternoEnMarcaBultos: values.AgregarCarpetaAMarcaBultos ?? false,
                agregarVendedorAtras: values.AnexoVendedorAtras ?? false,
                informarItemSubitemIext: values.InformarIExtItemSubitem ?? false,
                enviarDomicilioExpo: values.EnviarDomicilioImportadorExportador ?? false,
                informarRefInterna: values.InformarReferenciaInterna ?? false,
            };
            storage.setItem(claveOpciones, JSON.stringify(opciones));
            if ((values.CodigoSubregimen === 'SIMI' || values.CodigoSubregimen === 'SIRA' || values.CodigoSubregimen === 'SEDI') && !values.GenerarSIMIComoIDA4) {
                let respuesta = await api.getArmadoItems(interno);
                updateCaratula(respuesta.Caratula);
                if (respuesta.Caratula.ForzarDescripcionMercaderia) {
                    if (isNullOrWhiteSpace(respuesta.Caratula.DescripcionMercaderia)) {
                        updateMensajeDialogoConfirmar('La caratula no tiene descripción (obligatoria en SEDI). ¿Desea generar el TXT?');
                        if (!await dialogoConfirmarRef.current!.mostrar()) {
                            updateGenerandoTxt(false);
                            return;
                        }
                    }
                } else {
                    for (let item of respuesta.Items.sort((a: any, b: any) => a.NroItem - b.NroItem)) {
                        if (item.NroItem !== 0 && isNullOrWhiteSpace(item.Descripcion)) {
                            updateMensajeDialogoConfirmar(`El item ${item.NroItem} no tiene descripción (obligatoria en SEDI). ¿Desea generar el TXT?`);
                            if (!await dialogoConfirmarRef.current!.mostrar()) {
                                updateGenerandoTxt(false);
                                return;
                            }
                        }
                    }
                }
            }
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                const parametros = {
                    EmpresaId: userInfo.empresaActual,
                    Carpeta: interno,
                    CodigoSubregimen: values.CodigoSubregimen,
                    AgregarCarpetaAVendedor: values.AgregarCarpetaAVendedor ?? false,
                    AgregarCarpetaAMarcaBultos: values.AgregarCarpetaAMarcaBultos ?? false,
                    AnexoVendedorAtras: values.AnexoVendedorAtras ?? false,
                    InformarIExtItemSubitem: values.InformarIExtItemSubitem ?? false,
                    EnviarDomicilioImportadorExportador: values.EnviarDomicilioImportadorExportador ?? false,
                    GenerarSIMIComoIDA4: values.GenerarSIMIComoIDA4 ?? false,
                    ReferenciaInterna: values.InformarReferenciaInterna ? values.ReferenciaInterna : ''
                };
                if (subregimen?.Tipo !== 'E') {
                    parametros.EnviarDomicilioImportadorExportador = false;
                }
                if (values.EnviarASintiaAdvanced) {
                    let respuesta = await api.generarTxtSintiaAdvanced(parametros);
                    if (respuesta.error) {
                        if (respuesta.excepciones?.length > 0) {
                            mostrarError('Hubo un error al generar el txt y enviarlo al Sintia Advanced');
                        } else if (respuesta.errores?.length > 0) {
                            updateErroresValidacionSintiaAdvanced(respuesta.errores);
                        } else {
                            mostrarError('Hubo un error al generar el txt y enviarlo al Sintia Advanced');
                        }
                    } else {
                        window.open(respuesta.url);
                    }
                } else {
                    if (saveTXT) {
                        let { txt, fileName } = await api.generarTxtMaria(parametros);
                        FileSaver.saveAs(txt, fileName);
                    } else {
                        let { txt } = await api.generarTxtMaria(parametros);
                        const reader = new FileReader();
                        reader.readAsDataURL(txt);
                        reader.onloadend = () => {
                            const result = reader.result;
                            if (typeof result === 'string') {
                                const base64String = result.split(',')[1];
                                postCargarArchivoTXT(base64String);
                            }
                          
                        }
                    }

                }
                await api.eliminarLock(TipoLock.Caratula, interno);
                updateGenerandoTxt(false);
            } else {
                updateGenerandoTxt(false);
                updateMensajeErrorAlert('No se puede generar el txt porque otro usuario está modificando la caratula');
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al generar txt', error);
                mostrarError('Error al generar txt');
            }
            if (!api.isUnmounted()) {
                updateGenerandoTxt(false);
            }
        }
    }
    async function submit(values: any) {
        console.log('submit')
        if ((values.CodigoSubregimen === 'SIMI' || values.CodigoSubregimen === 'SIRA' || values.CodigoSubregimen === 'SEDI') && values.GenerarSIMIComoIDA4) {
            updateEstadoModalIDA4({ tipo: 'mostrar', opciones: values });
        } else {
            await generarTxt(values);
        }
    }
    async function submitIDA4(values: any) {
        try {
            let { Interno, TotalFob, MonedaFob, ...otrosValues } = values;
            let puedeModificar = await api.obtenerLock(TipoLock.Caratula, interno);
            if (puedeModificar) {
                let respuesta = await api.updateDatosIDA4({ ...otrosValues, Carpeta: interno });
                await generarTxt(estadoModalIDA4.opciones);
                updateCaratula(respuesta);
                updateEstadoModalIDA4({ tipo: 'cerrar' });
            } else {
                updateEstadoModalIDA4({ tipo: 'setError', error: 'No se pudo guardar los cambios a la caratula porque otro usuario la está modificando' });
            }
        } catch (error) {
            if (!api.isCancel(error)) {
                console.error('Error al guardar caratula', error);
                mostrarError('Error al guardar caratula');
            }
        }
    }
    React.useImperativeHandle(ref, () => ({
        mostrar: () => new Promise<void>((resolve, reject) => {
            updateEstadoModal({ tipo: 'mostrar', resolve: resolve });
        })
    }));
    const cerrar = () => {
        if (estadoModal.resolve) {
            estadoModal.resolve();
        }
        updateEstadoModal({ tipo: 'cerrar' });
    }

    const handleClose = () => {
        setDataArchivos([])
        setShowModalProgresoTXT(false)
    }

    return <>
        <MyModal show={estadoModal.abierto} onHide={cerrar}>
            <Modal.Dialog size="lg">
                <Modal.Header closeButton>
                    <h2>Transferencia al SIM de {interno}</h2>
                </Modal.Header>
                <Formik initialValues={{}} validationSchema={schema} innerRef={formikRef} onSubmit={submit}>
                    {({ submitForm, isSubmitting }) => (<>
                        <Modal.Body>
                            <MyForm blocking={cargando}>
                                {mensajeErrorAlert && (<Alert variant="danger">{mensajeErrorAlert}</Alert>)}
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MySelect name="CodigoSubregimen" label="Subregimen" getOptionLabel={optionLabelConCodigo}
                                            options={() => api.getSubregimenes().then(convertirDatosGenericosAOption)} onValueChange={async value => {
                                                try {
                                                    let codigo = value as string;
                                                    let listaSubregimenes = await api.getSubregimenes();
                                                    updateSubregimen(listaSubregimenes.find((s: any) => s.Codigo === codigo));
                                                    updateHabilitarCheckIDA4((codigo === 'SIMI' || codigo === 'SIRA' || codigo === 'SEDI'));
                                                    updateHabilitarUsarSintiaAdvanced(puedeUsarSintiaAdvanced(codigo, formikRef.current!.values!.GenerarSIMIComoIDA4));
                                                } catch (error) {
                                                    if (!api.isCancel(error)) {
                                                        console.error('Error al actualizar datos subregimen', error);
                                                    }
                                                }
                                            }}></MySelect>
                                        <MyFormCheck className="mt-2" name="GenerarSIMIComoIDA4" label="Generar como IDA4" disabled={!habilitarCheckIDA4}
                                            onCheckedChange={checked => updateHabilitarUsarSintiaAdvanced(puedeUsarSintiaAdvanced(subregimen.Codigo, checked))}></MyFormCheck>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MyFormCheck name="AgregarCarpetaAVendedor" label="Agregar Nro. Interno en Vendedor"></MyFormCheck>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MyFormCheck name="AgregarCarpetaAMarcaBultos" label="Agregar Interno en Marca Bultos"></MyFormCheck>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <p>Anexo Vendedor</p>
                                        <MyFormRadio inline id="anexoAtras" name="AnexoVendedorAtras" label="Agregar Adelante" value="false"></MyFormRadio>
                                        <MyFormRadio inline id="anexoAdelante" name="AnexoVendedorAtras" label="Agregar Atrás" value="true"></MyFormRadio>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MyFormCheck name="EnviarDomicilioImportadorExportador" label="Enviar domicilio y fecha inicio actividad" disabled={subregimen?.Tipo !== 'E'}></MyFormCheck>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MyFormCheck name="InformarIExtItemSubitem" label="Informar en IEXT = Item|Subitem"></MyFormCheck>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <MyFormCheck className="mb-2" name="InformarReferenciaInterna" label="Informar Interno  | Ident Ext en Nro. Ref Interna" onCheckedChange={checked => {
                                            updateHabilitarReferenciaInterna(checked)
                                        }}></MyFormCheck>
                                        <MyFormControl type="text" name="ReferenciaInterna" label="Referencia Interna"
                                            hideLabel disabled={!habilitarReferenciaInterna}></MyFormControl>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <p>NOTA: Usted va a transferir la información complementaria LOCALID/PROVINC tomando como dato el campo LOCALIDAD en la pantalla carga de importadores/exportadores</p>
                                    </Col>
                                </Form.Row>
                                <Field name="EnviarASintiaAdvanced" type="hidden"></Field>
                            </MyForm>
                            <div>
                                {showErrorCargarArchivo?.length > 0 && <p style={{fontSize:20, fontWeight:'bold', color:'red', marginTop:10}}>{showErrorCargarArchivo}</p>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={cerrar}>
                                Cancelar
                            </Button>
                            <Button disabled={isSubmitting || !habilitarSintiaAdvanced} onClick={() => {
                                //poner ultimo parametro en false para que no borre errores cuando se actualice el valor
                                formikRef.current?.setFieldValue('EnviarASintiaAdvanced', false, false);
                                setSaveTXT(false)
                                submitForm();
                            }}>
                                Validación Automatica
                            </Button>
                            <Button disabled={isSubmitting} onClick={() => {
                                //poner ultimo parametro en false para que no borre errores cuando se actualice el valor
                                formikRef.current?.setFieldValue('EnviarASintiaAdvanced', false, false);
                                setSaveTXT(true)
                                submitForm();
                            }}>
                                Generar TXT
                            </Button>
                        </Modal.Footer></>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={estadoModalIDA4?.abierto} onHide={() => updateEstadoModalIDA4({ tipo: 'cerrar' })}>
            <Modal.Dialog size="xl">
                <Modal.Header closeButton>
                    <h2>Generar Txt de SEDI como IDA4</h2>
                </Modal.Header>
                <Formik innerRef={formikIDA4Ref} validationSchema={schemaIDA4} initialValues={{}} onSubmit={submitIDA4}>
                    {({ submitForm, isSubmitting }) => (
                        <>
                            <Modal.Body>
                                <MyForm>
                                    {estadoModalIDA4?.error && (<Alert variant="danger">{estadoModalIDA4?.error}</Alert>)}
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="text" name="Interno" label="Interno" disabled plaintext></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="MontoFob" label="Importe FOB" disabled plaintext></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect isDisabled name="MonedaFob" label="Moneda FOB" getOptionLabel={optionLabelConCodigo} options={() => api.getMonedas().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="MontoFlete" label="Importe Flete"></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="MonedaFlete" label="Moneda Flete" getOptionLabel={optionLabelConCodigo} options={() => api.getMonedas().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="MontoSeguro" label="Importe Seguro"></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="MonedaSeguro" label="Moneda Seguro" getOptionLabel={optionLabelConCodigo} options={() => api.getMonedas().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelect name="CondicionVenta" label="Condición Venta" getOptionLabel={optionLabelConCodigo} options={() => api.getCondicionesVenta().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MySelect name="PaisFacturacion" label="País Facturación" getOptionLabel={optionLabelConCodigo} options={() => api.getPaises().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="date" name="FechaEmbarque" label="Fecha Embarque"></MyFormControl>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="date" name="FechaEmisionFactura" label="Fecha Emisión Factura"></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <MySelect name="MotivoDestSuspensiva" label="Motivo Dest. Suspensiva" getOptionLabel={optionLabelConCodigo} options={() => api.getMotivosSuspensiva().then(convertirDatosGenericosAOption)}></MySelect>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <MyFormControl type="number" name="Plazo" label="Plazo"></MyFormControl>
                                        </Form.Group>
                                    </Form.Row>
                                </MyForm>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="danger" onClick={() => updateEstadoModalIDA4({ tipo: 'cerrar' })}>
                                    Cancelar
                                </Button>
                                <Button disabled={isSubmitting} onClick={submitForm}>
                                    Generar TXT
                                </Button>
                            </Modal.Footer>
                        </>)}
                </Formik>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={generandoTxt}>
            <Modal.Dialog>
                <Modal.Body>
                    <Container style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}>
                        <p className="lead" style={{ marginBottom: '20px' }}>Generando Txt...</p>
                        <div style={{ marginTop: 5, padding: 20 }}>
                            <Loader type="ball-spin-fade-loader" active></Loader>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        api.cancelCurrentTokens();
                    }}>Cancelar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>
        <MyModal show={erroresValidacionSintiaAdvanced.length > 0}>
            <Modal.Dialog>
                <Modal.Body>
                    <div className="text-danger">
                        <p className="lead">El txt tiene los siguientes errores de validación</p>
                        <ul>
                            {erroresValidacionSintiaAdvanced.map(error => <li key={error}>error</li>)}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => updateErroresValidacionSintiaAdvanced([])}>Cerrar</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </MyModal>

      <ModalProgresoTXT handleClose={handleClose} showModalProgresoTXT={showModalProgresoTXT}/>

        <DialogoConfirmar ref={dialogoConfirmarRef} mensaje={mensajeDialogoConfirmar} resolverPromesaAlCancelar textoBotonConfirmar="Sí" textoBotonCancelar="No"></DialogoConfirmar>
    </>;
});