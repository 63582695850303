import { faCheckCircle, faFileAlt, faFileUpload, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Modal, Container, Alert, Toast, OverlayTrigger, Spinner } from 'react-bootstrap'
import { ObtenerEstadoArchivos, SubirArchivos } from 'modelos/Advanced'
import './style-modal.css'
import { renderTooltip } from 'utils/Utils'
import { Link } from 'react-router-dom'
import { formatearFechaHora } from 'Utilidades'

const ModalSubirTXT = () => {

    const [show, setShow] = useState(false)
    const [showModalErrores, setShowModalErrores] = useState(false)
    const [cargando, updateCargando] = useState(false)
    const [errorCarga, setErrorCarga] = useState('')
    const [dataArchivos, setDataArchivos] = useState<ObtenerEstadoArchivos[]>([])
    const [uploadedFile, setUploadedFile] = useState<File | undefined>()
    const [habilitarSubir, setHabilitarSubir] = useState(true)
    const [habilitarErrorArchivo, setHabilitarErrorArchivo] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResults, setSearchResults] = useState(dataArchivos)
    const [mostrarForm, setMostrarForm] = useState<boolean>(true)
    const [errores, setErrores] = useState('')
    const [habilitarReiniciarProgreso, setHabilitarReiniciarProgreso] = useState(false)

    const inputRef = useRef<HTMLInputElement>(null)

    const handleUpload = () => {
        inputRef.current?.click()
    }
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            leerTXT(inputRef?.current?.files?.[0])
        setUploadedFile(inputRef?.current?.files?.[0])
    }

    const handleClose = () => {
        setDataArchivos([])
        setSearchResults([])
        setHabilitarErrorArchivo(false)
        setSearchTerm('')
        setErrorCarga('')
        setUploadedFile(undefined)
        setShow(false)
    }

    const handleShow = () => setShow(true)
    let api = useAdvAPI()

    const subirTXT = (txt: File) => {
        updateCargando(true)
        setMostrarForm(false)
        setErrorCarga('')
        const formData = new FormData()
        formData.append('file', txt)
        api.subirTXT(formData)
            .then((resp: SubirArchivos[]) => {
                updateCargando(false)
                if (resp[0]?.Errores[0]?.Descripcion?.length > 0) {
                    setErrorCarga(resp[0]?.Errores[0]?.Descripcion)
                    obtenerEstadoArchivosTXT()

                } else {
                    obtenerEstadoArchivosTXT()
                }
            })
            .catch((e) => console.log('error', e))
    }


    let intervaloActivo = false 

    const obtenerEstadoArchivosTXT = () => {

        if (intervaloActivo) return 
        intervaloActivo = true
    
        const ejecutarConIntervalo = async () => {
            try {
                const resp = await api.obtenerEstadoArchivos()
    
                const enProgreso = resp.filter((item: ObtenerEstadoArchivos) => item.DespachoID === null && item?.Error === null)
    
                if (JSON.stringify(resp) !== JSON.stringify(dataArchivos)) {
                    setDataArchivos(resp)
                    setSearchResults(resp)
                }
    
                const hayError = resp.filter((item) => item.Error !== null)
                if (hayError.length > 0) {
                    setErrorCarga(hayError[0].Error!)
                }
    
                if (enProgreso.length > 0 && intervaloActivo) {
                    setTimeout(ejecutarConIntervalo, 5000)
                } else {
                    intervaloActivo = false 
                }
                
                updateCargando(false)
            } catch (error) {
                updateCargando(false)
                intervaloActivo = false
                setErrorCarga('Error del servidor')
                console.error('Error:', error)
            }
        }
    
        ejecutarConIntervalo()
    }

    const borrarArchivos = (ids: any) => {
        setErrorCarga('')
        updateCargando(true)
        api.eliminarArchivos([ids]).then((resp) => {
            obtenerEstadoArchivosTXT()
        })
    }

    const leerTXT = (txt: any) => {
        const archivo = txt
        if (!archivo) {
            return
        }
        const lector = new FileReader()
        lector.onload = function (e) {
            const contenido: any = e?.target?.result
            const esDDT = `${contenido[0]}${contenido[1]}${contenido[2]}${contenido[3]}${contenido[4]}`
            if (esDDT === '[DDT]') {
                setHabilitarSubir(false)
                setHabilitarErrorArchivo(false)
            } else {
                setHabilitarSubir(true)
                setHabilitarErrorArchivo(true)
            }

        }
        lector.readAsText(archivo)
    }

    const verificarDespachoIDNull = (array: any) => {
        // const hayError = array.filter((item: ObtenerEstadoArchivos) => item.Error !== null)
        const enProgreso = array.filter((item: ObtenerEstadoArchivos) => item.DespachoID === null && item?.Error === null)
        if (enProgreso?.length > 0) {
            obtenerEstadoArchivosTXT()
        } 
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value)
    }

    const nuevoTXT = () => {
        setDataArchivos([])
        setSearchResults([])
        setHabilitarSubir(true)
        setHabilitarReiniciarProgreso(false)
        setUploadedFile(undefined)
        setMostrarForm(true)
    }

    const verProgresos = () => {
        setErrorCarga('')
        updateCargando(true)
        setHabilitarReiniciarProgreso(true)
        setMostrarForm(false)
        obtenerEstadoArchivosTXT()
    }

    useEffect(() => {
     if(dataArchivos?.length > 0){
        verificarDespachoIDNull(dataArchivos)
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataArchivos])
    
    useEffect(() => {
         verificarDespachoIDNull(dataArchivos)
    // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])

    useEffect(() => {
        const results = dataArchivos.filter(item =>
            item.IEXT.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Subregimen.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setSearchResults(results)
        // eslint-disable-next-line
    }, [searchTerm])

    return (
        <>
            <Button onClick={handleShow} variant="success"><FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 20, marginRight: 3 }} />
                Subir .TXT</Button>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Carga por .txt</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <Container>
                                {uploadedFile?.name &&
                                    <Alert style={{ fontSize: 15, display: 'flex', justifyContent: 'center', marginTop: 10 }} key={'upload-success'} variant={'primary'}>
                                        {`Archivo cargado: ${uploadedFile?.name}`}
                                    </Alert>}
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <input
                                        ref={inputRef}
                                        onChange={handleDisplayFileDetails}
                                        className="d-none"
                                        type="file"
                                        accept='.txt'
                                    />

                                    <Button onClick={handleUpload}
                                        variant="success"><FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 20, marginRight: 3 }} />
                                        {'Cargar .txt'}</Button>
                                    <Button disabled={habilitarSubir} onClick={() => uploadedFile !== undefined && subirTXT(uploadedFile)
                                    } variant={habilitarSubir ? 'secondary' : 'primary'}><FontAwesomeIcon icon={faFileUpload} style={{ fontSize: 20, marginRight: 3 }} />Subir .txt</Button>
                                </div>
                            </Container>
                            {errorCarga?.length > 0 && errores?.length > 0 && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                {errorCarga?.length < 30 ? errorCarga : 'Revise los archivos con errores o eliminelos antes de continuar.'}
                            </Alert>}
                           
                            {habilitarErrorArchivo && <Alert key={'danger'} variant={'danger'} style={{ marginTop: 5 }}>
                                El archivo contiene errores o saltos de lineas. Por favor revise el .TXT
                            </Alert>}
                            {!mostrarForm && <div className='container-cards' style={{ marginTop: 20, maxHeight: 400, overflowY: 'scroll' }}>
                                {dataArchivos?.length > 0 &&
                                    <div style={{ paddingBottom: 10, display: 'flex', justifyContent: 'center' }}>
                                        <form className="form-search">
                                            <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
                                                <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <input className="input-search" value={searchTerm}
                                                onChange={handleChange} placeholder="Buscar.." type="text" />
                                        </form>
                                    </div>
                                }
                                {searchResults?.map((el) => (

                                    (el?.DespachoID === null && el?.Error === null) ?
                                        <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid', }}>

                                            <Toast.Body>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyItems: 'center' }}>
                                                    <Spinner animation='border' variant='success' />
                                                    <p style={{ fontWeight: 'bold' }}>Procesando..</p>
                                                    <p>Identificador: {el?.IEXT}</p>

                                                </div>
                                                <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 0, hide: 200 }}
                                                        overlay={renderTooltip('Borrar')}
                                                    >
                                                        <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                    </OverlayTrigger>
                                                </div>

                                            </Toast.Body>
                                        </Toast> : el?.Error?.length! > 0 ? <>
                                            <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                <Toast.Header closeButton={false}>
                                                    <strong className="mr-auto" style={{ color: 'black' }}>
                                                        Identificador: {el?.DespachoID}-{el?.IEXT}</strong>
                                                    <small>ID Sistema: {el?.ID} </small>
                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <strong  style={{ color: 'black', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                                                            Archivo con errores</strong>
                                                        <Button onClick={() => {
                                                            setErrores(el?.Error!)
                                                            setShowModalErrores(true)
                                                        }}>Ver Errores</Button>
                                                        <p style={{ marginTop: 1 }}>Fecha Subido: {formatearFechaHora(el?.FechaCreado)} </p>
                                                    </div>
                                                    <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>


                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Borrar')}
                                                        >
                                                            <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                        </OverlayTrigger>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                        </> :
                                            <Toast style={{ borderRadius: 10, borderColor: 'black', border: '1px solid' }}>
                                                <Toast.Header closeButton={false}>
                                                    <strong className="mr-auto" style={{ color: 'black' }}>
                                                        Identificador:  <Link to={`/provisorio/${el?.DespachoID}`}>{el?.IEXT}</Link> </strong>
                                                    <small>ID Sistema: {el?.ID} </small>
                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div>
                                                        <p>Subregimen: {el?.Subregimen}</p>
                                                        <p>Fecha Subido: {formatearFechaHora(el?.FechaCreado)} </p>
                                                    </div>
                                                    <div className='icons-cards' style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: 50, height: 0, marginLeft: 210 }}>

                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Procesado')}
                                                        >
                                                            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 25, marginBottom: -5, color: '#65ba77', cursor: 'pointer' }} />

                                                        </OverlayTrigger>

                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 0, hide: 200 }}
                                                            overlay={renderTooltip('Borrar')}
                                                        >
                                                            <FontAwesomeIcon onClick={() => borrarArchivos(el?.ID)} icon={faTimesCircle} style={{ fontSize: 25, marginTop: 10, color: '#bf5b54', cursor: 'pointer' }} />
                                                        </OverlayTrigger>
                                                    </div>
                                                </Toast.Body>
                                            </Toast>
                                ))}

                            </div>}
                        </Modal.Body>}
                    {dataArchivos?.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                        <p style={{ fontSize: 15 }}>Cantidad de archivos: {dataArchivos?.length}</p>
                    </div>}
                    <Modal.Footer>
                        <div style={{ marginRight: 'auto' }}>
                            {!mostrarForm ? <Button variant="success" onClick={() => nuevoTXT()} >Volver</Button> : <Button variant="warning" onClick={() => verProgresos()} ><FontAwesomeIcon icon={faSpinner} style={{ fontSize: 20, marginRight: 3 }} />Ver Progresos</Button>}
                            {habilitarReiniciarProgreso && <Button variant="warning" onClick={() => verProgresos()} style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faSpinner} style={{ fontSize: 20, marginRight: 3 }} />Refrescar</Button>}
                        </div>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </BlockUi>
            </Modal>



            <Modal style={{
                backgroundColor: 'rgba(1,1,1,0.5)'
            }} show={showModalErrores} dialogClassName="modal-upload"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Errores</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    {!cargando &&
                        <Modal.Body>
                            <div style={{ overflowY: errores !== '' ? 'scroll' : undefined, height: 270, border: '1px solid #000', backgroundColor: 'white', color: 'black' }}>
                                <p>{errores}</p>
                            </div>
                        </Modal.Body>}

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalErrores(false)}>
                            Cerrar
                        </Button>

                    </Modal.Footer>
                </BlockUi>
            </Modal>

        </>
    )
}

export default ModalSubirTXT