import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface propsModalInsertarProrrateos {
    showModalInsertarProrrateos: boolean | undefined
    handleCloseModalProrrateos: () => void
    typeModal: string
    valorFOB?: string
    kgNetoTotalValue?:string
    despachoID: number
    setMensajeExitoInsertInfItemCero: ((args:string) => void)
    IDDT: string
    porcentajeCheck?: boolean
}

const ModalInsertarProrrateos = ({ showModalInsertarProrrateos, handleCloseModalProrrateos,setMensajeExitoInsertInfItemCero,IDDT,porcentajeCheck, kgNetoTotalValue,typeModal,valorFOB, despachoID }: propsModalInsertarProrrateos) => {

    let api = useAdvAPI()

    const confirmarInsertar = async () => {
        if(typeModal === 'FOB'){
           
            let data =
            {
                "DespachoId": despachoID,
                "AjusteFOB": valorFOB?.includes('%') ? valorFOB.replace("%", "") : valorFOB,
                "Porcentaje": valorFOB?.includes('%') ? true : porcentajeCheck
                }
            try {
                let resp = await api.DatosGlobalesProrrateoFOBTransaccion(data)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó correctamente los nuevos valores.')
                    handleCloseModalProrrateos()
                } else {
                    setMensajeExitoInsertInfItemCero(resp?.Descripcion)
                    handleCloseModalProrrateos()
                }
    
            } catch (error) {
                setMensajeExitoInsertInfItemCero('Error al insertar los nuevos valores.')
                handleCloseModalProrrateos()
            }
        }else{
            let data =
            {
                "DespachoId": despachoID,
                "KGNeto": kgNetoTotalValue,
                "IDDT": IDDT
                }
            try {
                let resp = await api.DatosGlobalesProrrateoKilos(data)
                if (resp?.Exito) {
                    setMensajeExitoInsertInfItemCero('Se insertó correctamente los nuevos valores.')
                    handleCloseModalProrrateos()
                } else {
                    setMensajeExitoInsertInfItemCero(resp?.Descripcion)
                    handleCloseModalProrrateos()
                }
    
            } catch (error) {
                setMensajeExitoInsertInfItemCero('Error al insertar los nuevos valores.')
                handleCloseModalProrrateos()
            }
        }
       
    }

    return (
        <>
            <Modal  style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} show={showModalInsertarProrrateos} onHide={handleCloseModalProrrateos} dialogClassName="modal-cancelar"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <h4 style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>{typeModal === 'FOB' ? `¿Confirma Ajustar a FOB por el valor ${valorFOB}?` : `¿Confirma establecer KG Neto total por el valor ${kgNetoTotalValue}?` }</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                            <Button variant='danger' onClick={() => confirmarInsertar()} style={{ width: 80 }}>Si</Button>
                            <Button variant='secondary' onClick={() => handleCloseModalProrrateos()} style={{ width: 80 }}>No</Button>

                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>)
}

export default ModalInsertarProrrateos