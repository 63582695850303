import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MySelect, OnValueChangeArgument } from 'FormikHooks';
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi';
import { Formik } from 'formik';
import { ObtenerUsuariosAFIPResponse } from 'modelos/Advanced';
import React, { useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui';
import { Button, Col, Container, Form, Modal } from 'react-bootstrap'

interface propsModalOficializar {
    showModalOficializar: boolean | undefined;
    handleCloseModalOficializar: (() => void);
    cargando: boolean;
    updateCargando: ((args: boolean) => void)
    setShowModalPDFMaria: ((args: boolean) => void)
    despachoID: string
    CUITSeleccionadoSesion?: string
}
const ModalOficializar = ({ showModalOficializar, handleCloseModalOficializar, cargando, updateCargando, despachoID, CUITSeleccionadoSesion, setShowModalPDFMaria }: propsModalOficializar) => {

    let api = useAdvAPI();

    const [usuariosAFIP, SetUsuariosAFIP] = useState<ObtenerUsuariosAFIPResponse[]>()
    const [CUITSeleccionado, setCUITSeleccionado] = useState<OnValueChangeArgument>('')
    const [claveAFIP, setClaveAFIP] = useState('')
    const [habilitarBotonPDF, setHabilitarBotonPDF] = useState(false)
    const [mensajeError, setMensajeError] = useState<string | null>('En progreso..')
    const [mensajeErrorHistorial, setMensajeErrorHistorial] = useState<string | null>(null)
    const [mensajeProgreso, setMensajeProgreso] = useState<string>('Iniciando Oficialización..')
    const [oficializandoConCuitSinToken, setOficializandoConCuitSinToken] = useState(false)
    const [nroDespachoOficializado, setNroDespachoOficializado] = useState('')
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | any>(null)

    const nroDespacho = useRef<null | string>(null)

    const ObtenerUsuariosAFIP = async () => {
        try {
            updateCargando(true)
            let usuariosAFIP = await api.ObtenerUsuariosAFIP()
            SetUsuariosAFIP(usuariosAFIP)
            updateCargando(false)
        } catch (error) {
            updateCargando(false)
        }
    }

    const obtenerHistorialValidacion = async () => {
        const retryDelay = 4000;
        let historialValidacion;
        let estadoDespacho;
        let isCancelled = false;

        const cancelarPeticiones = () => {
            isCancelled = true;
            clearTimeout(timeoutId);
            setTimeoutId(null);
        };

        try {
            const getOficializacion = async () => {
                if (isCancelled) return; // Detiene la ejecución si se cancela

                try {
                    historialValidacion = await api.ObtenerUltimoHistorialValidacion(despachoID);
                    estadoDespacho = await api.ObtenerEstadoDespacho(Number(despachoID));

                    if (isCancelled) return; // Revisa si se cancela después de cada petición

                    if (estadoDespacho?.EstadoID === 7) {
                        updateCargando(true);
                        setMensajeProgreso(historialValidacion?.DescripcionProgreso);
                        const newTimeoutId = setTimeout(getOficializacion, retryDelay);
                        setTimeoutId(newTimeoutId);
                    }

                    if (estadoDespacho?.EstadoID === 10) {
                        nroDespacho.current = estadoDespacho?.NroDespacho;
                        setNroDespachoOficializado(estadoDespacho?.NroDespacho);
                        setMensajeProgreso('');
                        setHabilitarBotonPDF(true);
                        updateCargando(false);
                        setMensajeError(null);
                        cancelarPeticiones(); // Cancela peticiones cuando ya no son necesarias
                    }

                    if (estadoDespacho?.EstadoID === 8) {
                        nroDespacho.current = estadoDespacho?.NroDespacho;
                        setNroDespachoOficializado(estadoDespacho?.NroDespacho);
                        setMensajeProgreso('');
                        setHabilitarBotonPDF(false);
                        updateCargando(false);
                        cancelarPeticiones();
                        setMensajeErrorHistorial(historialValidacion?.Error);
                        setMensajeError(estadoDespacho?.EstadoDescripcion);
                    }

                    if (historialValidacion.Error === null && estadoDespacho?.EstadoID !== 10 && estadoDespacho?.EstadoID !== 8) {
                        setMensajeProgreso(historialValidacion?.DescripcionProgreso);
                        const newTimeoutId = setTimeout(getOficializacion, retryDelay);
                        setTimeoutId(newTimeoutId);
                    } else if (historialValidacion.Error?.length > 0 && estadoDespacho?.EstadoID !== 10) {
                        setMensajeError(historialValidacion?.Error);
                        setMensajeProgreso('');
                        updateCargando(false);
                        cancelarPeticiones();
                    }
                } catch (error) {
                    cancelarPeticiones();
                    setMensajeError('Error del servidor');
                    updateCargando(false);
                    throw error;
                }
            };

            getOficializacion();
        } catch (error) {
            cancelarPeticiones();
            updateCargando(false);
            setMensajeError('Error del servidor');
        }
    };


    const OficializarDespacho = async (despachoID: string, CUITSeleccionado: string, claveAFIP: string) => {
        try {
            updateCargando(true)
            await api.OficializarDespacho(despachoID, CUITSeleccionado, claveAFIP)
            obtenerHistorialValidacion()
            //Loop obtener estado despacho hasta obtener el id del estado.
            //EstadoID 9 = en progreso.
            //Si es estado 8 mostrar el error de último historial 
            // let historialValidacion = await api.ObtenerUltimoHistorialValidacion(despachoID);

            //Si es estadoId = 10 habilitar boton PDF, exito.
            // setHabilitarBotonPDF(true)
            // updateCargando(false);
        } catch (error) {
            console.log('error')
            updateCargando(false);
        }
    }

    const showModalPDFMaria = async () => {
        handleCloseModalOficializar()
        setShowModalPDFMaria(true)
    }

    useEffect(() => {
        setHabilitarBotonPDF(false)
        ObtenerUsuariosAFIP()
        setClaveAFIP('')
        setCUITSeleccionado('')
        setMensajeError(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [showModalOficializar])

    useEffect(() => {
        if (showModalOficializar && CUITSeleccionadoSesion) {
            setOficializandoConCuitSinToken(true)
            updateCargando(true)
            OficializarDespacho(despachoID, CUITSeleccionadoSesion, 'X')
        }

  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CUITSeleccionadoSesion])



    return (
        <>
            <Modal show={showModalOficializar} onHide={handleCloseModalOficializar} dialogClassName="modal-nuevo_perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" keyboard={false} style={{
                    backgroundColor: 'rgba(1,1,1,0.5)'
                }}>
                <Modal.Header closeButton={false}>
                    <Modal.Title>Oficializar Despacho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BlockUi blocking={cargando}  >
                        {!habilitarBotonPDF && mensajeError === null && !oficializandoConCuitSinToken ? <div>
                            <Formik
                                onSubmit={(values) => {
                                }}
                                initialValues={{
                                    CUIT: '',
                                    claveAFIP: ''
                                }}
                            >
                                {() => (
                                    <Form noValidate >
                                        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Form.Group as={Col} md="10" lg="10">
                                                <MySelect
                                                    name="CUIT" label='Seleccione CUIT'
                                                    options={usuariosAFIP!.map(usuario => ({
                                                        value: usuario?.CUIT,
                                                        label: `${usuario?.CUIT} - ${usuario?.Nombre}`
                                                    }))}
                                                    onValueChange={(e) => {
                                                        setClaveAFIP('')
                                                        setCUITSeleccionado(e)
                                                    }} />
                                            </Form.Group>
                                            {CUITSeleccionado!?.length > 0 && <Form.Group as={Col} md="6" lg="6">
                                                <Form.Label>Clave AFIP</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    name="claveAFIP"
                                                    aria-label='Ingrese clave AFIP..'
                                                    value={claveAFIP}
                                                    onChange={(e) => setClaveAFIP(e.target.value)}
                                                />
                                            </Form.Group>}
                                        </Container>
                                    </Form>
                                )}
                            </Formik>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                {CUITSeleccionado!?.length > 0 && claveAFIP!?.length > 0 && <Button onClick={() => OficializarDespacho(despachoID, CUITSeleccionado?.toString()!, claveAFIP)} variant='success'>Oficializar</Button>}
                            </div>
                        </div> : mensajeError?.length! > 0 ? <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <FontAwesomeIcon size="3x" color='red' icon={faExclamationCircle}></FontAwesomeIcon>
                            <h2>
                                {mensajeError}
                            </h2>
                            {mensajeError !== mensajeErrorHistorial && <h2>
                                {mensajeErrorHistorial}
                            </h2>}
                        </Col> : mensajeProgreso?.length! > 0 ? <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2>
                                {mensajeProgreso}
                            </h2>
                        </Col> : habilitarBotonPDF ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }} >
                                <FontAwesomeIcon size="4x" color='green' icon={faCheckCircle}></FontAwesomeIcon>
                                <h2 >Declaración Oficializada </h2>
                                <h4>Nro. Despacho: <b>{nroDespachoOficializado}</b></h4>
                                <Button variant='warning' onClick={() => showModalPDFMaria()}>PDF Maria</Button>
                            </div> :
                            <div>
                                <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h2>
                                        Iniciando Oficialización..
                                    </h2>
                                </Col>
                            </div>
                        }
                    </BlockUi>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10 }} >
                        <Button variant='danger' onClick={() => handleCloseModalOficializar()}>Cerrar</Button>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalOficializar