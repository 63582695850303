import { faExclamationCircle, faExclamationTriangle, faFile, faFileDownload, faFileUpload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AppContext } from 'App'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import { isNullOrWhiteSpace } from 'Utilidades'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Alert, Button, Col, Modal, ProgressBar, Table } from 'react-bootstrap'
import './styleModalSubirArchivo.css'
import { useApi } from 'ApiHooks'
import Loader from 'react-loaders'
import { setTimeout } from 'timers'
import { ListArchivos } from 'modelos/Advanced'
import axios, { CancelTokenSource } from 'axios'

interface PropsArchivosAdjuntosCaratula {
    interno: string
    activeTabKey: string
}
const ArchivosAdjuntosCaratula = ({ interno, activeTabKey }: PropsArchivosAdjuntosCaratula) => {

    const [tablaListaArchivos, setTablaListaArchivos] = useState<ListArchivos[]>()
    const [cargando, setCargando] = useState<boolean>(false)
    const [showModalSubirArchivos, setShowModalSubirArchivos] = useState(false);
    const [showModalEliminarArchivo, setShowModalEliminarArchivo] = useState(false)
    const [showModalDescargandoArchivo, setShowModalDescargandoArchivo] = useState(false)
    const [uploadedFile, setUploadedFile] = useState<File | undefined>()
    const [habilitarErrorArchivo, setHabilitarMensajeRespuesta] = useState<string | null>(null)
    const [habilitarMensajeRespuestaError, setHabilitarMensajeRespuestaError] = useState<string | null>(null)
    const [reemplazarArchivo, setReemplazarArchivo] = useState<boolean>(false)
    const [cuitImportador, setCuitImportador] = useState<string>()
    const [cuitDespachante, setCuitDespachante] = useState<string>()
    const [idArchivo, setIdArchivo] = useState<number>()
    const [mostrarMensajeSinClienteDespachante, setMostrarMensajeSinClienteDespachante] = useState(false)
    const [progress, setProgress] = useState<number>(0)
    const [habilitarBotonCancelarCarga, setHabilitarBotonCancelarCarga] = useState<boolean>(false)

    const api = useAdvAPI();
    const apiCaratula = useApi();
    const { userInfo } = useContext(AppContext);
    const cancelTokenSource = useRef<CancelTokenSource | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    //TablaGrilla Componenente estilo
    const TablaGrilla = (props: React.PropsWithChildren<{ className?: string }>) => {
        let className;
        if (!isNullOrWhiteSpace(props.className)) {
            className = props.className + ' middle-vertical-align';
        } else {
            className = 'middle-vertical-align';
        }
        return (<Table bordered striped hover size="sm" className={className}>{props.children}</Table >);
    }

    //Modal Handlers
    const handleShowModalSubirArchivo = () => setShowModalSubirArchivos(true);
    const handleCloseModalEliminarArchivo = () => setShowModalEliminarArchivo(false);
    const handleShowModalDescargandoArchivo = () => setShowModalDescargandoArchivo(true);
    const handleCloseModalSubirArchivo = () => {
        setUploadedFile(null!)
        setHabilitarMensajeRespuesta(null)
        setHabilitarMensajeRespuestaError(null)
        setShowModalSubirArchivos(false)
        setReemplazarArchivo(false)
        obtenerListaArchivos()
    }
    const handleShowModalEliminarArchivo = (id: number) => {
        setIdArchivo(id)
        setShowModalEliminarArchivo(true)
    };
    const handleCloseModalDescargandoArchivo = () => {
        setShowModalDescargandoArchivo(false)
        setProgress(0)
    }

    //File Handlers
    const handleDisplayFileDetails = () => {
        inputRef.current?.files &&
            setUploadedFile(inputRef?.current?.files?.[0])
    };

    //Peticiones
    const obtenerListaArchivos = (cuit?: string, despachante?: string) => {
        let body = {
            "ID_ClienteAlpha": userInfo?.nroClienteAlpha!,
            "CuitDespachante": despachante || cuitDespachante,
            "CuitImpoExpo": cuit || cuitImportador,
            "ID_Empresa": userInfo?.empresaActual!,
        }
        api.obtenerListaArchivos(body).then((resp) => setTablaListaArchivos(resp?.listArchivos))
    }

    const descargarArchivo = async (archivo: ListArchivos) => {
        setCargando(true)
        handleShowModalDescargandoArchivo()
        cancelTokenSource.current = axios.CancelToken.source();

        const options = {
            onDownloadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent
                let percentage = Math.floor(loaded * 100 / total)
                setProgress(percentage)
            },
            cancelToken: cancelTokenSource.current.token,
        }

        try {
            // Realizar una solicitud GET al endpoint
            const response = await api.descargarArchivo(archivo.id, options);
            // Decodificar el archivo base64
            const base64String = response;
            const byteCharacters = atob(base64String);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            // Crear un Blob a partir del array de bytes
            const blob = new Blob(byteArrays, { type: 'application/octet-stream' });

            // Crear un enlace en memoria y descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${archivo?.nombre}.${archivo?.extension}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setCargando(false)
            setTimeout(() => {
                handleCloseModalDescargandoArchivo()
            }, 2000);
        } catch (error) {
            setCargando(false)
            console.error('Error al descargar el archivo:', error);
        }
    }

    const borrarArchivo = (idArchivo: number) => {
        setCargando(true)
        api.eliminarArchivo(idArchivo).then((resp) => {
            obtenerListaArchivos()
            setCargando(false)
            handleCloseModalEliminarArchivo()
        }).catch((error) => setCargando(false)
        )
    }

    const subirArchivo = (archivo: File) => {
        setCargando(true);
        setHabilitarBotonCancelarCarga(true)
        function fileToBase64(archivo: File, callback: CallableFunction) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const base64String = event?.target?.result;
                callback(base64String);
            };
            reader.readAsDataURL(archivo);
        }
        fileToBase64(archivo, function (base64String: string) {
            postSubirNuevoArchivo(base64String?.split(",")?.[1]!)

        });
    };

    //Utils
    const obtenerExtension = (nombreArchivo: string) => {
        let ultimoPunto = nombreArchivo?.lastIndexOf('.');
        let extension = nombreArchivo.substring(ultimoPunto + 1).toLowerCase();
        return extension;
    }

    const cancelarDescarga = () => {
        setCargando(false)
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Descarga cancelada por el usuario.');
            handleCloseModalDescargandoArchivo()
            cancelTokenSource.current = null;
        }
    };

    const cancelarCarga = () => {
        setCargando(false)
        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Carga cancelada por el usuario.');
            handleCloseModalSubirArchivo()
            setHabilitarBotonCancelarCarga(false)
            setHabilitarMensajeRespuestaError(null)
            setProgress(0)
            cancelTokenSource.current = null;
        }
    };

    const postSubirNuevoArchivo = async (base64String: string) => {
        setProgress(0)
        setHabilitarMensajeRespuesta(null)
        setHabilitarMensajeRespuestaError(null)
        cancelTokenSource.current = axios.CancelToken.source();
        const options = {
            onUploadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent
                let percentage = Math.floor(loaded * 100 / total)
                setProgress(percentage)
            },
            cancelToken: cancelTokenSource.current.token,
        }
        await api.subirArchivosVarios({
            "ID_ClienteAlpha": userInfo?.nroClienteAlpha,
            "Nombre": uploadedFile?.name?.slice(0, -4)!,
            "Extension": obtenerExtension(uploadedFile?.name!),
            "ArchivoBytesBase64": base64String,
            "Interno": interno,
            "CuitDespachante": cuitDespachante,
            "CuitImpoExpo": cuitImportador,
            "id_empresa": userInfo?.empresaActual!,
            "ReemplazarArchivoSiExiste": reemplazarArchivo
        }, options).then((resp) => {
            if (resp?.mensaje === `El archivo ${uploadedFile?.name?.slice(0, -4)} se ha guardado correctamente.` || resp?.mensaje === `El archivo ${uploadedFile?.name?.slice(0, -4)} se ha pisado correctamente.`) {
                setCargando(false)
                setHabilitarMensajeRespuesta(resp?.mensaje)
                setReemplazarArchivo(false)
                setHabilitarBotonCancelarCarga(false)
                setTimeout(() => {
                    setProgress(0)
                    setHabilitarMensajeRespuesta(null)
                    obtenerListaArchivos()
                    handleCloseModalSubirArchivo()
                }, 1500);
            }
            else if (resp?.mensaje === "Ya existe un archivo con el mismo nombre y extension.") {
                setCargando(false)
                setHabilitarMensajeRespuesta(resp?.mensaje)
                setReemplazarArchivo(false)
                setProgress(0)
                setHabilitarBotonCancelarCarga(false)
            } else if (resp?.mensajeError) {
                setCargando(false)
                setReemplazarArchivo(false)
                setHabilitarMensajeRespuestaError(resp?.mensajeError)
                setProgress(0)
                setHabilitarBotonCancelarCarga(false)
            }
        }).catch((error) => {
            setHabilitarBotonCancelarCarga(false)
            setCargando(false)
        })
    }
    //UseEffect para obtener archivos al montar el componente
    useEffect(() => {
        if (activeTabKey === 'archivos') {
            setCargando(true)
            apiCaratula.getCaratula(interno).then((resp) => {
                setCuitImportador(resp?.CuitImportador)
                setCuitDespachante(resp?.CuitDespachante)
                if ((resp?.CuitImportador === null || resp?.CuitDespachante === null)) {
                    setMostrarMensajeSinClienteDespachante(true)
                    setCargando(false)
                    setTablaListaArchivos(null!)
                } else {
                    obtenerListaArchivos(resp?.CuitImportador, resp?.CuitDespachante)
                    setCargando(false)
                }
            })
        }
        // eslint-disable-next-line
    }, [activeTabKey])

    return (
        <>
            <hr />
            <Col style={{ display: 'flex', flexDirection: 'row' }} >
                <Button onClick={handleShowModalSubirArchivo} disabled={cuitImportador === null || cuitDespachante === null} variant={cuitImportador === null || cuitDespachante === null ? "secondary" : "primary"}><FontAwesomeIcon icon={faFile} style={{ fontSize: 20, marginRight: 3 }} />
                    Cargar Archivo</Button>
                {mostrarMensajeSinClienteDespachante && <p style={{ padding: 10, fontWeight: 'bold', fontSize: 15 }}>Asegurese de tener un Despachante y Cliente seleccionados en la pestaña "Carpeta" para poder subir archivos.</p>
                } </Col>
            <hr />
            {tablaListaArchivos?.length === 0 || tablaListaArchivos === null ?
                <Col>
                    <Alert variant="dark">
                        <Col style={{ display: 'flex', flexDirection: 'column', marginTop: 10, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: 30 }} />
                            <h4> No existen archivos cargados para este interno.</h4>

                        </Col>
                    </Alert>
                </Col> :
                <BlockUi blocking={cargando} loader={<Loader active={cargando} type='ball-beat' />} message="Cargando archivos.." >
                    <TablaGrilla>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre Archivo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tablaListaArchivos?.map((archivo: ListArchivos) => (
                                <tr>
                                    <td>{archivo?.id}</td>
                                    <td>{`${archivo?.nombre}.${archivo?.extension}`}</td>
                                    <td>
                                        <Button title='Descargar' variant="link" onClick={() => descargarArchivo(archivo)} style={{ fontSize: 20, color: 'green' }}><FontAwesomeIcon icon={faFileDownload} /></Button>
                                        <Button title='Borrar' variant="link" onClick={() => handleShowModalEliminarArchivo(archivo?.id)} style={{ fontSize: 20, color: '#db3939' }}><FontAwesomeIcon icon={faTrash} /></Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </TablaGrilla>
                </BlockUi>
            }
            <Col>
                <Modal show={showModalSubirArchivos} onHide={handleCloseModalSubirArchivo} dialogClassName="modal-upload"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop={cargando ? "static" : true}
                >
                    <Modal.Header closeButton={cargando ? false : true}>
                        <Modal.Title>Cargar Archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            <label className="custum-file-upload" htmlFor="file">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" clip-rule="evenodd" fillRule="evenodd"></path> </g></svg>
                                </div>
                                <div className="text">
                                    <h5>{uploadedFile?.name ? uploadedFile?.name : 'Click para seleccionar un archivo'}</h5>
                                </div>
                                <input type="file" id="file" ref={inputRef}
                                    onChange={handleDisplayFileDetails} />
                            </label>
                        </Col>
                        <div className="checkbox">
                            <p className='reemplazar-text'>Reemplazar archivo si existe</p>
                            <input onChange={() => setReemplazarArchivo(!reemplazarArchivo)} disabled={cargando} type="checkbox" id="cbx" />
                            <label htmlFor="cbx" className="cbx">
                                <div className="flip">
                                    <div className="front"></div>
                                    <div className="back">
                                        <svg viewBox="0 0 16 14" height="14" width="16">
                                            <path d="M2 8.5L6 12.5L14 1.5"></path>
                                        </svg>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                            {cargando ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <p style={{ fontSize: 15, fontWeight: 'bold', color: 'blue' }}>Subiendo archivo..</p>
                                <ProgressBar style={{ width: 270, height: 25 }} striped variant="primary" now={progress} label={<p style={{ fontSize: 15 }}>{`${progress}%`}</p>} />
                                {habilitarBotonCancelarCarga && <Button variant='danger' style={{ marginTop: 3 }} onClick={cancelarCarga}>Cancelar</Button>}
                            </div> : <Button disabled={uploadedFile === undefined || cargando} onClick={() => uploadedFile !== undefined && subirArchivo(uploadedFile)} variant={uploadedFile === undefined ? 'secondary' : 'primary'}><FontAwesomeIcon icon={faFileUpload} style={{ fontSize: 20, marginRight: 3 }} />Subir</Button>}
                        </Col>
                        {habilitarErrorArchivo !== null && <Alert style={{ marginTop: 5 }} variant='primary' >
                            <b>{habilitarErrorArchivo}</b>
                        </Alert>}
                        {habilitarMensajeRespuestaError !== null && <Alert style={{ marginTop: 5 }} variant='danger' >
                            <b>{habilitarMensajeRespuestaError}</b>
                        </Alert>}
                    </Modal.Body>
                </Modal>
            </Col>
            <Col>
                <Modal show={showModalEliminarArchivo} onHide={handleCloseModalEliminarArchivo} dialogClassName="modal-upload"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar Archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {cargando ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Loader active={cargando} type='ball-beat' />
                            <p style={{ fontSize: 15 }}>Eliminando..</p>
                        </div>
                            : <>
                                <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                    <FontAwesomeIcon style={{ fontSize: 30, color: '#db3939', textJustify: 'auto' }} icon={faExclamationCircle} />
                                    <h4>¿Esta seguro que desea eliminar el archivo de forma permanente?</h4>
                                </Col>
                                <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5 }}>
                                    <Button variant='danger' onClick={() => borrarArchivo(idArchivo!)}>Borrar</Button>
                                    <Button variant='primary' onClick={() => handleCloseModalEliminarArchivo()}>Cancelar</Button>
                                </Col>
                            </>}
                    </Modal.Body>
                </Modal>

                <Modal animation={false}  backdrop="static" show={showModalDescargandoArchivo} onHide={handleCloseModalDescargandoArchivo} dialogClassName="modal-upload"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Descargar Archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                                {progress === 0 && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                    <p style={{ fontWeight: 'bold' }}>Obteniendo archivo..</p>
                                    <Loader active={cargando} type='ball-beat' />
                                </div>}
                                <ProgressBar style={{ width: 270, height: 25 }} striped variant={progress === 100 ? "success" : "primary"} now={progress} label={<p style={{ fontSize: 15 }}>{progress === 100 ? 'Descarga completa' : `${progress}%`}</p>} />
                            </Col>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Button onClick={cancelarDescarga} variant='danger'>Cancelar Descarga</Button>

                            </div>
                        </>
                    </Modal.Body>
                </Modal>
            </Col>
        </>)
}

export default ArchivosAdjuntosCaratula