import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Perfiles } from 'modelos/Advanced'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Loader from 'react-loaders'

interface propsModalSeleccionarPerfil {
    showModalSeleccionarPerfil: boolean | undefined;
    handleCloseModalSeleccionarPerfil: () => void;
    cargando: boolean;
    setPerfilSeleccionadoID: (arg: Number) => void;
    opcionesPerfiles: Perfiles[];
    setShowModalNuevoPerfil: (arg: boolean) => void;
    handleShowModalValidar: (arg: Number) => void;
    perfilSeleccionadoID: Number | null
    setNombrePerfilSeleccionado: (arg: any) => void
    estadoDespacho: string
    setShowPerfiles: (arg: number) => void;
    showPerfiles: number;
    estadoDespachoId: number | null;
    cancelarValidar: ((args: number) => void);
}

const ModalSeleccionarPerfil = ({ showModalSeleccionarPerfil, handleCloseModalSeleccionarPerfil, cargando, setPerfilSeleccionadoID, opcionesPerfiles, setShowModalNuevoPerfil, handleShowModalValidar, perfilSeleccionadoID, setNombrePerfilSeleccionado, estadoDespacho, showPerfiles, setShowPerfiles, estadoDespachoId, cancelarValidar }: propsModalSeleccionarPerfil) => {

    const [mensajeErrores, setMensajeErrores] = useState<boolean>(false)

    useEffect(() => {
        if (showModalSeleccionarPerfil && estadoDespacho === 'Validado') {
            setShowPerfiles(1)
        }
        if (showModalSeleccionarPerfil && estadoDespachoId === 5) {
            setMensajeErrores(true)
            setShowPerfiles(1)
        }
        if (showModalSeleccionarPerfil && estadoDespachoId === 1) {
            setMensajeErrores(false)
            setShowPerfiles(0)
        }

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [estadoDespacho, showModalSeleccionarPerfil])

    return (
        <>
            <Modal show={showModalSeleccionarPerfil} onHide={handleCloseModalSeleccionarPerfil} dialogClassName="modal-perfil"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" >
                <Modal.Header closeButton>
                    <Modal.Title>{showPerfiles === 0 ? "Seleccionar Perfil" : "Re-validación"}</Modal.Title>
                </Modal.Header>
                {showPerfiles === 0 ? <Modal.Body>
                    {cargando ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                        <Loader active={cargando} type='ball-beat' />
                    </div>
                        :
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Group>
                                    <Form.Label>Perfiles</Form.Label>
                                    <Form.Control style={{ width: '180%' }} as="select" onChange={(e) => {
                                        setNombrePerfilSeleccionado(opcionesPerfiles.filter((el) => el?.ID === Number(e.target.value))[0].Nombre)
                                        setPerfilSeleccionadoID(Number(e.target.value))
                                    }}>
                                        <option key={null} value={undefined}>Seleccione una opción</option>
                                        {opcionesPerfiles.map((perfil: Perfiles) => <option key={perfil?.ID} value={perfil?.ID}>{perfil?.Nombre}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                <OverlayTrigger overlay={<Tooltip id="tooltip">Agregar perfil</Tooltip>}>
                                    <span className="d-inline-block" style={{ marginLeft: '35%', marginTop: '5%' }}>
                                        <Button onClick={() => setShowModalNuevoPerfil(true)} style={{ width: 50, height: 50 }}><FontAwesomeIcon icon={faPlus} /></Button>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                                <Button variant='success' style={{ width: 150 }} onClick={() =>
                                    handleShowModalValidar(perfilSeleccionadoID!)
                                }>Seleccionar</Button>
                                <Button variant='secondary' style={{ width: 150 }} onClick={() => {
                                    handleShowModalValidar(null!)
                                }}>No seleccionar ninguno</Button>
                            </div>
                        </>}
                </Modal.Body> : <Modal.Body>
                    {cargando ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                        <Loader active={cargando} type='ball-beat' />
                    </div>
                        :
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                {mensajeErrores ? <h2>Se encontrarón errores en la validación. ¿desea re-validar y limpiar los errores?</h2> : <h2>¿Desea realizar una revalidación?</h2>}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 5, alignContent: 'center', alignItems: 'center' }}>
                                <Button variant='success' style={{ width: 150 }} onClick={() => {
                                    setShowPerfiles(0)
                                    cancelarValidar(1)
                                    setMensajeErrores(false)
                                }
                                }>Si</Button>
                                <Button variant='secondary' style={{ width: 150 }} onClick={() => {
                                    handleCloseModalSeleccionarPerfil()
                                }}>No</Button>
                            </div>
                        </>}
                </Modal.Body>}
            </Modal>
        </>
    )
}

export default ModalSeleccionarPerfil