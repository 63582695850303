import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAdvAPI } from 'Paginas/advanced/AdvancedApi'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import BlockUi from 'react-block-ui'
import { Button, Container, Modal, Table } from 'react-bootstrap'

interface propsModalTablaErroresValidacion {
    showModalTablaErroresValidacion: boolean;
    cargando: boolean;
    updateCargando: ((arg: boolean) => void)
    handleCloseModalErroresValidacion: (() => void)
    despachoID: Number
    setTituloValidarDespacho: Dispatch<SetStateAction<string>>
    setTimeoutId: Dispatch<any>
    setTipoVentanaMaria:Dispatch<SetStateAction<string | null>>
    setMensajeDespachoProgreso:Dispatch<SetStateAction<null>>
    setCargandoDespachoValidacion:Dispatch<SetStateAction<boolean>>

}

const ModalTablaErroresValidacion = ({ showModalTablaErroresValidacion,setCargandoDespachoValidacion,setTituloValidarDespacho,setTimeoutId,setMensajeDespachoProgreso,setTipoVentanaMaria, cargando, updateCargando, handleCloseModalErroresValidacion, despachoID }: propsModalTablaErroresValidacion) => {

    let api = useAdvAPI();

    const [historialValidacion, setMensajeValidacionError] = useState<string | any>([])

    const procesarErrores = (errorStr: string) => {
        return errorStr?.split('|').filter(e => e?.trim() !== "")?.map(e => {
            let partes = e?.split('#-#');
            let item = partes[0]?.trim();
            let causante = partes[1]?.trim();
            let errorMsg = partes[2]?.trim();
            return {
                itemID: item,
                causante: causante,
                error: errorMsg
            };
        });
    }

    const validarDespacho = async () => {
        try {
            updateCargando(true)
            let estadoValidacion = await api.ObtenerUltimoHistorialValidacion(despachoID.toString());
            setMensajeValidacionError(procesarErrores(estadoValidacion?.Error))
            updateCargando(false);
        } catch (error) {
            console.error('Error al cargar', error);
        }
    }

    useEffect(() => {
        if (showModalTablaErroresValidacion) {
            validarDespacho()
        }

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [showModalTablaErroresValidacion])

    return (
        <>
            <Modal show={showModalTablaErroresValidacion} dialogClassName="modal-errores"
                aria-labelledby="contained-modal-title-vcenter"
                centered backdrop="static" size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Errores</Modal.Title>
                </Modal.Header>
                <BlockUi blocking={cargando}>
                    <Modal.Body>
                        <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ color: 'red', fontSize: 30, marginBottom: 10 }} icon={faExclamationCircle} />
                            <p style={{ fontSize: 15, fontStyle: 'italic', textAlign: 'center' }}>
                                {historialValidacion?.length! > 0 ? <>
                                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        {
                                            historialValidacion?.[0]?.error === undefined ? <Container>
                                                <p>{historialValidacion?.[0]?.itemID}</p>
                                            </Container> :
                                                <Table responsive striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Item.</th>
                                                            <th style={{ fontSize: 15 }}>Causante</th>
                                                            <th style={{ fontSize: 15 }}>Mensaje error</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {historialValidacion.map((item: { itemID: string; causante: string; error: string; }, index: React.Key | null | undefined) => (
                                                            <tr key={index}>
                                                                <td>{item?.itemID}</td>
                                                                <td>{item?.causante}</td>
                                                                <td>{item?.error}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>}
                                    </div>
                                </> : 'Ocurrió un error del servidor'}</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <Button variant='success' style={{ width: 70, marginTop: 15 }} onClick={() => {
                                    setMensajeValidacionError([])
                                    handleCloseModalErroresValidacion()
                                    setTituloValidarDespacho('Iniciando Validación')
                                    setTimeoutId(null)
                                    setTipoVentanaMaria(null)
                                    setMensajeDespachoProgreso(null)
                                    setCargandoDespachoValidacion(false)
                                    updateCargando(false)
                                }}>Cerrar</Button>
                            </div>
                        </Container>
                    </Modal.Body>
                </BlockUi>

            </Modal>
        </>
    )
}

export default ModalTablaErroresValidacion